<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" @click="goBack"><i class="blind">이전</i></button>
            <div class="util-group">
                <button type="button" class="btn-search" @click="goSrch"><i class="blind">검색</i></button>
            </div>
        </header>
        <router-view></router-view>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
    name : 'cafe',
    components: {
    },
    data : function() {
        return {
         baseUrl : process.env.VUE_APP_SERVER_URL,
         srchText1: ''
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
            this.cafe = new Object();

            const route = useRoute();
            this.srchText1 = route.query.paramSrchText1;
        },
        goSrch(event) {
            this.$router.push({
                name: 'srch',
                query : { paramSrchText1:this.srchText1}
            }).then((() =>window.scrollTo(0,0) ));;
        },
        goBack(e) {
            console.log(this.$router);
            this.$router.back();
        }
    }
  
}
</script>