import cnst from '@/util/cnst.js'

export default {
    namespaced: true,
    state: {
        cust: {
            loginGbnCode: cnst.SMPL.NORMAL.CUST_REG_TYPE_CODE
        },
    },
    getters: {},
    mutations: {
        setCust(state, payload) {
            state.cust.loginGbnCode = payload;
            console.log("store check")
            console.log(state.cust.loginGbnCode);
        },
    },
    actions: {
        setCust({ state, commit }, payload) {
            commit('setCust', payload);
        },
    },
};
