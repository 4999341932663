<template>
    <div class="wrap">
        <!-- Tab links -->
        <div class="tab full-type">
            <button class="tablinks active" @click="clickTab($event, 0)" >진행중인 이벤트</button>
            <button class="tablinks" @click="clickTab($event, 1)">종료된 이벤트</button>
        </div>
        <div id="tabCont-01" class="tabcontent" v-show="curTabIndex == 0">
            <section class="section">
                <div class="list-wrap evt-type">
                    <div class="list-item" v-for="(arti, index) in activeArtiLst" :key="index">
                        <a class="list-box" @click="goDetail(arti.artiNum)">
                            <div class="list-thumb"><img src="" alt=""></div>
                            <div class="list-info">
                                <strong class="tit">{{ arti.ttl }}</strong>
                                <span class="date">{{ StringUtil.timestampToDate(arti.evntStartDt) }} ~ {{ StringUtil.timestampToDate(arti.evntEndDt) }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
        <div id="tabCont-02" class="tabcontent" v-show="curTabIndex == 1">

            <section class="section" v-if="endArtiLst != undefined">
                <div class="list-wrap evt-type">
                    <div class="list-item" v-for="(arti, index) in endArtiLst" :key="index">
                        <a class="list-box" @click="goDetail(arti.artiNum)">
                            <div class="list-thumb"><img src="" alt=""></div>
                            <div class="list-info">
                                <strong class="tit">{{ arti.ttl }}</strong>
                                <span class="date">{{ StringUtil.timestampToDate(arti.evntStartDt) }} ~ {{ StringUtil.timestampToDate(arti.evntEndDt) }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <template v-else>
                <strong class="blind">종료된 이벤트</strong>
                <div class="evt-info-wrap">
                    <div class="evt-info">
                        <strong class="tit">진행중인 이벤트가 없습니다.</strong>
                    </div>
                </div>
            </template>        
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import boardEnum from "@/util/board.js" 

//components
import StringUtil from '@/util/StringUtil.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

const curTabIndex = ref(0);


//게시판 정보 처리 시작
const board = ref({});
const activeArtiLst = ref([]);
const endArtiLst = ref([]);

function goDetail(artiNum){
    router.push({
        name: 'boardDetail',
        params : { boardNum:board.value.boardNum,
                    artiNum:artiNum}
    }).then((() =>window.scrollTo(0,0) ));;
}

async function goGetLst(board){
    let tmpBoardLst = [];
    await $axios({
        method: 'POST',
        url: '/arti/lst',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoardLst = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoardLst;
}

function clickTab(event, index) {
  const tabLinks = document.querySelectorAll('.tablinks');
  tabLinks.forEach((tabLink) => tabLink.classList.remove('active'));
  event.target.classList.add('active');
  curTabIndex.value = index;
}

onMounted( async () => {
    board.value.boardNum = route.params.boardNum;
    board.value.boardSrchGbnCode = boardEnum.EVNT.ACTIVE_EVNT;
    activeArtiLst.value = await goGetLst(board.value);

    board.value.boardSrchGbnCode = boardEnum.EVNT.END_EVNT;
    endArtiLst.value = await goGetLst(board.value);

    console.log(endArtiLst);
})
//유저 정보 처리 끝


</script>