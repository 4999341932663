<template>
        <main class="section-wrap dashboard">
            <h2 class="title">카페 관리</h2>
            <section class="dashboard-wrap">
                <article class="article-box half-type">
                    <div class="account-area">
                        <span class="pic-box"><img src="/mycafemngr/images/dummy/@my-pic.png" alt=""></span>
                        <div class="txt-box"><strong>홍길동</strong>사장님, 안녕하세요!</div>
                        <div class="desc-box">zzzxcv312@naver.com</div>
                        <button type="button" class="btn-setting"><i class="blind">정보수정</i></button>
                    </div>
                    <div class="account-info">
                        <dl class="row-info">
                            <dt>닉네임</dt>
                            <dd>홍길동</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>휴대폰</dt>
                            <dd>010-1234-5678</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>사업자등록번호</dt>
                            <dd>123-12-12345</dd>
                        </dl>
                    </div>
                </article>
                <article class="article-box half-type">
                    <div class="account-area">
                        <span class="pic-box"><img src="/mycafemngr/images/dummy/@my-pic.png" alt=""></span>
                        <div class="txt-box"><strong>마이카페</strong></div>
                        <div class="desc-box">MyCafe</div>
                        <button type="button" class="btn-setting"><i class="blind">정보수정</i></button>
                    </div>
                    <div class="account-info">
                        <dl class="row-info">
                            <dt>전화번호</dt>
                            <dd>010-1234-5678</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>주소</dt>
                            <dd>서울시 구로구 12-3길 태평양물산</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>태그</dt>
                            <dd>
                                <div class="tag-box">
                                    <span class="tag">#커피</span>
                                    <span class="tag">#핸드드립</span>
                                    <span class="tag">#브런치</span>
                                    <span class="tag">#아인슈페너맛집</span>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </article>
                <article class="article-box half-type">
                    <h3 class="article-tit">게시정보</h3>                    
                    <ul class="article-items">
                        <li>
                            <dl class="row-info">
                                <dt>공지</dt>
                                <dd>
                                    <a href="#" role="button">총:<em>5</em>건</a>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl class="row-info">
                                <dt>이벤트</dt>
                                <dd>
                                    <a href="#" role="button">총:<em>5</em>건</a>
                                </dd>
                                <dd>
                                    <a href="#" role="button">진행중:<em>5</em>건</a>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl class="row-info">
                                <dt>후기</dt>
                                <dd>
                                    <a href="#" role="button">총:<em>5</em>건</a>
                                </dd>
                                <dd>
                                    <a href="#" role="button">문의:<em>5</em>건</a>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl class="row-info">
                                <dt>찜</dt>
                                <dd>
                                    <a href="#" role="button">총:<em>100</em>건</a>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </article>
                <article class="article-box half-type">
                    <h3 class="article-tit">메뉴</h3>
                    <div class="article-info">
                        <dl class="row-info menu-all">
                            <dt>전체</dt>
                            <dd><em>50</em>개</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>커피</dt>
                            <dd><em>20</em>개</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>차</dt>
                            <dd><em>3</em>개</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>디저트</dt>
                            <dd><em>10</em>개</dd>
                        </dl>
                        <dl class="row-info">
                            <dt>행사음료</dt>
                            <dd><em>20</em>개</dd>
                        </dl>
                        <a href="#" role="button" class="btn-more"><i class="blind">더보기</i></a>
                    </div>
                </article>
            </section>
        </main>
</template>

<script>

export default {
  name: 'dashboard'
}
</script>
