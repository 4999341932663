<template>
    <header class="layer-header">
        <button type="button" class="btn-close-bar"><i class="blind">Layer Close</i></button>
        <h1>비밀번호 변경</h1>
    </header>
    <section class="layer-cont">
        <div class="pwd-account">
            <h2 class="blind">정보입력</h2>
            <div class="frm-box line-box">
                <label for="user-pwd">현재 비밀번호</label>
                <input type="password" id="before-pwd" ref="pw" v-model="user.pw" placeholder="비밀번호를 입력해주세요." title="비밀번호">
            </div>
            <div class="frm-box line-box">
                <label for="user-pwd1">변경 비밀번호<i class="blind">필수사항</i></label>
                <input type="password" id="new-pwd1" ref="newPw1" v-model="user.newPw1" placeholder="영문, 숫자 필수, 4~8자리" @keyup="goCheckPw" title="변경 비밀번호">
                <p class="err-txt" v-show="!pwLengthChk" >비밀번호는 8자리 이상, 숫자와 문자, 특수문자(!@#$%)가 포함되어야 합니다.</p>
            </div>
            <div class="frm-box line-box" :class="{'err-box' : !pwChk}"><!-- 오류경우 .err-box -->
                <label for="user-pwd2">변경 비밀번호 확인<i class="blind">필수사항</i></label>
                <input type="password" id="new-pwd2" ref="newPw2" v-model="user.newPw2" placeholder="영문, 숫자 필수, 4~8자리" @keyup="goCheckPwRe" title="변경 비밀번호 재입력">
                <p class="err-txt userPwRe" v-show="!pwChk" >비밀번호가 일치하지 않습니다.</p>
            </div>
        </div>
    </section>
    <footer class="layer-footer">
        <div class="btn-area">
            <button type="button" class="btn-full line-type" @click="closeLayer()">취소</button>
            <button type="button" class="btn-full" @click="goPwChg">설정</button>
        </div>
    </footer>
</template>

<script setup>

import { useStore } from "vuex";
import { ref, onMounted, getCurrentInstance, computed } from 'vue'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore();

//레이어 처리
function closeLayer(){
    const layer = {};
    layer.show = false;
    layer.name = 'pwChg';
    store.dispatch('layer/setLayerLst', layer)
}

//비밀번 유효성 검사

const user = ref({
            pw : '',
            newPw : '',
            newPw1 : '',
            newPw2 : '',
});

const pwLengthChk = ref(false);
const pwChk = ref(false);

const pw = ref(null);
const newPw1 = ref(null);
const newPw2 = ref(null);

function goCheckPw(){

    //에러 메시지 삭제
    pwLengthChk.value = true;

    //8자리 이상, 숫자와 문자, 특수문자 포함 정규식
    const reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/;
    const pwStr = user.value.newPw1;
    if( !reg.test(pwStr) ) {
        pwLengthChk.value = false;
        return false;
    }

    goCheckPwRe();
}

function goCheckPwRe() {
    pwChk.value = true;

    const pw1 = user.value.newPw1;
    const pw2 = user.value.newPw2;

    if(pw1 != pw2){
        pwChk.value = false;
    }
}

async function goPwChg(){
    if(pw.value.value == ''){
        pw.value.focus();
        return false;
    }else if(!pwLengthChk.value){
        newPw1.value.focus();
        return false;
    }else if (!pwChk.value){
        newPw2.value.focus();
        return false;
    }
    user.value.newPw = user.value.newPw1;

    await $axios({
        method: 'POST',
        url: '/cust/pwChg',
        data: user.value,
    }).then((data) => {
        alert('비밀번호 변경이 완료되었습니다. ');
        user.value.pw = '';
        user.value.newPw = '';
        user.value.newPw1 = '';
        user.value.newPw2 = '';
        closeLayer();

    }).catch((error) => {
        console.log(error);
        const errorCode = error.response.data.resultCode;
        if(errorCode == 'M003'){
            alert('현재 비밀번호가 일치하지 않습니다. ');
            pw.value.focus();
        }else{
            alert('에러가 발생하였습니다. 관리자에게 문의하시기 바랍니다. ');
        }
    })

}

</script>