<template>
                    <!-- Start Content-->
                    <div class="container-fluid">

                        <!-- start page title -->
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box">
                                    <h4 class="page-title">롤 관리</h4>
                                </div>
                            </div>
                        </div>
                        <!-- end page title -->

                        <div class="row">
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <h4 class="header-title">롤 목록</h4>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="text-sm-end">
                                                    <button type="button" id="addNodeBtn" class="btn btn btn-danger mb-2 btn-sm" @click="openRegRoleModal()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end nav-->

                                        <table class="table table-hover table-centered mb-0">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th>번호</th>
                                                    <th>롤 명</th>
                                                    <th>사용여부</th>
                                                    <th>등록일</th>
                                                    <th>관리</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(role, index) in roleLst" :key="index" @click="goMenuRoleMappLst(role)">
                                                    <td>{{role.roleNum}}</td>
                                                    <td>{{role.roleNm}}</td>
                                                    <td>{{role.useYn}}</td>
                                                    <td>{{timestampToDate(role.regDt)}}</td>
                                                    <td @click.stop>
                                                        <button type="button" class="btn btn-info" v-on:click="openGetRoleModal(role.roleNum)">수정</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> <!-- end col-->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <h4 class="header-title">메뉴 매핑</h4>
                                            </div>
                                        </div>
                                        <!-- end nav-->
                                        <div class="tab-content">

                                            <div id="tree">

                                            </div>

                                        </div>
                                        <div class="text-sm-end">
                                            <button type="button" id="modBtn" class="btn btn btn-danger mb-2 btn-sm" @click="goModMenuRoleMapp()" :disabled="role.roleNum == null"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- container -->

                    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="roleModalLabel">코드 정보</h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                                </div>
                                <div class="modal-body">
                                    <form class="ps-3 pe-3" action="#">
                                        <div class="mb-3">
                                            <label for="subCodeNm" class="form-label">롤 명 </label>
                                            <input class="form-control" type="text" v-model="role.roleNm" required="" placeholder=""> 
                                        </div>
                                        <div class="mb-3">
                                            <label for="subCodeNm" class="form-label">사용여부</label>
                                            <!-- <input class="form-control" type="text" v-model="code.useYn" required="" placeholder="하위 코드명 등록">  -->
                                            <select class="form-control" type="text" v-model="role.useYn" required="" placeholder="사용유무를 선택해주세요">
                                                <option value="Y" selected>Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        
                                        </div>
                                        <div class="mb-3">
                                            <label for="subCodeNm" class="form-label">설명 </label>
                                            <textarea class="form-control" v-model="role.cntn"></textarea>
                                        </div>
                                        <div class="mb-3 text-center">
                                            <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" v-show="delBtnShow" @click="goDelRole()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                            <button type="button" id="modBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="modBtnShow" @click="goRegRole()"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                            <button type="button" id="addBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="addBtnShow" @click="goRegRole()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                            <button type="button" id="cancelBtn" class="btn btn-success  mb-2 btn-sm" v-on:click="hideRoleModel()" ><i class="mdi mdi-cancel me-2"></i>취소</button>
                                        </div>
                                    </form>
                                </div>
                            </div><!-- /.modal-content -->
                        </div><!-- /.modal-dialog -->
                    </div><!-- /.modal -->
</template>

<script>
import axiosUtil from '../../../../util/request';
import jstree from '../../../../assets/js/jstree/dist/jstree.js';
import "../../../../assets/js/jstree/dist/themes/default/style.min.css";

export default {
    components: {
        jstree
    },
    name: 'role',
    data : function() {
        return {
         roleLst : []
         ,role : {}
         ,menuTreeList:[]
         ,menuRoleMappLst:[]
         ,menuRoleMapp :{}
         ,menu : {}
         ,mode : ''   // GET, DEL, MOD, REG
         ,treeData : []
         ,delBtnShow : false
         ,modBtnShow : false
         ,addBtnShow : true
        }
    },
    created() { 
        this.init();
    },
    methods:{
        init : async function(){
            console.log('role.vue init')

            this.role = new Object();

            this.roleLst =  await this.goRoleLst(this.role);

            this.menu = new Object();

            this.menuTreeList =  await this.goMenuTreeList(this.menu);
            this.makeNode();
            this.makeTree();
        },
        /***
         *롤 목록을 조회한다. 
         */
        goRoleLst : async function(role){
            let tmpLst = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/role/lst', 
                data : role
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpLst = data.resultObj;
                }
            })
            return tmpLst;
        }, 
        goGetRole : async function(role){
            let tmpRole = new Object();
            const test = await axiosUtil({
                method: 'POST',
                url: '/role/get', 
                data : role
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null  ){
                    tmpRole = data.resultObj;
                }
            })
            return tmpRole;
        }, 
        openRegRoleModal : function(){
            this.role = new Object();
            this.openRoleModal('REG');
        }, 
        openGetRoleModal : async function(roleNum){
            // 상위코드
            this.role = new Object();
            this.role.roleNum = roleNum;
            this.role = await this.goGetRole(this.role);

            this.openRoleModal('MOD');
        },
        openRoleModal : function(mode){
            this.mode = mode;


            // GET, DEL, MOD, REG
            if( mode == 'REG' ){
                this.delBtnShow = false;
                this.modBtnShow = false;
                this.addBtnShow = true;
                this.role.useYn = 'Y';
            }else if( mode == 'MOD' ){
                this.delBtnShow = true;
                this.modBtnShow = true;
                this.addBtnShow = false;

            }
            var roleModal = document.getElementById("roleModal");
            var roleModalModal = new bootstrap.Modal(roleModal, {});
            roleModalModal.show();
        },
        hideRoleModel : function(){
            var roleModal = document.getElementById("roleModal");
            var tmpModal = bootstrap.Modal.getInstance(roleModal)
            tmpModal.hide();
        }, 
        /***
         *메뉴 목록을 조회한다. 
         */
        goMenuTreeList : async function(menu){
            let tmpMenuList = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/menu/lst', 
                data : menu
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpMenuList = data.resultObj;
                }
            })
            return tmpMenuList;
        }, 
        goMenuRoleMappLst : async function(role){
            $('#tree').jstree("deselect_all");
            this.role = role;
            const test = await axiosUtil({
                method: 'POST',
                url: '/role/menuRoleMappLst', 
                data : role
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    this.menuRoleMappLst = data.resultObj;
                    this.selectNode(this.menuRoleMappLst);
                }
            })
        }, 
        goRegRole: function(){
            axiosUtil({
                method: 'POST',
                url: '/role/reg', 
                data : this.role
             }).then((data) => {
                this.$router.go();
            })
        }, 
        goRole: function(){
            this.$router.go()
        },
        makeNode : function() {
            var treeNodes = [];
            this.menuTreeList.forEach((item) => {
                var node = {};
                node.id = item.menuNum;
                item.upMenuNum == -1 ? node.parent = "#" : node.parent = item.upMenuNum;
                node.text = item.menuNm;
                treeNodes.push(node);
            });
            this.treeData = treeNodes;
        },
        selectNode : function(menuRoleMappLst){
            menuRoleMappLst.forEach((item) => {
                $('#tree').jstree("select_node", item.menuNum);
            });
        }, 
        goModMenuRoleMapp: function() {
            this.menuRoleMapp.roleNum = this.role.roleNum;

            //매핑 테이블에서 해당 롤이 가지고 있는 매핑 정보를 전부 삭제하고 다시 넣을 수 있도록 구성한다.
            //우선 jstree에서 선택된 것들에 대한 리스트를 가져오는 방법을 찾는다.

            let selMenuRoleMappLst = [];
            const selMenuLst = $("#tree").jstree(true).get_selected();  
            const selRoleNum = this.role.roleNum;
            $.each(selMenuLst, function(index, item){   
                let node = {};
                node.menuNum = item;
                node.roleNum = selRoleNum;
                selMenuRoleMappLst.push(node);
            });
            //가져온 정보로 axiousUtil을 foreach 할 건지, 리스트로 넘길 건지 결정한다.

            this.menuRoleMapp.menuLst = selMenuLst;
            
            axiosUtil({
                method: 'POST',
                url: '/role/regMenuRoleMapp', 
                data : this.menuRoleMapp,
            }).then((data) => {
                alert("수정되었습니다. ");
            })
            
        },
        makeTree(){
            $('#tree').jstree({ 
                'core' : {
                    'check_callback' : true,
                    'data' : this.treeData,
                },
	            "plugins" : [ "checkbox"],
                'checkbox' : {
                    'three_state': false,
                }
                })

                $('#tree').jstree(true).settings.core.data = this.treeData;
                $('#tree').jstree(true).refresh(); 

                //처음 시작 시 모든 노드가 확장된 상태로 유지하기 위해 사용. 설정값 찾으면 삭제 필요
                setTimeout(function(){
                $('#tree').jstree('open_all');
                },200);
        },
        addNode:function(){
            var ref = $('#tree').jstree(true);
            var sel = ref.get_selected();
            var newNode = false;

            if(!sel.length) { return false; }
            sel = sel[0];
            newNode = ref.create_node(sel, {"text":"새로운 메뉴"});
            if(newNode) {
                ref.open_node(sel);
                ref.deselect_all();
                ref.select_node(newNode);
                this.menu = {};
                this.menu.upMenuNum = sel;
                this.menu.upMenuNm = ref.get_text(sel);
                this.menu.useYn = 'Y';
            }	
        },
        timestampToDate(dateTime){
             if(dateTime == '') return '';
            // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
            var js_date = new Date(dateTime);

            // 연도, 월, 일 추출
            var year = js_date.getFullYear();
            var month = js_date.getMonth() + 1;
            var day = js_date.getDate();
            var hh = js_date.getHours();
            var mm = js_date.getMinutes();
            var ss = js_date.getSeconds();

            // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
            if(month < 10){
                month = '0' + month;
            }

            if(day < 10){
                day = '0' + day;
            }

            if(hh < 10){
                hh = '0' + hh;
            }

            if(mm < 10){
                mm = '0' + mm;
            }

            if(ss < 10){
                ss = '0' + ss;
            }

            // 최종 포맷 (ex - '2021-10-08 05:17:20')
            return year + '-' + month + '-' + day + " " + hh + ":" + mm + ":" + ss;
        }
    },
    filters : {
        
    },
    mounted(){

    },
  computed: {
  },
}
</script>
