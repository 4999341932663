<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-close" onclick="history.back()"><i class="blind">취소</i></button>
            <em class="txt-cafe">후기 작성</em>
        </header>
        <section class="section">
            <h2 class="title">어떤 점이 좋았나요?</h2>
            <p class="desc">이 카페에 어울리는 키워드를 골라주세요. (0개~3개)</p>
            <div class="line-type">
                <div class="list-item" v-for="(kywr, index) in kywrCodeLst" :key="index">
                    <div class="list-box" :class="kywr.ref1">
                        <span class="list-chk tit"><input type="checkbox" :id="kywr.codeNum"  :value="kywr.codeNum" v-model="kywrArray" @click="clickKywr($event, kywr)"><label :for="kywr.codeNum">{{ kywr.codeNm }}</label></span>
                    </div>
                </div>
            </div>
            <h2 class="title">상세한 후기를 써주세요.</h2>
            <div class="frm-box border-line-box">
                <label for="user-id" class="blind">내용</label>
                <textarea placeholder="내용을 입력하세요." v-model="cafeRvw.cntn"></textarea>
            </div>
            <p class="count">0 / 50</p>
            <h2 class="title">사진을 올려주세요.<small>(선택)</small></h2>
            <div class="scroll-cont">
                <ul class="pic-list">
                    <li>
                        <a href="#Modal" role="button" class="btn-pic-regist"><span class="blind">사진 추가</span></a>
                        <p class="count">0/5</p>
                    </li>
                    <!-- <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-02.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-03.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-02.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-03.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li>
                    <li>
                        <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                        <button type="button" class="btn-del"><i class="blind">사진 삭제</i></button>
                    </li> -->
                </ul>
            </div>
            <span class="frm-box txt-box">
                <input type="checkbox" id="frm-chk" v-model="cafeRvw.undcovYn" true-value="Y" false-value="N"><label for="frm-chk">사장님에게만 공개</label>
            </span>
            <div class="btn-area">
                <button type="button" class="btn-full" :disabled="writeBtnDisable" @click="clickWriteBtn()">후기 작성 완료</button>
            </div>
        </section>
    </div>
</template>

<script setup>

import { ref, reactive, onMounted, getCurrentInstance, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

const code = ref({});
const kywrCodeLst = ref([]);

const cafeRvw = ref({});
const kywrArray = ref([]);

const writeBtnDisable = ref(true);


function clickKywr(e, kywr){

    let cnt = 0;
    kywrCodeLst.value.forEach((kywr) => {
        kywr.chkYn ? cnt++ : cnt
    })
    if( cnt > 2){
        e.target.checked = false;
    }
    kywr.chkYn = e.target.checked;
}

async function goGetCode(code){
    let tmpKywrCodeLst;
    await $axios({
        method: 'POST',
        url: '/code/lst', 
        data : code
        }).then((data) => {
        tmpKywrCodeLst = data.resultObj;
    })
    return tmpKywrCodeLst;
}

async function clickWriteBtn(){
    cafeRvw.value.kywrCode = kywrArray.value.toString();
    cafeRvw.value = await goReg(cafeRvw.value);
}

async function goReg(cafeRvw){
    let tmpCafeRvw = new Object;
    await $axios({
    method: 'POST',
    data: cafeRvw,
    url: '/cafeRvw/reg',
    }).then((data) => {
        var resultObj = data.resultObj
        if(resultObj != null){
            tmpCafeRvw = resultObj
            alert("리뷰 등록이 완료되었습니다. ");
        }
    }).catch((error) => {
        alert("에러 발생");
    })
    
    return tmpCafeRvw;
}

onMounted( async () => {
    code.value.upCodeNum = '6029';
    kywrCodeLst.value = await goGetCode(code.value);

    cafeRvw.value.cafeNum = route.query.cafeNum;
});

watch(cafeRvw, (currentValue, oldValue) => {
    if(cafeRvw.value.cntn == ''){
        writeBtnDisable.value = true;
    }else{
        writeBtnDisable.value = false;
    }
},{ deep: true });
</script>