<template>
<transition>
    <div class="layer-wrap" id="myAccount" v-show="layer.show">
        <template v-if="props.name == 'pwChg'">
            <pw-chg></pw-chg>
        </template>
        <template v-else-if="props.name == 'cafeFilter'">
            <cafe-filter></cafe-filter>
        </template>
        <template v-else>
            <header class="layer-header">
                <button type="button" class="btn-close-bar" onclick="closeLayer()"><i class="blind">Layer Close</i></button>
                <h1 class="blind">Filter</h1>
            </header>
            <section class="layer-cont">
                <dl class="filter">
                    <template v-for ="(item, index) in this.lst" :key="index" >
                        <dt v-if="index == 0" calss="tit">
                            {{ item.upCodeNm }}
                        </dt>
                        <dd>
                            <span class="tag-chk"><input type="checkbox" :id="'layer_' + item.codeNum" :value="item.codeNum" ><label :for="'layer_' + item.codeNum" :value="item.codeNum">{{ item.codeNm }}</label></span>
                        </dd>    
                    </template>
                    <dt class="tit">애견동반</dt>
                    <dd>
                        <span class="tag-chk"><input type="checkbox" id="chk-21" checked><label for="chk-21">가능</label></span>
                    </dd>
                    <dd>
                        <span class="tag-chk"><input type="checkbox" id="chk-22"><label for="chk-22">불가능</label></span>
                    </dd>
                    <dt class="tit">화장실</dt>
                    <dd>
                        <span class="tag-chk"><input type="checkbox" id="chk-31" checked><label for="chk-31">야외</label></span>
                    </dd>
                    <dd>
                        <span class="tag-chk"><input type="checkbox" id="chk-32"><label for="chk-32">남여공용</label></span>
                    </dd>
                </dl>
            </section>
            <footer class="layer-footer">
                <button type="button" class="btn-full" @click="clickEnter" >확인</button>
            </footer>
        </template>
    </div>
</transition>
</template>

<script setup>

import { ref, reactive, defineProps, watchEffect, watch, onMounted } from "vue";
import { useStore } from "vuex";

//components
import pwChg from '@/page/main/views/common/layerPage/pwChg.vue';
import cafeFilter from '@/page/main/views/common/layerPage/cafeFilter.vue';

const layer = reactive({
                name : 'layer',
                show : false
            })
const lst = []
const selLayer = []

//부모 컴포넌트에서 넘겨준 값을 받는다.
const props = defineProps({
  name: String
})

//vuex 설정
const store = useStore();

function setLayerValues (){
    let getLayerByName = store.state.layer.layerLst[props.name];
    layer.name = getLayerByName.name;
    layer.show = getLayerByName.show;
}

onMounted( () => {
    setLayerValues();
})

watch(store.state.layer.layerLst, (currentValue, oldValue) => {
        setLayerValues();
    }
    ,{ deep: true });

</script>

<style>

.v-enter-from, .v-leave-to {
    bottom: -86vh;
}

.v-enter-active, .v-leave-active {
    transition: all 0.3s ease-out;
}

.v-enter-to, .v-leave-from {
    bottom: 0
}


</style>