<template>
    <div id="tabCont-03" class="tabcontent">
        <h3 class="blind">후기</h3>
        <div class="tabCont-group">
            <strong class="tabCont-tit">이런 점이 좋았어요!</strong>
            <span class="txt-list-count">(233명 참여)</span>
            <div class="list-wrap line-type"><!-- 전체보기(.all-open) -->
                <div class="list-item">
                    <div class="list-box type-01">
                        <strong class="tit">커피가 맛있어요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:80%"><i class="blind">80%</i></span>
                        </div>
                        <span class="count">9,999</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-02">
                        <strong class="tit">디저트가 다양해요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:60%"><i class="blind">60%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-03">
                        <strong class="tit">접근성이 좋아요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:20%"><i class="blind">20%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-04">
                        <strong class="tit">직원분이 친절해요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:20%"><i class="blind">20%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-05">
                        <strong class="tit">콘센트가 많아요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:20%"><i class="blind">20%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-06">
                        <strong class="tit">공부하기 좋아요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:20%"><i class="blind">20%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box type-07">
                        <strong class="tit">특별한 메뉴가 있어요</strong>
                        <div class="graph-box">
                            <span class="bar" style="width:20%"><i class="blind">20%</i></span>
                        </div>
                        <span class="count">198</span>
                    </div>
                </div>
            </div>
            <button type="button" class="btn-list-all"><i class="blind">Show</i></button>
            <!--<button type="button" class="btn-list-all close"><i class="blind">Close</i></button>-->
        </div>
        <strong class="tabCont-tit">사진후기</strong>
        <div class="tabCont-group scroll-cont">
            <ul class="pic-list">
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-02.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-03.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-02.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-03.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@pic-01.png" alt=""></a>
                </li>
                <li>
                    <a href="#Modal" role="button" class="btn-pic-more"><span>사진<br>더보기</span></a>
                </li>
            </ul>
        </div>
        <div class="tabCont-group scroll-cont">
            <strong class="tabCont-tit">리뷰<em class="count">255</em></strong>
            <p class="tabCont-desc"><span>실제 이용자들의 리뷰를 확인해보세요.</span></p>
            <div class="sort-area">
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk" checked><label for="frm-chk">사진 리뷰만</label>
                </span>
                <div class="toggle-box">
                    <button tyle="button" class="btn-toggle active">최신순</button>
                    <button tyle="button" class="btn-toggle">메뉴순</button>
                </div>
            </div>
            <div class="list-wrap full-type">
                <div class="list-item">
                    <div class="list-box">
                        <div class="account-info">
                            <span class="pic-box"><img src="../../../../../../public/mycafe/images/dummy/@my-pic.jpg" alt=""></span>
                            <div class="txt-box">희정이밥버거</div>
                            <div class="date-box">3일전</div>
                        </div>
                        <!-- (Swiper) -->
                        <div class="swiper view-swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                        <!-- //(Swiper) -->
                        <div class="list-info">
                            <div class="txt">프리미엄 디저트 카페!<br>
                                새로운 시도였고 새로운 맛이었습니다. 디저트는 1인 1주문을 해야했구요, 저는 차 두 잔에 디저트 핀즈 시그니처와 피치 베르를 주문했습니다...
                                <button type="button" class="btn-more"><span>더보기</span></button>
                            </div>
                            <div class="tag-box">
                                <span class="tag">잉글리쉬 블랙퍼스트 티라떼</span>
                                <span class="tag">아메리카노</span>
                            </div>
                            <div class="tag-box img-type">
                                <span class="tag type-01">커피가 맛있어요</span>
                                <span class="tag type-02">디저트가 다양해요</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box">
                        <div class="account-info">
                            <span class="pic-box"><img src="../../../../../../public/mycafe/images/dummy/@my-pic.jpg" alt=""></span>
                            <div class="txt-box">희정이밥버거</div>
                            <div class="date-box">3일전</div>
                        </div>
                        <!-- (Swiper) -->
                        <div class="swiper view-swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                                <div class="swiper-slide">
                                    <figure class="item">
                                        <img src="../../../../../../public/mycafe/images/dummy/@thum_view-375x282-01.jpg" alt="">
                                    </figure>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                        <!-- //(Swiper) -->
                        <div class="list-info">
                            <div class="txt">프리미엄 디저트 카페!<br>
                                새로운 시도였고 새로운 맛이었습니다. 디저트는 1인 1주문을 해야했구요, 저는 차 두 잔에 디저트 핀즈 시그니처와 피치 베르를 주문했습니다...
                                <button type="button" class="btn-more"><span>더보기</span></button>
                            </div>
                            <div class="tag-box">
                                <span class="tag">잉글리쉬 블랙퍼스트 티라떼</span>
                                <span class="tag">아메리카노</span>
                            </div>
                            <div class="tag-box img-type">
                                <span class="tag type-01">커피가 맛있어요</span>
                                <span class="tag type-02">디저트가 다양해요</span>
                            </div>
                        </div>
                        <!-- 사장님, 덧글 -->
                        <div class="owner-reply">
                            고양이너무조은고양님,<br>
                            안녕하세요~ 앗뜨죽입니다<br>
                            늘 드시는 분들이 건강하시고 기분좋게 드시길 바라는마음으로 정성을 담고 있습니다.
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-box">
                        <div class="account-info">
                            <span class="pic-box"><img src="../../../../../../public/mycafe/images/dummy/@my-pic.jpg" alt=""></span>
                            <div class="txt-box">희정이밥버거</div>
                            <div class="date-box">3일전</div>
                        </div>
                        <div class="info-txt">사장님에게만 보이는 리뷰입니다.</div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn-full" @click="goRouterPush('cafeRvwWrite', 'name', route.params.cafeNum)">후기 작성</button>
    </div>
</template>

<script setup>

import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

const cafe = ref({});

cafe.value.cafeNum = route.params.cafeNum;

function goRouterPush(data, type, query) {
    if(type == 'path'){
        router.push({
            path: data,
        }).then((() =>window.scrollTo(0,0) ));
    }else if(type == 'name'){
        router.push({
            name: data,
            query: {cafeNum : query}
        }).then((() =>window.scrollTo(0,0) ));
    }
}

onMounted( async () => {

});


</script>