<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">회원가입</em>
        </header>
        <div class="account-wrap">
            <h2 class="blind">정보입력</h2>
            <div class="frm-box line-box">
                <label for="user-id" class="required">아이디<i class="blind">필수사항</i></label>
                <span class="frm-flex">
                    <input type="text" id="user-id" v-model="user.id" placeholder="아이디를 입력해주세요." title="아이디" required @keydown="this.resetIdChkObj">
                    <button type="button" class="btn-line" @click="this.goCheckIdDup">중복 확인</button>
                </span>
                <p class="err-txt" v-show="!this.idChkObj.status" >{{ this.idChkObj.message }}</p>
            </div>
            <div class="frm-box line-box" :class="{'err-box' : !this.pwLengthChk}">
                <label for="userPw" class="required">비밀번호<i class="blind">필수사항</i></label>
                <input type="password" id="userPw" ref="userPw" v-model="user.pw" placeholder="영문, 숫자 필수, 8자리 이상" @keyup="this.goCheckPw" tite="비밀번호" required>
                <p class="err-txt" v-show="!this.pwLengthChk" >비밀번호는 8자리 이상, 숫자와 문자, 특수문자(!@#$%)가 포함되어야 합니다.</p>
            </div>
            <div class="frm-box line-box" :class="{'err-box' : !this.pwChk}"><!-- 오류경우 .err-box -->
                <label for="userPwRe" class="required">비밀번호 재입력<i class="blind">필수사항</i></label>
                <input type="password" id="userPwRe" ref="userPwRe" placeholder="영문, 숫자 필수, 8자리 이상" tite="비밀번호 재입력" @keyup="this.goCheckPwRe" required>
                <p class="err-txt userPwRe" v-show="!this.pwChk" >비밀번호가 일치하지 않습니다.</p>
            </div>
            <div class="frm-box line-box">
                <label for="user-nick" class="required">닉네임<i class="blind">필수사항</i></label>
                <span class="frm-flex">
                    <input type="text" id="user-nick" v-model="user.nickNm"  placeholder="닉네임을 입력해주세요." tite="닉네임" required @keydown="this.resetNickNmChkObj">
                    <button type="button" class="btn-line" @click="this.goCheckNickNmDup">중복 확인</button>
                </span>
                <p class="err-txt" v-show="!this.nickNmChkObj.status" >{{ this.nickNmChkObj.message }}</p>
            </div>
            <div class="frm-box line-box">
                <label for="user-phone" class="required">휴대폰 번호<i class="blind">필수사항</i></label>
                <span class="frm-flex frm-phone">
                    <div class="select-box">
                        <select title="휴대폰 앞자리" v-model="user.cpNum1" required>
                            <option>010</option>
                        </select>
                    </div>
                    <input type="tel" id="user-phone" v-model="user.cpNum2" placeholder="휴대폰 앞자리" tite="휴대폰 번호" required>
                    <input type="tel" id="user-phone" v-model="user.cpNum3" placeholder="휴대폰 뒷자리" tite="휴대폰 번호" required>
                    <button type="button" class="btn-line">인증</button>
                </span>
            </div>
            <div class="frm-box">
                <span class="label">성별</span>
                <span class="frm-flex">
                    <span class="frm-chk">
                        <input type="radio" id="sex-female" name="frm-sex" value="1" v-model="user.gndrCode" checked>
                        <label for="sex-female">여자</label>                 
                    </span>
                    <span class="frm-chk">
                        <input type="radio" id="sex-male" name="frm-sex" value="2" v-model="user.gndrCode">
                        <label for="sex-male">남자</label>
                    </span>
                </span>
            </div>
            <div class="frm-box line-box">
                <label for="birth">생년월일</label>
                <input type="text" id="user-birth" v-model="user.bithYmd" placeholder="YYYY    -    NN    -    DD" tite="생년월일" maxlength="10" @keyup="this.goCheckBithYmd">
            </div>
            <button type="button" class="btn-full" @click="this.goRegUser">회원가입</button>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import cnst from '@/util/cnst';

export default {
    name : 'sgnUpInfo'
    ,components:{
    }
    ,data : function() {
        return {
            user : {},
            idChkObj : {
                        status: false,
                        message: '',
                        },
            nickNmChkObj : {
                        status: false,
                        message: '',
                        },
            pwLengthChk : false,
            pwChk : false,
            cpNumChk : false,
            tnsChkLst : {},
            idChkMessage : {
                INIT : '아이디 중복 확인이 필요합니다.',
                FAIL : '해당 아이디를 사용하실 수 없습니다.',
                RESET : '아이디 중복 확인을 다시 해주시기 바랍니다.',
            },
            nickNmChkMessage : {
                INIT : '닉네임 중복 확인이 필요합니다.',
                FAIL : '해당 닉네임을 사용하실 수 없습니다.',
                RESET : '닉네임 중복 확인을 다시 해주시기 바랍니다.',
            }
        }
    }
    ,created(){
        this.init();
    }
    ,methods:{
        init:async function(){
            this.user = new Object();
            this.tnsChkLst = new Object();
            this.user.cpNum1 = '010';
            const route = useRoute();
            this.tnsChkLst = JSON.parse(route.query.paramTnsChkLst);
            this.user = {...this.user, ...this.tnsChkLst};
            this.user.gbn = cnst.USER.GBN.CUST;

            //messages
            this.idChkObj.message =this.idChkMessage.INIT;
            this.nickNmChkObj.message =this.nickNmChkMessage.INIT;
        },
        goCheckPw : function(){

            //const pw1 = document.querySelector("#userPw")
            const pw1 = this.$refs.userPw;
            //에러 메시지 삭제
            this.pwLengthChk = true;

            //8자리 이상, 숫자와 문자, 특수문자 포함 정규식
            const reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/;
            const pwStr = this.user.pw;
            if( !reg.test(pwStr) ) {
                this.pwLengthChk = false;
                return false;
            }

            this.goCheckPwRe();
        },
        goCheckPwRe : function(){
            this.pwChk = true;

            const pw1 = this.$refs.userPw;
            const pw2 = this.$refs.userPwRe;

            if(pw1.value != pw2.value){
                this.pwChk = false;
            }
        },
        goCheckIdDup: async function(){
            let tmpUser = new Object();
            tmpUser.id = this.user.id;
            tmpUser = await this.goGetUser(tmpUser)
            tmpUser == null ? this.idChkObj.status = true : this.idChkObj.status = false
            this.idChkObj.message = this.idChkMessage.FAIL;
            
        },
        resetIdChkObj : function(){
            if(this.idChkObj.status){
                this.idChkObj.status = false;
                this.idChkObj.message = this.idChkMessage.RESET;
            }
        },
        goCheckNickNmDup: async function(){
            let tmpUser = new Object();
            tmpUser.nickNm = this.user.nickNm;
            tmpUser = await this.goGetUser(tmpUser)
            tmpUser == null ? this.nickNmChkObj.status = true : this.nickNmChkObj.status = false;
            this.nickNmChkObj.message = this.nickNmChkMessage.FAIL;
        },
        resetNickNmChkObj : function(){
            if(this.nickNmChkObj.status){
                this.nickNmChkObj.status = false;
                this.nickNmChkObj.message = this.nickNmChkMessage.RESET;
            }
        },
        goGetUser : async function(user){
            let tmpUser = new Object();
            const test = await this.$axios({
                method: 'POST',
                data: user,
                url: '/cust/get',
                }).then((data) => {
                    console.log(data);
                    tmpUser =  data.resultObj;
                }).catch((error) => {
                    console.log(error);
                    alert("에러 발생");
                })

            return tmpUser;
        }, 
        goRegUser : async function(){
            //필수값 체크
            if(!this.user.id){
                alert('아이디를 체크해주세요. ');
                return false;
            }

            if(!this.idChkObj.status){
                alert('아이디 중복 확인이 필요합니다. ');
                return false;
            }

            if(!this.user.pw || !this.pwChk || !this.pwLengthChk){
                alert('패스워드를 체크해주세요. ');
                return false;
            }

            if(!this.user.nickNm){
                alert('닉네임을 체크해주세요. ');
                return false;
            }

            if(!this.nickNmChkObj.status){
                alert('닉네임 중복 확인이 필요합니다. ');
                return false;
            }

            if(!this.user.cpNum2 || !this.user.cpNum1 || !this.user.cpNum3){
                alert('휴대폰 번호를 체크해주세요. ');
                return false;
            }

            this.user.cpNum = this.user.cpNum1 + '-' + this.user.cpNum2 + '-' + this.user.cpNum3;

            await this.$axios({
                method: 'POST',
                data: this.user,
                url: '/cust/reg',
                }).then((data) => {
                    console.log(data);
                    this.goLogin();
                }).catch((error) => {
                    console.log(error);
                    alert("에러 발생");
                })

        },
        goCheckBithYmd : function(){
            var formatNum = this.user.bithYmd;

            // 공백제거
            formatNum = formatNum.replace(/\s/gi, "");
            try{
                if(formatNum.length == 8) {
                    formatNum = formatNum.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                }
            } catch(e) {
                formatNum = formatNum;
                console.log(e);
            }
            this.user.bithYmd = formatNum;
        },
        goLogin: async function() {
            await this.$axios({
                method: 'POST',
                data: this.user,
                url: '/user/login',
                }).then((data) => {
                    console.log(data);
                    this.goSgnUpComp();
                }).catch(error => {
                    console.log(error);
                    alert("회원가입은 완료하였으나 로그인 시도 중 오류가 발생하였습니다. 로그인 페이지에서 다시 로그인하시기 바랍니다. ")
                });
        },
        goSgnUpComp(event, cafeNum) {
            this.$router.push({
                name: 'sgnUpComp',
            }).then((() =>window.scrollTo(0,0) ));
        },
    }
}

</script>