export default {
    namespaced: true,
    state: {
        layerLst: {
            pwChg : {
                'name' : 'pwChg',
                'show' : false,
            },
            cafeFilter : {
                'name' : 'cafeFilter',
                'show' : false,
                selMoodLst : [],
                'selSrvcLst' : [],
            }
        },
        layer:{
            name : '',
        },
    },
    getters: {},
    mutations: {
        setLayerLst(state, payload) {
            state.layerLst[payload.name].show = payload.show;
        },
        setCafeFilterMoodLst(state, payload) {
            state.layerLst.cafeFilter.selMoodLst = payload
        },
        setCafeFilterSrvcLst(state, payload) {
            state.layerLst.cafeFilter.selSrvcLst = payload
        }
    },
    actions: {
        setLayerLst({ state, commit }, payload) {
            commit('setLayerLst', payload);
        },
        setCafeFilterMoodLst({ state, commit }, payload) {
            commit('setCafeFilterMoodLst', payload);
        },
        setCafeFilterSrvcLst({ state, commit }, payload) {
            commit('setCafeFilterSrvcLst', payload);
        },
    },
};
