<template>
    <div class="wrap">
        <header class="header-type--List">
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">마이페이지</em>
        </header>
        <div class="account-wrap">
            <h2 class="tit required">이용약관동의<i class="blind">필수사항</i></h2>
            <span class="frm-box txt-box all-chk-box">
                <input type="checkbox" id="allChk" ref="allChk" @click="allChkClick"><label for="allChk">전체 동의합니다.</label>
            </span>
            <p class="desc">선택 항목에 동의하지 않은 경우도 회원가입 및 일반적인 서비스를 이용할 수 있습니다.</p>
            <div class="frm-group">
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk-01" ref="tncCnstYn" name="rqrd" @click="allChkYn" v-model="tnsChkLst.tncCnstYn" true-value="Y" false-value="N"><label for="frm-chk-01">이용약관 동의<small>(필수)</small></label>
                    <button type="button" class="btn-arr" @click="goCntn('5')" ><i class="blind">약관상세보기</i></button>
                </span>
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk-02" ref="pipaCnstYn" name="rqrd" @click="allChkYn" v-model="tnsChkLst.pipaCnstYn" true-value="Y" false-value="N" ><label for="frm-chk-02">개인정보 수집 및 이용동의<small>(필수)</small></label>
                    <button type="button" class="btn-arr" @click="goCntn('3')"><i class="blind">약관상세보기</i></button>
                </span>
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk-03" ref="posInfrSrvcCnstYn" name="rqrd" @click="allChkYn" v-model="tnsChkLst.posInfrSrvcCnstYn" true-value="Y" false-value="N" ><label for="frm-chk-03">위치정보서비스 이용약관<small>(필수)</small></label>
                    <button type="button" class="btn-arr" @click="goCntn('4')"><i class="blind">약관상세보기</i></button>
                </span>
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk-04" ref="ElctFincTrncCnstYn" name="rqrd" @click="allChkYn" v-model="tnsChkLst.ElctFincTrncCnstYn" true-value="Y" false-value="N" ><label for="frm-chk-04">전자금융거래 이용약관<small>(필수)</small></label>
                    <button type="button" class="btn-arr" @click="goCntn('6')"><i class="blind">약관상세보기</i></button>
                </span>
                <span class="frm-box txt-box">
                    <input type="checkbox" id="frm-chk-05" ref="mrktCnstYn" @click="allChkYn" v-model="tnsChkLst.mrktCnstYn" true-value="Y" false-value="N"><label for="frm-chk-05">마케팅 메시지 수신 동의<small>(선택)</small></label>
                    <button type="button" class="btn-arr" @click="goCntn('7')"><i class="blind">약관상세보기</i></button>
                </span>
            </div>
            <div class="btm-area">
                <button type="button" class="btn-full" :disabled="nextBtnDsb" @click="goSgnUp">다음</button>
            </div>
        </div>
    </div>
</template>

<script>

import axiosUtil from '@/util/request';

export default {
  name : 'tnsChk'
  ,components:{
  }
  ,data : function() {
    return {
        baseUrl : process.env.VUE_APP_SERVER_URL,
        nextBtnDsb : true,
        tnsChkLst : {
            tncCnstYn : 'N',
            pipaCnstYn : 'N',
            posInfrSrvcCnstYn : 'N',
            ElctFincTrncCnstYn : 'N',
            mrktCnstYn : 'N',
        },
        rqrdLst : ['tncCnstYn', 'pipaCnstYn', 'posInfrSrvcCnstYn', 'ElctFincTrncCnstYn'],
        allLst : []
        }
    }
  ,created(){
    this.init();

  },methods:{
    init:async function(){
        this.allLst = [...this.rqrdLst, 'mrktCnstYn'];
    },
    allChkClick : function(e){
        let tmpboolean = this.$refs.allChk.checked;
        for (let key in this.allLst) {
            this.$refs[this.allLst[key]].checked = tmpboolean;
        }
        this.nextBtnDsb = false;
    },
    allChkYn : function(e){
        let allChkYn = this.$refs.allChk;
        allChkYn.checked = true;

        for (let key in this.allLst) {
            !this.$refs[this.allLst[key]].checked ? allChkYn.checked = false : null;
        }

        this.nextBtnDsbChk();

    },
    nextBtnDsbChk : function(){
        let dsbYn = false;

        for (let key in this.rqrdLst) {
            !this.$refs[this.rqrdLst[key]].checked ? dsbYn = true : null;
        }

        this.nextBtnDsb = dsbYn;

    },
    goSgnUp(event, cafeNum) {
        this.$router.push({
            name: 'sgnUpInfo',
            query: {paramTnsChkLst : JSON.stringify(this.tnsChkLst)},
        }).then((() =>window.scrollTo(0,0) ));
    },
    goCntn(cntnNum) {
        this.$router.push({
            name: 'cntn',
            query: {cntnNum : cntnNum},
        }).then((() =>window.scrollTo(0,0) ));
    },
  }
}

</script>