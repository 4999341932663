<template>
    <div class="wrap">
        <header class="header-type--Account">
            <h1 class="logo"><i class="blind">MyCafe</i></h1>
        </header>
        <div class="account-wrap">
            <div class="frm-box line-box">
                <label for="user-id">아이디</label>
                <input type="text" id="user-id" ref="userId" v-model="user.id" placeholder="아이디를 입력해주세요." tite="아이디"
                    @keyup.enter="goLogin()">
            </div>
            <div class="frm-box line-box">
                <label for="user-pwd">비밀번호</label>
                <input type="password" id="user-pwd" ref="userPw" v-model="user.pw" placeholder="비밀번호를 입력해주세요." tite="비밀번호"
                    @keyup.enter="goLogin()">
            </div>
            <button type="button" class="btn-full" @click="goLogin()">로그인3</button>
            <div class="btn-group">
                <button type="button" class="btn-txt">아이디 찾기</button>
                <button type="button" class="btn-txt">비밀번호 찾기</button>
            </div>
            <div class="another-account-group">
                <p class="sect-txt"><span>SNS 계정으로 로그인하기</span></p>
                <button type="button" class="btn-ico naver" @click="clickNaverSmplLoginBtn"><i class="blind">네이버 계정으로
                        로그인하기</i> </button>
                <button type="button" class="btn-ico google" @click="clickGoogleSmplLoginBtn"><i class="blind">구글 계정으로
                        로그인하기</i></button>
                <button type="button" class="btn-ico kakao" @click="clickKakaoSmplLoginBtn"><i class="blind">카카오 계정으로
                        로그인하기</i></button>
                <!-- <button type="button" class="btn-ico fb"><i class="blind">페이스북 계정으로 로그인하기</i></button> -->
            </div>
            <div class="btm-area">
                <button type="button" class="btn-uline" @click="goRouterByName('tncChk')">회원가입이 필요하신가요?</button>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

//유저 정보 처리 시작
import cnst from '@/util/cnst';

const user = ref({});
const userId = ref(null);
const userPw = ref(null);
const baseUrl = process.env.VUE_APP_SERVER_URL;
const frontUrl = process.env.VUE_APP_FRONT_URL;

user.value.id = "";
user.value.pw = "";
user.value.gbn = cnst.USER.GBN.CUST;

function goRouterByName(name) {
    router.push({
        name: name,
    }).then(() => window.scrollTo(0, 0));
}


async function goLogin() {
    if (user.value.id == "") {
        userId.value.focus()
        return false;
    }

    if (user.value.pw == "") {
        userPw.value.focus()
        return false;
    }

    await $axios({
        method: 'POST',
        data: user.value,
        url: '/auth/login',
    }).then((data) => {

        console.log(data);
        if (data.resultYn == 'N') {
            alert('ID, PW를 확인 바랍니다.')
        } else {
            store.dispatch('cust/setCust', cnst.SMPL.NORMAL.CUST_REG_TYPE_CODE);
            goRouterByName('main');
        }
    }).catch(error => {
        alert("로그인에 실패하였습니다. ");
    });
}

//네이버 간편로그인

function clickNaverSmplLoginBtn() {

    const naverUrl = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
        + cnst.SMPL.NAVER.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.NAVER.CALLBACK_URL;

    window.location.href = naverUrl;

}

function clickKakaoSmplLoginBtn() {
    const kakaoUrl = 'https://kauth.kakao.com/oauth/authorize?client_id='
        + cnst.SMPL.KAKAO.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.KAKAO.CALLBACK_URL
        + '&response_type=code';
    window.location.href = kakaoUrl;

}

function clickGoogleSmplLoginBtn() {
    const googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id='
        + cnst.SMPL.GOOGLE.CLIENT_ID
        + '&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.GOOGLE.CALLBACK_URL
        + '&response_type=code'
        + '&scope=email profile';
    window.location.href = googleUrl;

}


onMounted(() => {


})

</script>