<template>
    <div class="wrap">
        <aside class="side-wrap">
            <h1 class="logo"><i class="blind">MyCafe</i></h1>
            <div class="account-area">
                <span class="pic-box"><img src="/mycafemngr/images/dummy/@my-pic.png" alt=""></span>
                <div class="txt-box"><em>breadcafe</em></div>
                <div class="desc-box">zzzxcv312@naver.com</div>
                <button type="button" class="btn-alarm on"><i class="blind">새알람</i></button><!-- 새알람(.on) -->
            </div>
			<mngr-menu />
        </aside>
        
        <router-view></router-view>

    </div>
</template>

<script>
import mngrMenu from './mngrMenu.vue'

export default {
  name: 'structure',
	components : {
		'mngr-menu' : mngrMenu,
	},
}
</script>
