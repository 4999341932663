<template>
<!-- main-banner(Swiper) -->

    <div class="section">
        <h2 class="sec-tit">오직 마이카페에서만</h2>
        <div class="swiper ad-swiper">

        <swiper
            :slides-per-view="1"
            :space-between="30"
            :loop="true"
            class="default-slider">
            <swiper-slide v-for="n in bnrLst" :key="n"> 
                <a href="#n" class="item">
                    <img :src="`${baseUrl}/file/getImg/${n.imgFile.fileNum}`">
                    <div class="swiper-slide">
                        <a href="#n" class="item">
                            <p class="guide">test item</p>
                        </a>
                    </div>
                </a>    
            </swiper-slide>
        </swiper>
        <button type="button" class="btn-list-more">전체보기</button>
        </div>
    </div>
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";

import axiosUtil from '@/util/request';
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name : 'custAdvBnr',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
          bnrLst:[]
         ,bnr : {}
         ,baseUrl : process.env.VUE_APP_SERVER_URL
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
            this.bnr = new Object();
            this.bnr.bnrCode = '1012';
            this.bnrLst =  await this.goGetBnrList(this.bnr);
        },
        /***
         *게시판 리스트를 조회를 한다. 
         */
        goGetBnrList : async function(bnr){
            let tmpBnrList = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/lst', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpBnrList = data.resultObj;
                }
            })
            return tmpBnrList;
        }, 
  }
  
}
</script>