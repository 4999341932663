<template>
            <nav class="store-nav">
                <a href="/manager/dashboard" role="button" class="nav-01 active">대시보드</a>
                <a href="#" role="button" class="nav-02">고객 관리</a>
                <a href="#" role="button" class="nav-03">주문 관리</a>
                <a href="#" role="button" class="nav-04">쿠폰/포인트 관리</a>
                <a href="#" role="button" class="nav-05">정산 관리</a>
                <a href="/manager/cafeinfo" role="button" class="nav-06">카페 관리</a>
            </nav>
</template>

<script>
export default {
  name: 'mngrMenu',
}
</script>
