<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">{{ cntn.ttl }}</em>
        </header>
        <div class="account-wrap">
            <div class="agree-area">
                <h2 class="tit">{{ cntn.ttl }}</h2>
                <div class="agree-txt">
                    {{ cntn.cntn }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//components
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();



//유저 정보 처리 시작
const user = ref({});


//
const cntn = ref({});


async function goGetCntn(){
    await $axios({
        method: 'POST',
        url: '/cntn/get',
        data: cntn.value,
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                cntn.value = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}


onMounted( () => {
    cntn.value.cntnNum = route.query.cntnNum;
    goGetCntn();
})
//유저 정보 처리 끝


</script>