<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">알람</em>
        </header>
        <div class="list-section">
            <ul class="list-box">
                <li v-for="(item, index) in newAlrmLst" :key="index" @click="goDetail(item)">
                    <a role="button" class="btn-txt">
                        <span clss="desc">{{ item.boardNm }}</span>
                        <strong class="tit">{{ item.ttl }}</strong>
                        <p class="date">{{ setTime(item.regDt) }}</p>
                        <img src="" alt="">
                    </a>
                </li>
            </ul>
            <p class="sect-txt" v-if="oldAlrmLst.length > 0"><span>이전 알림</span></p>
            <ul class="list-box">
                <li v-for="(item, index) in oldAlrmLst" :key="index" @click="goDetail(item)">
                    <a role="button" class="btn-txt">
                        <span clss="desc">{{ item.boardNm }}</span>
                        <strong class="tit">{{ item.ttl }}</strong>
                        <p class="date">{{ timeDifference(item.regDt) }}</p>
                        <img src="" alt="">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import boardEnum from "@/util/board.js" 
//components
import StringUtil from '@/util/StringUtil.js'

import normalBoard from '@/page/main/views/board/theme/normal.vue'
import dropdownBoard from '@/page/main/views/board/theme/dropdown.vue'
import evntBoard from '@/page/main/views/board/theme/evnt.vue'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

//게시판 정보 처리 시작
const board = ref({});
const alrmLst = ref([]);
const newAlrmLst = ref([]);
const oldAlrmLst = ref([]);


function goDetail(item){
    router.push({
        name: 'boardDetail',
        params : { boardNum:item.boardNum,
                    artiNum:item.artiNum}
    }).then((() =>window.scrollTo(0,0) ));;
}

async function goGetLst(board){
    let tmpBoard = new Object;
    await $axios({
        method: 'POST',
        url: '/alrm/lst',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoard = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoard;
}

function setTime(dt){
    return dt;
}

function timeDifference(timestamp) {
    var date1 = new Date()
    var date2 = new Date(timestamp);
    var difference = date1.getTime() - date2.getTime();

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    var secondsDifference = Math.floor(difference/1000);

    //분전, 시간 전, 일 전 으로 나눌 수 있어야 함.
    if(daysDifference > 0){
        return daysDifference + "일 전";
    }

    if(hoursDifference > 0){
        return hoursDifference + "시간 전";
    }

    if(minutesDifference > 0){
        return minutesDifference + "분 전";
    }

    if(secondsDifference > 0){
        return secondsDifference + "초 전";
    }
}

onMounted( async () => {
    alrmLst.value = await goGetLst(board.value);

    var newDate = new Date();
    newDate.setDate(newDate.getDate() -1);
    newAlrmLst.value = alrmLst.value.filter(board => board.regDt >= newDate)
    oldAlrmLst.value = alrmLst.value.filter(board => board.regDt < newDate);

    timeDifference(alrmLst.value[0].regDt);

})
//유저 정보 처리 끝


</script>