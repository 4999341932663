<template>
    <div class="wrap">
        <section class="section">
            <template v-if="board.boardSkinCode == boardEnum.SKIN.NORMAL">
                <normal-board :boardNum="board.boardNum"></normal-board>
            </template>
            <template v-else-if="board.boardSkinCode == boardEnum.SKIN.DROPDOWN">
                <dropdown-board :boardNum="board.boardNum"></dropdown-board>
            </template>
            <template v-else-if="board.boardSkinCode == boardEnum.SKIN.EVNT">
                <evnt-board :boardNum="board.boardNum"></evnt-board>
            </template>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import boardEnum from "@/util/board.js" 
//components
import StringUtil from '@/util/StringUtil.js'

import normalBoard from '@/page/main/views/board/theme/normal.vue'
import dropdownBoard from '@/page/main/views/board/theme/dropdown.vue'
import evntBoard from '@/page/main/views/board/theme/evnt.vue'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

//게시판 정보 처리 시작
const board = ref({});
const boardLst = ref([]);

function goDetail(artiNum){
    router.push({
        name: 'board',
        params : { boardNum:board.value.boardNum,
                    artiNum:artiNum}
    }).then((() =>window.scrollTo(0,0) ));;
}

async function goGet(board){
    let tmpBoard = new Object;
    await $axios({
        method: 'POST',
        url: '/board/get',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoard = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoard;
}


onMounted( async () => {
    board.value.boardNum = route.params.boardNum;

    board.value = await goGet(board.value);
    console.log(board);
})
//유저 정보 처리 끝


</script>