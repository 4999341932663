import axios from "axios";
import qs from "qs";
import errorHandler from '@/util/axiosErrorHandler';

const headers = {
    'Content-Type': 'application/json',
    Accept: "*/*",
};

const axiosInst = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: headers,
    withCredentials: true,
});

// axiosWrpr interceptor 설정
axiosInst.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        console.error("interceptors axiosWrpr Failed:", error.config);
        // Do something with axiosWrpr error
        return Promise.reject(error);
    }
);


axiosInst.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// axiosWrpr Wrapper with default success/error actions
const axiosWrpr = function (options) {
    //SuccessHandler
    const onSuccess = function (response) {
        return response.data;
    };

    return axiosInst(options).then(onSuccess).catch(errorHandler);
};

export default axiosWrpr;
