<template>
    <header>
        <h1 class="logo"><i class="blind">MyCafe</i></h1>
        <div class="util-group">
            <button v-if="role.isShowAlarm" type="button" class="btn-alarm" @click="goAlrm"><i class="blind">알람</i></button>
            <button type="button" class="btn-alarm on" @click="goAlrm"><i class="blind">새알람</i></button><!-- 새알람(.on) -->
        </div>
    </header>
</template>

<script setup>
import myCafeLoginUser from '@/user/myCafeLoginUser';
import cnst from '@/util/cnst';
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

//router 설정 
const router = useRouter()

//vuex 설정
const store = useStore();

// role에 따른 구분 
let role = ref({
    isShowAlarm: true,
})

function goAlrm(artiNum) {
    router.push({
        name: 'alrm',
        params: {}
    }).then((() => window.scrollTo(0, 0)));;
}

onMounted(() => {
    setRole();
});

function setRole() {
    if (myCafeLoginUser.isHasRole(cnst.USER.GBN.CUST)) {
        role.value.isShowAlarm = true;
    } else {
        role.value.isShowAlarm = false;
    }
}




</script>