<template>
    <div id="tabCont-02" class="tabcontent">
        <h3 class="blind">메뉴</h3>
        <div class="tabCont-group">
            <h2 class="tabCont-tit">제조음료</h2>
            <div class="list-wrap thumb-type">
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@menu_100x100-01.jpg" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">아이스 시그니처 초콜릿</strong>
                            <span class="desc">12,000원</span>
                            <div class="tag-box">
                                <span class="tag">#최강단맛</span>
                                <span class="tag">#휘핑추가</span>
                                <span class="tag">#인기메뉴</span>
                            </div>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-11" checked><label for="chk-11"><i class="blind">찜하기</i></label></span>
                </div>
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@menu_100x100-02.jpg" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">제주 청귤 라임 에이드</strong>
                            <span class="desc">4,500원</span>
                            <div class="tag-box">
                                <span class="tag">#시즌한정</span>
                            </div>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-12"><label for="chk-12"><i class="blind">찜하기</i></label></span>
                </div>
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@menu_100x100-03.jpg" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">제주 청귤 라임 에이드</strong>
                            <span class="desc">5,700원</span>
                            <div class="tag-box">
                                <span class="tag">#시즌한정</span>
                                <span class="tag">#제주전용</span>
                            </div>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-13"><label for="chk-13"><i class="blind">찜하기</i></label></span>
                </div>
            </div>
            <h2 class="tabCont-tit">커피</h2>
            <div class="list-wrap thumb-type">
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@menu_100x100-04.jpg" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">제주 청귤 라임 에이드</strong>
                            <span class="desc">6,300원</span>
                            <div class="tag-box">
                                <span class="tag">#시즌한정</span>
                                <span class="tag">#제주전용</span>
                            </div>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-21" checked><label for="chk-21"><i class="blind">찜하기</i></label></span>
                </div>
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@menu_100x100-05.jpg" alt="이미지 준비중입니다."></div>
                        <div class="list-info">
                            <strong class="tit">콜드 브루 플로트</strong>
                            <span class="desc">4,700원</span>
                            <div class="tag-box">
                                <span class="tag">#시즌한정</span>
                                <span class="tag">#제주전용</span>
                            </div>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-22"><label for="chk-22"><i class="blind">찜하기</i></label></span>
                </div>
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/common/menu-default.png" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">콜드 브루 플로트</strong>
                            <span class="desc">4,700원</span>
                        </div>
                    </a>
                    <span class="wish-box"><input type="checkbox" id="chk-23"><label for="chk-23"><i class="blind">찜하기</i></label></span>
                </div>
            </div>
            <button type="button" class="btn-list-modify">정보 수정 제안</button>
        </div>
    </div>
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import SwiperCore, { Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

import axiosUtil from '@/util/request';
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name : 'custBnr',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
         cafe : {}
         ,baseUrl : process.env.VUE_APP_SERVER_URL
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
        },

        goGetBnrList : async function(bnr){
            let tmpCafe = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/lst', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpBnrList = data.resultObj;
                }
            })
            return tmpBnrList;
        }, 
  }
  
}
</script>