<template>
    <div>
        <span v-for="(item, index) in codeLst" :class="{ active: item.chkYn == 'Y' }" :key="index" class="tag"
            :data-value="item.codeNum" :up-code-num="item.upCodeNum" @click="codeSpanClick">{{ item.codeNm
            }}</span>
    </div>
</template>

<script>
import axiosUtil from '@/util/request';

export default {
    name: 'tagSpan',
    created() {

    },
    props: { codeLst: Array },
    methods: {
        init: async function () {
            this.codeLst = await this.goGetCode();
        },
        codeSpanClick: function (e) {

            var chkYn = 'N'
            if (!e.target.classList.contains('active')) {
                chkYn = 'Y';
            }
            var codeInfo = {
                codeNum: e.target.getAttribute('data-value')
                , chkYn: chkYn
                , upCodeNum: e.target.getAttribute('up-code-num')
            }
            this.$emit('tagCodeSpanEmit', codeInfo);
        }
    }
}
</script>
