<template>
    <div class="section">
        <h2 class="sec-tit">따끈따끈한 신규 카페</h2>
        <cafe-four-greed :cafeLst="this.cafeLst" :cafeSrchCode="this.cafe.cafeSrchCode"/>
        <button type="button" class="btn-another-more" @click="this.goNextPage" ><em>다른 카페 추천 ( {{ this.cafe.pageNum == 0 ? 1 : this.cafe.pageNum}} <i>/ {{ this.pageObj.totalPage }}</i> )</em></button>
        <button type="button" class="btn-list-more" @click="this.goCafeLst(e, '2005')">전체보기</button> <!-- 상위코드 : 2001 -->
    </div>
</template>

<script>


import axiosUtil from '@/util/request';
import cafeFourGreed from "@/page/main/common/cafeFourGreed.vue";
import cafe from "@/util/cafe.js"

export default {
  name : 'custNewCafe'
  ,components:{
      'cafe-four-greed' : cafeFourGreed
  }
  ,data(){
    return {
      cafeLst : []
      ,cafe : {}
      ,pageObj :{totalCnt : 0 //최대 리스트 개수
                ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                ,pageSize : 1 //보여줄 페이징의 최대 개수
                ,pageBlockNum : 4 //한 페이지 내에 보여줄 리스트 개수
                }
    };
  }
  ,created(){
    this.init();
  },methods:{
    init:async function(){
        this.cafe = new Object();
        
        this.cafe.pageNum = this.pageObj.pageNum; //현재 페이지 번호
        this.cafe.pageSize = this.pageObj.pageSize; //최대 페이지 개수
        this.cafe.pageBlockNum = this.pageObj.pageBlockNum; //페이지 내에 보여줄 리스트 개수

        this.cafe.cafeSrchCode = cafe.SRCH.SRCH_CODE.NEW;

        this.cafeLst =  await this.goGetCafeList(this.cafe);
    },
    goNextPage : async function(){
        if(this.cafe.pageNum > 4){
          this.cafe.pageNum = 0;
        }
      this.cafeLst = await this.goGetCafeList(this.cafe);
    },
    goGetCafeList : async function(cafe){
        let tmpCafeLst = null
        const test = await axiosUtil({
            method: 'POST',
            url: '/cafe/lst', 
            data : cafe
            }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null && resultObj.length > 0 ){
                tmpCafeLst = data.resultObj;
                this.pageObj.totalCnt = data.totalCnt;
                let totalPage = Math.ceil(this.pageObj.totalCnt / this.pageObj.pageBlockNum);
                this.pageObj.totalPage = totalPage > 5 ? 5 : totalPage;
                this.cafe.pageNum++;
            }
        })
        return tmpCafeLst;
    }, 
    goCafeLst(event, searchCafe) {
        this.$router.push({
            name: 'cafeLst',
            query : { paramSrchCafe:searchCafe}
        }).then((() =>window.scrollTo(0,0) ));;
    },
  }
}

</script>