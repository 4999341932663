import axiosFileHelper from "@/util/axiosFileHelper";

// CAFE 분류
const cafe = {
  SRCH: {
    SRCH_CODE: {
      ALL : "2002",
      NEAR: "2003",
      THEME : "2004",
      NEW : "2005",
      FAV: "2006",
      RCMN: "3001",
    },
  },
};

cafe.getCafeFileImgFromLst = function(lst){
  for(var cafeModel of lst){
    if(cafeModel.cafeFileModelLst != undefined){
      cafeModel.cafeFileModelLst = this.setSrc(cafeModel.cafeFileModelLst);
    }
  }
  return lst;
}


cafe.getCafeFileImg = async function (cafeFileNum){
  let imgUrl = '';
  await axiosFileHelper({
      method: 'GET',
      url: '/cafeFile/img/profile/' + cafeFileNum,
  }).then((response) => {
      imgUrl = URL.createObjectURL(response.data)
  }).catch(error => {
      console.log(error);
      throw new Error(error);
  });
  return imgUrl;
}

cafe.setSrc = function (arr){
  if(arr != undefined){
      arr.forEach(async element => {
          if(!element.src){
              element.src = await this.getCafeFileImg(element.cafeFileNum)
          }
      });
   }
   return arr;
}

export default cafe;
