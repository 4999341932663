<template>
    <!-- Start Content-->
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{title}} </h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="mb-3">
                                    <label for="previewImg" class="form-label">미리보기</label>
                                    <img v-if="this.file.fileNum != undefined || this.file.fileNum != null" :src="`${baseUrl}/file/getImg/${file.fileNum}`" id="previewImg" alt="" class="image-box" style="width:100%">
                                    <img v-else id="previewImg" alt="" class="image-box" style="width:100%">
                                </div>
                            </div> <!-- end col-->
                        </div>
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->



            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrNm" class="form-label">배너명</label>
                                    <input type="text" id="bnrNm" class="form-control" v-model="bnr.bnrNm" placeholder="배너명을 입력해주세요.">
                                    <input type="hidden" id="fileGroupNum" class="form-control" v-model="bnr.fileGroupNum" />
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">배너 URL</label>
                                    <input type="text" id="bnrUrl" class="form-control" v-model="bnr.bnrUrl" placeholder="배너 URL을 입력해주세요.">
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">사용여부</label>
                                    <select class="form-control" v-model="bnr.useYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">내용</label>
                                    <div class="row g-2">
                                        <textarea class="form-control" id="cntn" rows="5" v-model="bnr.cntn"></textarea>
                                    </div>
                                </div>
                            </div> <!-- end col-->

                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">배너 이미지</label>
                                    <div v-if="this.file.fileNum == undefined || this.file.fileNum == null">
                                        <input type="file" id="bnrFile" class="form-control" @change="this.imgChange" >
                                    </div>
                                    <div v-else>
                                        {{file.fileNm}}
                                        <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" @click="goDelBnrImg()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrCode" class="form-label">배너 코드</label>
                                    <select class="form-control" v-model="bnr.bnrCode" required="">
                                        <sel-option upCodeNum="1009" :selCodeNum="bnr.bnrCode" codename="bnrCodeNm"></sel-option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">정렬 번호</label>
                                    <input type="number" id="bnrUrl" class="form-control" v-model="bnr.sortNum" placeholder="정렬 번호를 입력해주세요.">
                                </div>
                                <div class="mb-3">
                                    <label for="disYn" class="form-label">노출 여부</label>
                                    <select class="form-control" v-model="bnr.disYn" required="" placeholder="노출여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">노출 일시</label>
                                    <div class="row g-2">
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disStartDt" class="form-control" v-model="bnr.disStartDt" placeholder="">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disEndDt" class="form-control" v-model="bnr.disEndDt" placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- end col-->
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="text-sm-end">
                                    <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" v-show="delBtnShow" @click="goDelBnr()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    <button type="button" id="cancelBtn" class="btn btn btn-success mb-2 btn-sm" @click="goBnr()"><i class="mdi dripicons-wrong me-2"></i> 취소 </button>
                                    <button type="button" id="modifyBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="modBtnShow" @click="goRegBnr()"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                    <button type="button" id="addBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="addBtnShow" @click="goRegBnr()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                </div>
                            </div>
                        </div>

                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>
    </div> <!-- container -->
</template>

<script>
import axiosUtil from '@/util/request';
import selOption from '../../common/selOption.vue';

export default {
  components: { selOption },
    name: 'bnrWrite',
    data : function() {
        return {
         bnr : {}
         ,mode : ''   // GET, DEL, MOD, REG
         ,selBnrNum : 0
         ,file:{}
         ,title : '배너 추가'
         ,baseUrl : process.env.VUE_APP_SERVER_URL
         ,delBtnShow : false
         ,modBtnShow : false
         ,addBtnShow : true
        }
    },
    created() { 
        this.init();
    },
    methods:{
        init : async function(){
            console.log('bnr.vue init')
            this.bnr = new Object();

            this.bnr.useYn = 'N';
            this.bnr.disYn = 'N';

            this.selBnrNum = history.state.bnrNum;

            if(this.selBnrNum > 0){
                this.title = '배너 수정';
                this.bnr.bnrNum = this.selBnrNum;
                this.bnr = await this.goGetBnr(this.bnr);
                this.modBtnDis();

                this.bnr.disStartDt = this.Unix_timestamp(this.bnr.disStartDt);
                this.bnr.disEndDt = this.Unix_timestamp(this.bnr.disEndDt);

                if(this.bnr.imgFile != null)
                    this.file = this.bnr.imgFile;
            }else{
                this.regBtnDis();
            }
        },
        /***
         *게시판 리스트를 조회를 한다. 
         */
        goGetBnr : async function(bnr){
            let tmpBnr = new Object();
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/get', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null  ){
                    tmpBnr = resultObj;
                }
            })
            return tmpBnr;
        },
        regBtnDis : function(){
            this.delBtnShow = false;
            this.modBtnShow = false;
            this.addBtnShow = true;
        },
        modBtnDis : function(){
            this.delBtnShow = true;
            this.modBtnShow = true;
            this.addBtnShow = false;
        },
        imgChange:function(e){
            const fileDom = e.target;
            const imgDom = document.querySelector('#previewImg');

            imgDom.src = URL.createObjectURL(fileDom.files[0]);
        },
        goBnr() {
            this.$router.push({
                path: `/admin/bnr`
            });
        },
        goRegBnr: function(){

            const formData = new FormData();
            const bnrFileDom = document.querySelectorAll('#bnrFile');
            
            for(const file of bnrFileDom){
                for(const files of file.files){
                    formData.append('bnrFile', files);
                }
            }
            this.bnr.disStartDt = Math.floor(new Date(this.bnr.disStartDt).getTime());
            this.bnr.disEndDt = Math.floor(new Date(this.bnr.disEndDt).getTime());

            for(var key in this.bnr) {
                if(this.bnr[key] != null)
                formData.append(key, this.bnr[key]);
            }

            axiosUtil({
                method: 'POST',
                url: '/bnr/reg', 
                data : formData
             }).then((data) => {
                this.goBnr();
            })
        }, 
        goDelBnr: function(){
            if(confirm('정말로 삭제하시겠습니까?')){
                this.bnr.delYn = 'Y'
                axiosUtil({
                    method: 'POST',
                    url: '/bnr/reg', 
                    data : this.bnr
                }).then((data) => {
                    this.goBnr();
                })
            }
        }, 
        goDelBnrImg: async function(){
            if(confirm("정말 해당 이미지를 삭제하시겠습니까? 이미지 삭제는 바로 적용됩니다.")){
                const test = await axiosUtil({
                    method: 'POST',
                    url: '/file/del', 
                    data : this.file
                }).then((data) => {
                    var resultObj =  data.resultObj;
                    if(resultObj){
                        this.file = {};
                        this.init();
                    }else{
                    alert("해당 파일의 정보를 찾지 못했습니다. 관리자에게 문의하시기 바랍니다. ");
                    }
                })
            }
        },
        Unix_timestamp:function (t){
            var date = new Date(t);
            var year = date.getFullYear();
            var month = "0" + (date.getMonth()+1);
            var day = "0" + date.getDate();
            var hour = "0" + date.getHours();
            var minute = "0" + date.getMinutes();
            var second = "0" + date.getSeconds();
            return year + "-" + month.substr(-2) + "-" + day.substr(-2) + " " + hour.substr(-2) + ":" + minute.substr(-2) + ":" + second.substr(-2);
        },
    }
}
</script>
