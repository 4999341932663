<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <div class="search-box">
                <input type="text" placeholder="지역, 카페이름으로 찾아보세요." tite="검색어" v-model="srch.srchText1" @keypress.enter="goSrch" >
            </div>
        </header>
        <!-- filter -->
        <div class="filter-wrap">
            <div class="filter-box">
                <button type="button" class="btn-filter" @click="clickOpenLayer"><i class="blind">필터선택</i></button>
                <div class="tag-chk-box" ref="moodLstParent">
                    <template v-for="(item, index) in moodLst" :key="index">
                        <span class="tag" :class="{'active' : item.chkYn == 'Y'}" :data-value="item.codeNum" @click="clickMoodlst">#{{ item.codeNm }}</span>
                    </template>
                </div>
            </div>
        </div>
        <!-- //filter -->
        <!-- 최근 검색어 -->
        <div class="section top-line">
            <strong class="sec-stit">최근 검색어</strong>
            <div class="sch-box">
                <template v-for="(item, index) in srchHis" :key="index">
                    <span class="sch">{{ item }} <button type="button" class="btn-del" @click="delSrchHis" :data-value="item"><i class="blind">삭제</i></button></span>
                </template>
            </div>
            <button type="button" class="btn-all-delete" @click="delAllSrchHis">전체삭제</button>
        </div>
        <!-- //최근 검색어 -->
        <!-- 관심 급상승 검색어 -->
        <div class="section top-bg">
            <strong class="sec-stit">관심 급상승 검색어</strong>
            <ol class="sch-rank-list">
                <li v-for="(item, index) in hotKywrLst" :key="index" @click="clickHotKywr(item)">{{ item.codeNm }}</li>
            </ol>
        </div>
        <!-- //관심 급상승 검색어 -->
        <!-- 즐겨찾는 태그 -->
        <div class="section">
            <strong class="sec-stit">즐겨찾는 태그</strong>
            <div class="tag-box">
                <span class="tag">#커피</span>
                <span class="tag">#핸드드립</span>
                <span class="tag">#브런치</span>
                <span class="tag">#아인슈페너맛집</span>
            </div>
        </div>
        <!-- //즐겨찾는 태그 -->
        <div class="section">
            <button type="button" class="btn-full" @click="goSrch" >검색</button>
        </div>
    </div>
    <layer name="cafeFilter" ></layer>
</template>

<script setup>

import { ref, reactive, onMounted, onBeforeMount, watch, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//util
import cafeEnum from "@/util/cafe.js"
import CodeClass from "@/util/CodeClass.js";

//components
import layer from "@/page/main/views/common/layer.vue";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

//변수 선언
const srch = reactive({});

const code = reactive({});

const moodLst = ref([]);
const selMoodLst = ref([]);
const moodLstParent = ref(null);

const srchHis = ref([]);

const hotKywrLst = ref([]);


//레이어 처리
function clickOpenLayer(){
    const layer = {};
    layer.show = true;
    layer.name = 'cafeFilter';
    store.dispatch('layer/setLayerLst', layer)
}

function setFilterLst(){
    selMoodLst.value = store.state.layer.layerLst.cafeFilter.selMoodLst;
    moodLst.value = CodeClass.getCodeListByArray(moodLst.value, selMoodLst.value);
}

function clickHotKywr(item){
    srch.srchText1 = item.codeNm;
}

watch(store.state.layer.layerLst, (currentValue, oldValue) => {
        setFilterLst();
},{ deep: true });


onMounted( async () => {

    //보여주는 필터 정보
    code.upCodeNum = '4001';
    moodLst.value = await goGetCodeLst(code);

    //인기 급상승 검색어
    code.upCodeNum = '6010';
    hotKywrLst.value = await goGetCodeLst(code);

    //로컬스토리지에서 최근 검색어 확인
    let localStorageLst = JSON.parse(localStorage.getItem("srchHis")) || [];
    if(localStorageLst._value != undefined)
        srchHis.value = localStorageLst._value;
})

function init(){
    const route = useRoute();
    srch.srchText1 = route.query.paramSrchText1;
}

function goSrch(e) {
    const srchText1 = srch.srchText1;

    if (srchText1 && !srchHis.value.includes(srchText1)) {
        srchHis.value = srchHis.value.slice(-4).concat(srchText1);
        localStorage.setItem("srchHis", JSON.stringify(srchHis));
    }

    router.push({
        name: 'cafeLst',
        query : { paramSrchText1:srch.srchText1}
    }).then(() =>window.scrollTo(0,0) );
}

function delSrchHis(e){
    //배열 요소 삭제
    for(var i = 0; i < srchHis.value.length; i++){ 
        if (srchHis.value[i] === e.target.dataset.value) { 
        srchHis.value.splice(i, 1); 
        break;
        }
    }
    localStorage.setItem("srchHis", JSON.stringify(srchHis));
}

function delAllSrchHis(){
    srchHis.value = [];
    localStorage.setItem("srchHis", JSON.stringify(srchHis));
}

async function goGetCodeLst(code){
    let tmpCode = [];
    await $axios({
        method: 'POST',
        url: '/code/lst', 
        data : code
        }).then((data) => {
        var resultObj =  data.resultObj;
        if( resultObj != null){
            tmpCode = data.resultObj;
        }
    });
    return tmpCode;
}

function clickMoodlst(e){
  e.target.classList.toggle("active");

  const activeTags = Array.from(moodLstParent.value.querySelectorAll('.tag.active'));
  const selMoodLstOnDepth1 = activeTags.map(item => item.dataset.value);

  store.dispatch('layer/setCafeFilterMoodLst', selMoodLstOnDepth1);
}

</script>