export default {
    namespaced: true,
    state: {
        firstName: "freelife",
        lastName: "Doe",
        myCafe: {
            "useYn": null,
            "cafeNum": null,
            "bizNum": null,
            "cafeNm": null,
            "cafeEnglNm": null,
            "repreNm": null,
            "cpSrvcGbnCode": null,
            "cpNum": null,
            "cpNum1": null,
            "cpNum2": null,
            "cpNum3": null,
            "cafeCode": null,
            "email": null,
            "zipCode": null,
            "jibunAddr": null,
            "roadAddr": null,
            "dtlAddr": null,
            "refAddr": null,
            "intr": null,
            "dtl": null,
            "weekStartTime": null,
            "weekEndTime": null,
            "weekDisYn": null,
            "satuStartTime": null,
            "satuEndTime": null,
            "satuDisYn": null,
            "sundStartTime": null,
            "sundEndTime": null,
            "sundDisYn": null,
            "holiStartTime": null,
            "holiEndTime": null,
            "holiDisYn": null,
            "bsnsTimeGuide": null,
            "bsnsTimeGuideDisYn": null,
            "bsnsYn": null,
            "srvc": [],
            "testArray": null,
            "tag": null,
            "imgFileGroupNum": null,
            "thmbnFileGroupNum": null,
            "lat": 0.0,
            "lot": 0.0,
            "shtdYn": null,
            cafeFileModelLst: [],
            imgFileLst: [],
            cafeCtgrList: [],
            delCafeFileModelLst: [],
        },
    },
    mutations: {
        setMyCafe(state, payLoad) {
            state.myCafe = payLoad;

            //초기값 세팅
            if (state.myCafe.delCafeFileModelLst === undefined) state.myCafe.delCafeFileModelLst = [];
            if (state.myCafe.imgFileLst === undefined) state.myCafe.imgFileLst = [];
        },
        setCafeFileModelLst(state, payLoad) {
            state.myCafe.cafeFileModelLst = payLoad;
        },
        pushDelCafeFileModelLst(state, payLoad) {

            state.myCafe.delCafeFileModelLst.push(payLoad);
        },
        setImgFileLst(state, payLoad) {
            state.myCafe.imgFileLst = payLoad;
        },
        pushImgFileLst(state, payLoad) {
            state.myCafe.imgFileLst.push(payLoad);
        },
        setSrvc(state, payLoad) {
            console.log(payLoad.join());
            state.myCafe.srvc = payLoad.join();
        },
        setAddr(state, addr) {
            state.myCafe.zipCode = addr.zipCode;
            state.myCafe.jibunAddr = addr.jibunAddr;
            state.myCafe.roadAddr = addr.roadAddr;
            state.myCafe.dtlAddr = addr.dtlAddr;
        },
    },
    actions: {},
    getters: {},
};
