<template>
    <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-4 col-lg-5">
                    <div class="card">
                        <!-- Logo -->
                        <div class="card-header pt-4 pb-4 text-center bg-primary">
                            <a href="index.html">
                                <span>
                                    <img src="/assets/images/logo.png" alt="" height="18" /></span>
                            </a>
                        </div>

                        <div class="card-body p-4">
                            <div class="text-center w-75 m-auto">
                                <h4 class="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
                                <p class="text-muted mb-4">
                                    Enter your email address and password to access admin panel.
                                </p>
                            </div>

                            <form action="#">
                                <div class="mb-3">
                                    <label for="emailaddress" class="form-label">Email address</label>
                                    <input class="form-control" v-model="id" id="emailaddress" required=""
                                        placeholder="Enter your email" />
                                </div>

                                <div class="mb-3">
                                    <a href="pages-recoverpw.html" class="text-muted float-end"><small>Forgot your
                                            password?</small></a>
                                    <label for="password" class="form-label">Password</label>
                                    <div class="input-group input-group-merge">
                                        <input type="password" v-model="pw" id="password" class="form-control"
                                            placeholder="Enter your password" />
                                        <div class="input-group-text" data-password="false">
                                            <span class="password-eye"></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 mb-3">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="checkbox-signin" checked />
                                        <label class="form-check-label" for="checkbox-signin">Remember me</label>
                                    </div>
                                </div>

                                <div class="mb-3 mb-0 text-center">
                                    <button class="btn btn-primary" v-on:click="goLogin" type="button">
                                        Log In
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <p class="text-muted">
                                Don't have an account?
                                <a href="pages-register.html" class="text-muted ms-1"><b>Sign Up</b></a>
                            </p>
                        </div>
                        <!-- end col -->
                    </div>
                    <!-- end row -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->

        <footer class="footer footer-alt">
            2018 - 2021 © Hyper - Coderthemes.com
        </footer>
    </div>
</template>

<script>
import axiosUtil from "../../../util/axiosUtil";
import cnst from "../../../util/cnst";

export default {
    name: "login",
    data: function () {
        return {
            id: "",
            pw: "",
        };
    },
    methods: {
        successHandler(data) {
            location.href = "/admin";
        },
        errorHandler(data) {
            console.log(data);
        },
        goLogin() {
            const url = "/user/login";
            let data = {
                id: this.id,
                pw: this.pw,
                gbn: cnst.USER.GBN.ADMN,
            };
            axiosUtil.go(url, data, this.successHandler, this.errorHandler);
            return false;
        },
    },
};
</script>
