<template>
    <section class="section-wrap tabsboard">
        <!-- Tab links -->
        <div class="tab">
            <a href="./cafeinfo" class="tablinks active">카페정보</a>
            <a href="./mngrinfo" class="tablinks">사장님정보</a>
            <a href="./cafervw" class="tablinks">후기</a>
        </div>
        <!-- Tab content -->
        <div id="tabCont-01" class="tabcontent" style="display: block">
            <h3 class="blind">카페정보</h3>
            <input type="hidden" id="cafeNum" v-model="cafeInfo.cafeNum" />
            <input type="hidden" id="imgFileGroupNum" v-model="cafeInfo.imgFileGroupNum" />
            <input type="hidden" id="thmbnFileGroupNum" v-model="cafeInfo.thmbnFileGroupNum" />
            <ul class="frm-info-items">
                <li class="column">
                    <span class="label">카페이미지<small>* 최적 사이즈는 1212x20 입니다.</small></span>
                    <div class="scroll-cont">
                        <img-li></img-li>
                        <!-- <img-li :imgLst="cafeInfo.cafeFileModelLst"></img-li> -->
                    </div>
                </li>
                <li>
                    <label class="label" for="cafeNm">카페 국문명</label>
                    <div class="label-grp">
                        <input type="text" id="cafeNm" placeholder="카페 국문명 입력" v-model="cafeInfo.cafeNm" />
                        <!-- <span class="frm-box txt-box">
                            <input type="checkbox" id="frm-chk-01"><label for="frm-chk-01">노출</label>
                        </span> -->
                    </div>
                </li>
                <li>
                    <label class="label" for="cafeEnglNm">카페 영문명</label>
                    <div class="label-grp">
                        <input type="text" id="cafeEnglNm" placeholder="카페 영문명 입력" v-model="cafeInfo.cafeEnglNm" />
                        <!-- <span class="frm-box txt-box">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <input type="checkbox" id="frm-chk-02"><label for="frm-chk-02">노출</label>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </span> -->
                    </div>
                </li>
                <li>
                    <label class="label" for="cafeCode">카페 분류</label>
                    <div class="label-grp">
                        <div class="tag-chk-box">
                            <tag-code-span :codeLst="this.ctgrCodeLst" @tagCodeSpanEmit="ctgrCodeEmit"></tag-code-span>
                        </div>
                    </div>
                </li>
                <li>
                    <span class="label">카페 주소</span>
                    <div class="label-grp">
                        <div class="label-box">
                            <button type="button" class="btn-blk rounded" @click="openPostCode()">
                                우편번호 찾기
                            </button>
                            <input type="text" readonly style="width: 90px" placeholder="우편번호" v-model="cafeInfo.zipCode" />
                            <input type="text" readonly style="width: 304px" placeholder="주소" v-model="cafeInfo.roadAddr" />
                            <input type="text" id="zipCode">
                            <input type="text" id="roadAddr">
                            <input type="hidden" id="jibunAddr">
                        </div>
                        <div class="label-box">
                            <input type="text" placeholder="상세주소" v-model="cafeInfo.dtlAddr" />
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label" for="frm-05">카페 연락처</label>
                    <div class="label-grp">
                        <input type="text" id="cpNum" v-model="cafeInfo.cpNum" placeholder="연락처" @keyup="this.cpNumSplit" />
                        <input type="hidden" id="cpNum1" v-model="cafeInfo.cpNum1" />
                        <input type="hidden" id="cpNum2" v-model="cafeInfo.cpNum2" />
                        <input type="hidden" id="cpNum3" v-model="cafeInfo.cpNum3" />
                    </div>
                </li>
                <li>
                    <span class="label">영업시간</span>
                    <div class="label-grp">
                        <div class="label-box sel-type">
                            <span class="label">평일</span>
                            <time-select startTime="0" endTime="24" name="weekStartTime" :value="cafeInfo.weekStartTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            ~
                            <time-select startTime="0" endTime="24" name="weekEndTime" :value="cafeInfo.weekEndTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            <span class="frm-box txt-box">
                                <input type="checkbox" id="weekDisYn" v-model="cafeInfo.weekDisYn" true-value="Y"
                                    false-value="N" /><label for="weekDisYn">노출</label>
                            </span>
                        </div>
                        <div class="label-box sel-type">
                            <span class="label">토요일</span>
                            <time-select startTime="0" endTime="24" name="satuStartTime" :value="cafeInfo.satuStartTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            ~
                            <time-select startTime="0" endTime="24" name="satuEndTime" :value="cafeInfo.satuEndTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            <span class="frm-box txt-box">
                                <input type="checkbox" id="satuDisYn" v-model="cafeInfo.satuDisYn" true-value="Y"
                                    false-value="N" /><label for="satuDisYn">노출</label>
                            </span>
                        </div>
                        <div class="label-box sel-type">
                            <span class="label">일요일</span>
                            <time-select startTime="0" endTime="24" name="sundStartTime" :value="cafeInfo.sundStartTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            ~
                            <time-select startTime="0" endTime="24" name="sundEndTime" :value="cafeInfo.sundEndTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            <span class="frm-box txt-box">
                                <input type="checkbox" id="sundDisYn" v-model="cafeInfo.sundDisYn" true-value="Y"
                                    false-value="N" /><label for="sundDisYn">노출</label>
                            </span>
                        </div>
                        <div class="label-box sel-type">
                            <span class="label">공휴일</span>
                            <time-select startTime="0" endTime="24" name="holiStartTime" :value="cafeInfo.holiStartTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            ~
                            <time-select startTime="0" endTime="24" name="holiEndTime" :value="cafeInfo.holiEndTime"
                                :changeEvent="this.changeTimeSelect"></time-select>
                            <span class="frm-box txt-box">
                                <input type="checkbox" id="holiDisYn" v-model="cafeInfo.holiDisYn" true-value="Y"
                                    false-value="N" /><label for="holiDisYn">노출</label>
                            </span>
                        </div>
                        <div class="label-box">
                            <span class="label">안내</span>
                            <input type="text" placeholder="안내 입력" title="안내" v-model="cafeInfo.bsnsTimeGuide" />
                            <span class="frm-box txt-box">
                                <input type="checkbox" id="frm-chk-65" v-model="cafeInfo.bsnsTimeGuideDisYn" true-value="Y"
                                    false-value="N" /><label for="frm-chk-65">노츨</label>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label" for="intr">소개글</label>
                    <div class="label-grp">
                        <textarea id="intr" placeholder="소개글" v-model="cafeInfo.intr"></textarea>
                    </div>
                </li>
                <li>
                    <label class="label" for="dtl">상세정보</label>
                    <div class="label-grp">
                        <textarea id="dtl" placeholder="내용" v-model="cafeInfo.dtl"></textarea>
                    </div>
                </li>
                <li>
                    <span class="label">서비스</span>
                    <div class="label-grp">
                        <div class="tag-chk-box">
                            <tag-span upCodeNum="7" codename="srvcLst" @tagSpanEmit="tagSpanEmit"
                                :selArray="cafeInfo.srvc"></tag-span>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label" for="tag">태그</label>
                    <div class="label-grp">
                        <input type="text" id="tag" placeholder="‘,’ 로 구분하여 원하시는 태그를 입력해주세요." title="태그"
                            v-model="cafeInfo.tag" />
                    </div>
                </li>
            </ul>
            <div class="btn-area">
                <button type="button" class="btn-blk" @click="goRegCafe()">저장</button>
            </div>
        </div>

    </section>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

//components
import imgLi from "@/page/mngr/common/imgLi.vue";
import tagSpan from "@/page/mngr/common/tagSpan.vue";
import tagCodeSpan from "@/page/mngr/common/tagCodeSpan.vue";
import timeSelect from "@/page/mngr/common/timeSelect.vue";

//utils
import CodeClass from "@/util/CodeClass.js";

//vuex 설정
const store = useStore();


//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosFile = proxy.$axiosFile;
const $axiosJson = proxy.$axiosJson;
const router = useRouter()

const srvcCodeLst = ref([]);
const ctgrCodeLst = ref([]);   // 카페 카테고리 
const srvcLst = ref([]);
const test = ref('1');
const zip = ref('');
const addr = ref({
    zipCode: ''
    , jiunAddr: ''
    , oradAddr: ''
    , dtlAddr: ''
})

const imgFileLst = computed(() => store.state.cafe.myCafe.imgFileLst);

async function goGetCafe() {
    $axios({
        method: "POST",
        url: "/cafe/get",
    }).then((data) => {
        var resultObj = data.resultObj;
        console.log(resultObj);
        if (resultObj != null) {

            store.commit('cafe/setMyCafe', data.resultObj.cafe)
            //this.setMyCafe(data.resultObj.cafe);
            srvcCodeLst.value = data.resultObj.srvcCodeLst;

            let tmpCodeLst = data.resultObj.ctgrCodeLst;
            let tmpCafeCtgrLst = data.resultObj.cafe.cafeCtgrLst;
            ctgrCodeLst.value = CodeClass.getCodeListByCmprList(tmpCodeLst, tmpCafeCtgrLst, 'cafeCtgrNum');

        };
    })
}

const cafeInfo = computed(() => store.state.cafe.myCafe);


// computed: {
//     ...mapState({
//         cafeInfo: (state) => state.cafe.myCafe,
//     }),
// },


// methods: {
//     ...mapMutations({
//         setMyCafeImage: "cafe/setMyCafeImage",
//         setMyCafe: "cafe/setMyCafe",
//         setSrvc: "cafe/setSrvc",
//         setAddr: "cafe/setAddr",
//     }),


async function goRegCafe() {

    let tmpCtgrCodeLst = CodeClass.getCodeListChkYes(ctgrCodeLst.value, 'cafeCtgrNum');
    cafeInfo.value.cafeCtgrLst = tmpCtgrCodeLst;

    // 주소 넣기. 
    addr.value.zipCode = document.getElementById('zipCode').value;
    addr.value.roadAddr = document.getElementById('roadAddr').value;
    addr.value.jibunAddr = document.getElementById('jibunAddr').value;
    if (addr.value.zipCode != '') {
        //this.setAddr(this.addr);
        store.commit('cafe/setAddr', addr.value)
    }

    store.commit('cafe/setSrvc', srvcLst.value)
    //this.setSrvc(this.srvcLst);

    // for (var key in cafeInfo.value) {
    //     if (cafeInfo.value[key] != null) formData.append(key, cafeInfo.value[key]);
    // }

    //카페 정보 등록 후 등록한 정보를 return받는다.
    const fileLst = await goRegCafeFile();


    //fileLst를 cafeInfo에 넣어서 전달해야 함.
    //imgFileLst와 병합하는 방식으로 처리하는 게 가장 이상적으로 보임.
    //그렇다면 어떤 값으로 매칭을 시켜줄 것인가?
    //순서가 항상 같다는 것을 증명할 수 있는가?

    var i = 0
    for (var item of fileLst) {
        if (item.realNm == imgFileLst.value[i].name) item.sortNum = imgFileLst.value[i].sortNum;
        i++;
    }

    store.commit('cafe/setImgFileLst', fileLst);

    cafeInfo.value.cafeFileModelLstJson = JSON.stringify(cafeInfo.value.cafeFileModelLst);
    cafeInfo.value.imgFileLstJson = JSON.stringify(cafeInfo.value.imgFileLst);
    cafeInfo.value.delCafeFileModelLstJson = JSON.stringify(cafeInfo.value.delCafeFileModelLst);

    await $axios({
        method: "POST",
        url: '/cafe/reg',
        data: cafeInfo.value,
    }).then((data) => {
        var resultObj = data.resultObj;
        if (resultObj != null) {
            //store.commit('cafe/setMyCafe', data.resultObj.cafe)
            //this.setMyCafe(data.resultObj);
            goGetCafe();
        }
    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });

}

async function goRegCafeFile() {

    const formData = new FormData();

    for (const file of imgFileLst.value) {
        formData.append("cafeImgFile_" + file.sortNum, file);
    }

    let fileLst = [];

    await $axios({
        method: "POST",
        url: '/cafeFile/reg',
        data: formData,
    }).then((data) => {
        var resultObj = data.resultObj;
        if (resultObj != null) {
            fileLst = resultObj;
        }
    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });

    return fileLst;

}



onMounted(async () => {
    await goGetCafe();
});


function delNewImg(e) {
    const delLi = e.target.closest("li");
    delLi.remove();
}

//커스텀 컴포넌트 사용 시 v-model이 정상적으로 작동하지 않아서 이벤트를 부모창에서 처리하여 값을 직접 넣을 수 있도록 추가하였음.
function changeTimeSelect(e) {
    const selName = e.target.name;
    cafeInfo[e.target.name] = e.target.value;
}
function cpNumSplit(e) {
    cafeInfo.cpNum = e.target.value
        .replace(/[^0-9]/, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    const cpNums = cafeInfo.cpNum.split("-");
    if (cpNums.length > 0) {
        cafeInfo.cpNum1 = cpNums[0];
        cafeInfo.cpNum2 = cpNums[1];
        cafeInfo.cpNum3 = cpNums[2];
    }
}
function tagSpanEmit(obj) {
    if (obj.upCodeNum == '7') {
        if (obj.activeYn == 'Y') {
            srvcLst.value.push(obj.code);
        } else {
            let idx = -1;
            if (srvcLst.value != null && srvcLst.value.length > 0) {
                for (var i = 0; i < srvcLst.value.length; i++) {
                    if (srvcLst.value[i] == obj.code) {
                        idx = i;
                        break;
                    }
                }
            }
            srvcLst.value.splice(idx, 1);
        }
    }
}

function openPostCode() {
    // 다음주소 
    var daumPostCodeObj = new daum.Postcode({
        oncomplete: function (data) {
            console.log(data);
            addr.value.zipCode = data.zonecode;
            addr.value.roadAddr = data.roadAddress;
            addr.value.jibunAddr = data.jibunAddress;
            if (addr.value.zipCode != '') {
                //this.setAddr(this.addr);
                store.commit('cafe/setAddr', addr.value)
            }

        }
    }).open();
}

function ctgrCodeEmit(tmpCode) {
    console.log(tmpCode);
    ctgrCodeLst.value = CodeClass.getCodeListByCmprCode(ctgrCodeLst.value, tmpCode, 'cafeCtgrNum');
    console.log(ctgrCodeLst);
}

</script>