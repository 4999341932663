import { createRouter, createWebHistory } from "vue-router";

import error404 from "../views/Error404.vue";

import structure from "../views/structure.vue";
import code from "../views/code.vue";
import main from "../views/test3.vue";
import login from "../views/login.vue";
import join from "../views/join.vue";
import board from "../views/board/board/lst.vue";
import boardWrite from "../views/board/board/boardWrite.vue";

import arti from "../views/board/arti/lst.vue";
import artiWrite from "../views/board/arti/write.vue";

import bnr from "../views/bnr/bnr.vue";
import bnrWrite from "../views/bnr/bnrWrite.vue";

import menu from "../views/menu/menu.vue";
import role from "../views/role/role.vue";

import cntn from "@/page/admin/views/cntn/cntn.vue";
import cntnWrite from "@/page/admin/views/cntn/cntnWrite.vue";

import admn from "@/page/admin/views/user/admn/lst.vue";
import cust from "@/page/admin/views/user/cust/lst.vue";
import mngr from "@/page/admin/views/user/mngr/lst.vue";

import axiosHelper from "@/util/axiosHelper";
import store from "@/store/store.js";

const routes = [
  {
    path: "/admin",
    name: "structure",
    component: structure,
    children: [
      {
        path: "main",
        component: main,
        name: "main",
      },
      {
        path: "bnr",
        component: bnr,
        name: "bnr",
        children: [],
      },
      {
        path: "bnr/bnrWrite",
        component: bnrWrite,
        name: "bnrWrite",
      },
      {
        path: "join",
        component: join,
        name: "join",
      },
      {
        path: "code",
        component: code,
        name: "join",
      },
      {
        path: "board/lst",
        component: board,
        name: "boardLst",
      },
      {
        path: "board/boardWrite",
        component: boardWrite,
        name: "boardWrite",
      },
      {
        path: "arti/lst",
        component: arti,
        name: "artiLst",
      },
      {
        path: "arti/write",
        component: artiWrite,
        name: "artiWrite",
      },
      {
        path: "menu",
        component: menu,
        name: "menu",
      },
      {
        path: "role",
        component: role,
        name: "role",
      },
      {
        path: "cntn",
        component: cntn,
        name: "cntn",
      },
      {
        path: "cntn/write",
        component: cntnWrite,
        name: "cntnWrite",
        props: true,
      },
      {
        path: "user/admn/lst",
        component: admn,
        name: "userAdmn",
        props: true,
      },
      {
        path: "user/cust/lst",
        component: cust,
        name: "userCust",
        props: true,
      },
      {
        path: "user/mngr/lst",
        component: mngr,
        name: "userMngr",
        props: true,
      },
    ],
  },
  {
    path: "/admin/login",
    name: "login",
    component: login,
  },
  {
    path: "/admin/:afterUser(.*)",
    name: "error404",
    component: error404,
  },
];


const adminRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //base: process.env.BASE_URL,
  routes,
});


adminRouter.beforeEach(async (to, from, next) => {
  let result = false;
  const test = await axiosHelper({
    method: 'POST',
    url: '/auth/chkViewUrlAuth',
    data: { 'url': to.path },
  }).then((data) => {

    if (data.resultYn == 'Y') {
      next();
    } else {
      next('/admin/login');
    }
  })
})



export default adminRouter;
