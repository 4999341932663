import axios from "axios";
import qs from "qs";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  Accept: "*/*",
  "Access-Control-Allow-Origin": "*",
};

console.log(process.env.VUE_APP_SERVER_URL);

const client = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: headers,
  transformRequest: [(data, headers) => {
    if (!(data instanceof FormData)) {
      var queryString = qs.stringify(data, { arrayFormat: 'repeat' });
      data = queryString;
    }
    return data;
  }, ...axios.defaults.transformRequest]
});

// request interceptor 설정
client.interceptors.request.use(
  function (config) {
    // 1. 요청 보내기 전에 실행
    // store의 토큰 값 설정
    let token = localStorage.getItem("authorization");
    if (token != null && token != "") {
      config.headers.Authorization = localStorage.getItem("authorization");
    }
    return config;
  },
  function (error) {
    console.error("interceptors request Failed:", error.config);
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    console.debug("Request Request Successful!", response);

    // 전체 모달창 닫기
    try {
      hideAllModel();
    } catch (e) {}

    return response.data;
  };

  const onError = function (error) {
    console.log("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);

      popErrMsg("에러", "오류가 발생 했습니다.<br>관리자에게 문의 하세요. ");
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);

      //popErrMsg("에러", "오류가 발생 했습니다.<br>관리자에게 문의 하세요. ");
    }

    return Promise.reject(error.response || error.message);
  };
  
  return client(options).then(onSuccess).catch(onError);
};

export default request;
