<template>
    <div class="wrap">
        <header class="header-type--List">
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">마이페이지</em>
        </header>
        <div class="my-account-wrap">
            <!-- 로그인후 -->
            <div v-if="user.id != null && user.id != undefined" class="my-account-area after">
                <span class="pic-box"><img src="" alt="" ref="custImg" ></span>
                <div class="txt-box"><strong>{{ user.nickNm }}</strong>님 안녕하세요!</div>
                <div class="desc-box">{{ user.id }}</div>
            </div>
            <!-- 로그인전 -->
            <div v-else class="my-account-area before">
                <span class="pic-box"></span>
                <div class="txt-box"><strong>로그인</strong>이 필요합니다.</div>
                <div class="btn-box">
                    <a @click="goRouterPush('tncChk', 'name')" role="button" class="btn-line">회원가입</a>
                    <a @click="goRouterPush ('login', 'name')" role="button" class="btn-line">로그인</a>
                </div>
            </div>
        </div>
        <div class="list-section top-bg">
            <ul class="list-box line-box">
                <li v-for="(menu, index) in menuLst" :key="index">
                    <a role="button" class="btn-link" @click="goRouterPush(menu.url, 'path')" >{{ menu.menuNm }}</a>
                </li>
                <li>
                    <a role="button" class="btn-link">설정</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosFile = proxy.$axiosFile;
const router = useRouter()

//유저 정보 처리 시작
const user = ref({});

async function goGetUser(){
    await $axios({
        method: 'POST',
        url: '/cust/getCurrent',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                user.value.id = resultObj.id;
                user.value.nickNm = resultObj.nickNm;
                user.value.custFileModel = resultObj.custFileModel;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

onMounted( async () => {
    await goGetUser();
    await getProfileImg();
})
//유저 정보 처리 끝


//메뉴리스트 처리 시작
let menuLst = ref([]);
let tmpMenuLst = ref({});

async function getMenuLst(){
    let menuLst = new Object();
    const test = await $axios({
        method: 'POST',
        url: '/mycafemenu/treeLst',
        }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null  ){
                menuLst = data.resultObj;
            }
        }).catch((error =>{
            console.log(error)
        }))
    return menuLst;
}
onMounted( async () => {
    tmpMenuLst = await getMenuLst();
    menuLst.value = tmpMenuLst.filter(menu => menu.upMenuNum == 25);
})

//메뉴리스트 처리 끝

function goRouterPush(data, type, query) {
    if(type == 'path'){
        router.push({
            path: data,
        }).then((() =>window.scrollTo(0,0) ));
    }else if(type == 'name'){
        router.push({
            name: data,
        }).then((() =>window.scrollTo(0,0) ));
    }
}


//프로필 이미지 처리
const custImg = ref(null);

async function getProfileImg(){
    await $axiosFile({
        method: 'GET',
        url: '/custFile/img/profile/100x100/' + user.value.custFileModel.custFileNum,
    }).then((response) => {
        let imgUrl = URL.createObjectURL(response.data)
                
        custImg.value.src = imgUrl        

    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });
}

</script>