<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">찜한 내역</em>
        </header>
        <!-- list -->
        <div class="section">
            <cafe-four-greed :cafeLst="cafeLst" />
        </div>
        <!-- //list -->
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

//components
import cafeFourGreed from "@/page/main/common/cafeFourGreed.vue";
import cafeEnum from "@/util/cafe.js"

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()


const pageObj = ref({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 1 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 10 //한 페이지 내에 보여줄 리스트 개수
                    })

const cafe = ref({});
const cafeLst = ref([]);

cafe.value.cafeSrchCode = cafeEnum.SRCH.SRCH_CODE.FAV;

async function goGetLst(cafe){
  let tmpCafeLst = [];
  await $axios({
      method: 'POST',
      data: cafe,
      url: '/cafe/lst',
      }).then((data) => {
        var resultObj = data.resultObj;
        if(resultObj != null){
          tmpCafeLst = resultObj;
          pageObj.value.totalCnt = data.totalCnt;
          pageObj.value.totalPage = Math.ceil(pageObj.value.totalCnt / pageObj.value.pageBlockNum);
          cafe.pageNum++;
        }
      }).catch((error) => {
        alert('에러 발생')
      })

    return tmpCafeLst;
}

onMounted( async () => {

  cafe.value.pageNum = pageObj.value.pageNum; //현재 페이지 번호
  cafe.value.pageSize = pageObj.value.pageSize; //최대 페이지 개수
  cafe.value.pageBlockNum = pageObj.value.pageBlockNum; //페이지 내에 보여줄 리스트 개수
  cafeLst.value = await goGetLst(cafe.value);  
})

import { debounce, throttle } from 'lodash'
//하단 스크롤 시 발생 이벤트 관련 변수 선언
const currentScroll = ref(0);
const waitingScroll = ref(false);


const scrollHandler = throttle(scrollCheck, 500);

onMounted(() => {
    document.addEventListener('scroll',scrollHandler);
})

onBeforeMount(() => {
    document.removeEventListener('scroll', scrollHandler);
})

function scrollCheck(e){
    currentScroll.value = e.target.scrollingElement.scrollTop + e.target.scrollingElement.clientHeight;

    if(currentScroll.value > e.target.scrollingElement.scrollHeight- 200 && pageObj.value.totalPage > cafe.value.pageNum && !waitingScroll.value){
        nextGoGetArtiLst(e);
    }
}

async function nextGoGetArtiLst() {
    waitingScroll.value = true;
    const addCafeLst = await goGetLst(cafe.value);
    let i;
    for(i = 0; i < addCafeLst.length; i++){
        cafeLst.value.push(addCafeLst[i]);
    }
    waitingScroll.value = false;
}
</script>