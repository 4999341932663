
class Code {

    /** 
     * codeList와 cmprList 비교하여 같은 값이 있는 경우 chkYn를 Y로 변경해 준다. 
    */
    static getCodeListByCmprList(codeList, cmprList, cmprKey) {
        // console.log('getCodeListByCmprCodeList');
        // console.log(codeList);
        // console.log(cmprList);
        if (codeList != null && codeList.length > 0) {
            for (let i = 0; i < codeList.length; i++) {
                let code = codeList[i];
                code.chkYn = 'N';
                if (cmprList != null && cmprList.length > 0) {
                    for (let m = 0; m < cmprList.length; m++) {
                        let cmprObj = cmprList[m];
                        let cmprNum = cmprObj[cmprKey];
                        if (cmprNum == code.codeNum) {
                            code.chkYn = 'Y';
                            break;
                        }
                    }
                }
                codeList[i] = code;
            }
        }
        return codeList;
    }

    /** 
     * codeList와 cmprCodeList를 비교하여 같은 값이 있는 경우 chkYn를 Y로 변경해 준다. 
     * 두 파라미터 모두 code 형식의 객체 여야 한다. 
    */
    static getCodeListByCmprCode(codeList, cmprCode) {
        if (codeList != null && codeList.length > 0) {
            for (let i = 0; i < codeList.length; i++) {
                let code = codeList[i];
                console.log(code.codeNum + ',' + cmprCode);
                if (code.codeNum == cmprCode.codeNum) {
                    code.chkYn = cmprCode.chkYn
                    break;
                }
                codeList[i] = code;
            }
        }
        return codeList;
    }


    /** 
     * codeList와 Array를 비교하여 같은 값이 있는 경우 chkYn를 Y로 변경해 준다. 
     * 
    */
     static getCodeListByArray(codeList, arr) {
        if (codeList != null && codeList.length > 0) {
            for (let i = 0; i < codeList.length; i++) {
                let code = codeList[i];
                code.chkYn = 'N';
                if (arr != null && arr.length > 0) {
                    for (let m = 0; m < arr.length; m++) {
                        let cmprNum = arr[m];
                        if (cmprNum == code.codeNum) {
                            code.chkYn = 'Y';
                            break;
                        }
                    }
                }
                codeList[i] = code;
            }
        }
        return codeList;
    }

    /** 
     * 활성화된 Code 리스트만 반환한다. 
    */
    static getCodeListChkYes(codeList, cmprKey) {
        let newCodeList = [];
        if (codeList != null && codeList.length > 0) {
            for (let i = 0; i < codeList.length; i++) {
                let code = codeList[i];
                code[cmprKey] = code.codeNum;
                if (code.chkYn == 'Y') {
                    newCodeList.push(code);
                }
            }
        }
        return newCodeList;
    }


}
export default Code;
