<template>
    <!-- Start Content-->
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{title}} </h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrNm" class="form-label">제목</label>
                                    <input type="text" id="bnrNm" class="form-control" v-model="cntn.ttl" placeholder="제목을 입력해주세요.">
                                    <input type="hidden" id="fileGroupNum" class="form-control" v-model="cntn.fileGroupNum" />
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">사용여부</label>
                                    <select class="form-control" v-model="cntn.useYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                            </div> <!-- end col-->

                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrCode" class="form-label">컨텐츠 코드</label>
                                    <select class="form-control" v-model="cntn.cntnCode" required="">
                                        <sel-option upCodeNum="5003" :selCodeNum="cntn.cntnCode" codename="cntnCodeNm"></sel-option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="disYn" class="form-label">노출 여부</label>
                                    <select class="form-control" v-model="cntn.disYn" required="" placeholder="노출여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">노출 일시</label>
                                    <div class="row g-2">
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disStartDt" class="form-control" v-model="cntn.disStartDt" placeholder="">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disEndDt" class="form-control" v-model="cntn.disEndDt" placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- end col-->


                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">내용</label>
                                    <div class="row g-2">
                                        <textarea class="form-control" id="cntn" rows="5" v-model="cntn.cntn"></textarea>
                                    </div>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="text-sm-end">
                                    <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" v-show="delBtnShow" @click="goDelCntn()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    <button type="button" id="cancelBtn" class="btn btn btn-success mb-2 btn-sm" @click="goCntn()"><i class="mdi dripicons-wrong me-2"></i> 취소 </button>
                                    <button type="button" id="modifyBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="modBtnShow" @click="goRegCntn()"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                    <button type="button" id="addBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="addBtnShow" @click="goRegCntn()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                </div>
                            </div>
                        </div>

                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>
    </div> <!-- container -->
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const route = useRoute()
const router = useRouter()

//components
import selOption from '../../common/selOption.vue';


const cntn = ref({});

const delBtnShow = ref(false);
const modBtnShow = ref(false);
const addBtnShow = ref(true);

const selCntnNum = ref();

const title = ref("컨텐츠 추가");

cntn.value.useYn = 'Y';
cntn.value.disYn = 'N';

async function goGetCntn(){
    await $axios({
        method: 'POST',
        data: cntn.value,
        url: '/cntn/get',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                cntn.value = resultObj;
                updSet();
            }
    }).catch((error) => {
        console.log(error)
        alert("에러 발생");
    })
}
function updSet(){
    delBtnShow.value = true;
    modBtnShow.value = true;
    addBtnShow.value = false;
}

async function goRegCntn(){
    
    await $axios({
        method: 'POST',
        data: cntn.value,
        url: '/cntn/reg',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                cntn.value = data.resultObj;
                alert("등록이 완료되었습니다. ");
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

onMounted( () => {
    cntn.value.cntnNum = route.query.cntnNum;
    goGetCntn();
})

function goCntn(cntnNum) {
    router.push({
        name: "cntn",
        params: { cntnNum: cntnNum},
    });
}

function timestampToDate(dateTime){
        if(dateTime == '') return '';
    // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
    var js_date = new Date(dateTime);

    // 연도, 월, 일 추출
    var year = js_date.getFullYear();
    var month = js_date.getMonth() + 1;
    var day = js_date.getDate();
    var hh = js_date.getHours();
    var mm = js_date.getMinutes();
    var ss = js_date.getSeconds();

    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
    if(month < 10){
        month = '0' + month;
    }

    if(day < 10){
        day = '0' + day;
    }

    if(hh < 10){
        hh = '0' + hh;
    }

    if(mm < 10){
        mm = '0' + mm;
    }

    if(ss < 10){
        ss = '0' + ss;
    }

    // 최종 포맷 (ex - '2021-10-08 05:17:20')
    return year + '-' + month + '-' + day + " " + hh + ":" + mm + ":" + ss;
}
</script>