<template>
  <li><a @click="this.goCafeLstAll(event, '')" class="theme-go theme-01"><i>전체</i></a></li><!-- 아이콘구분(.theme-01~08) -->
  <li v-for="(item, index) in codeLst" :key="index">
    <a @click="this.goCafeLst(event, item.codeNum)" class="theme-go" :class="item.ref1"><i>{{ item.codeNm }}</i></a>
  </li>
</template>

<script>

import axiosUtil from '@/util/request';
import cafe from "@/util/cafe.js"

export default {
  name : 'custQuickCafeLst'
  ,components:{
  }
  ,data(){
    return {
      codeLst : []
      ,code : {}
    };
  }
  ,created(){
    this.init();
  },methods:{
    init:async function(){
        this.code = new Object();
        this.codeLst =  await this.goGetCodeLst();
    },
    goGetCodeLst: async function(){
      const code = {"upCodeNum": 1001};
      let tmpCode = [];
      const test = await axiosUtil({
          method: 'POST',
          url: '/code/lst', 
          data : code
        }).then((data) => {
          var resultObj =  data.resultObj;
          if( resultObj != null){
              tmpCode = data.resultObj;
              }
        });
        return tmpCode;
    },
    goCafeLst : function(event, codeNum){
        this.$router.push({
            name: 'cafeLst',
            query : { paramSrchCafe:cafe.SRCH.SRCH_CODE.THEME,
                      paramQuickCafe:codeNum}
        }).then((() =>window.scrollTo(0,0) ));

    },
    goCafeLstAll : function(event, codeNum){
        this.$router.push({
            name: 'cafeLst',
            query : { paramSrchCafe: cafe.SRCH.SRCH_CODE.ALL,
                      paramQuickCafe:codeNum}
        }).then((() =>window.scrollTo(0,0) ));

    }
  }
}

</script>