<template>
<div id="tabCont-01" class="tabcontent" style="display: block">
    <h3 class="blind">기본정보 </h3>
    <dl class="tab-txt prev-type">
        <dt class="tit">상호명</dt>
        <dd>{{ cafe.cafeNm }}</dd>
        <dt class="tit">주소</dt>
        <dd>{{ cafe.roadAddr }} {{ cafe.dtlAddr }}</dd>
        <dt class="tit">전화번호</dt>
        <dd>{{ cafe.cpNum }}</dd>
        <dt class="tit">영업시간</dt>
        <dd>
            <p v-if="cafe.weekDisYn = 'Y'">주중 {{cafe.weekStartTime }} ~ {{cafe.weekEndTime }}</p>
            <p v-if="cafe.satuDisYn = 'Y'">토요일 {{cafe.satuStartTime }} ~ {{cafe.satuEndTime }}</p>
            <p v-if="cafe.sundDisYn = 'Y'">일요일 {{cafe.sundStartTime }} ~ {{cafe.sundEndTime }}</p>
            <p v-if="cafe.holiDisYn = 'Y'">공휴일 {{cafe.holiStartTime }} ~ {{cafe.holiEndTime }}</p>
        </dd>
    </dl>
    <dl class="tab-txt">
        <dt class="tit">소개글</dt>
        <dd>{{ cafe.intr }}</dd>
    </dl>
    <dl class="tab-txt">
        <dt class="tit">상세정보</dt>
        <dd>{{ cafe.dtl }}</dd>
    </dl>
    <div class="tab-txt group-type">
        <strong class="tit">서비스</strong>
        <div class="svc-box">
            <span v-for="(srvc, index) in srvcLst" :key="index" class="svc" :class="[srvc.ref1, srvc.chkYn == 'N' ? 'off' : '']" >{{ srvc.codeNm }} {{ srvc.chkYn == 'Y' ? '가능' : '불가능' }}</span>
        </div>
        <div class="tag-box">
            <span v-for="(item, index) in tags" :key="index" class="tag">#{{ item }}</span>
        </div>
        <ul class="blet-info">
            <li>사장님이신가요? <strong class="underline-txt">운영자 전용 태블릿 앱</strong>을 다운로드해 편리하게 
                관리해 보세요.</li>
            <li>해당 카페가 더 이상 운영하지 않나요? <a href="#" class="underline-txt">여기</a>를 클릭해 주세요.</li>
        </ul>
    </div>
    <button type="button" class="btn-full">카페문의</button>
</div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, watch, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import CodeClass from "@/util/CodeClass.js";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();


const cafe = ref({});
const tags = ref([]);
const srvcLst = ref([]);

const baseUrl = process.env.VUE_APP_SERVER_URL;

onMounted(async () => {
    cafe.value.cafeNum = route.params.cafeNum;
    [cafe.value, srvcLst.value] = await goGetCafe(cafe.value);
    tags.value = cafe.value.tag.split(',');

    srvcLst.value = CodeClass.getCodeListByArray(srvcLst.value, cafe.value.srvc.split(','));
    console.log(srvcLst.value);
})

async function goGetCafe(cafe){
    let tmpCafe, tmpSrvc = null
    await $axios({
        method: 'POST',
        url: '/cafe/get', 
        data : cafe
        }).then((data) => {
        tmpCafe = data.resultObj.cafe;
        tmpSrvc = data.resultObj.srvcCodeLst;
    })
    return [tmpCafe, tmpSrvc];
}
</script>