<template>
    <div class="swiper row-swiper wish-swiper">
        <swiper
            :slides-per-view="3.1"
            :space-between="10" 
            class="default-slider">
            <swiper-slide v-for="n in cafeLst" :key="n"> 
                <a class="item list-box" @click="this.goCafeDetail(event, n.cafeNum)">
                    <div class="list-thumb"><img :src="`${baseUrl}/file/getImg/${n.thmbnFileLst[0].fileNum}`"></div>
                    <div class="list-info">
                        <strong class="tit">{{ n.cafeNm }}</strong>
                        <span class="desc">{{ n.intr}}</span>
                    </div>
                </a>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";

// Import Swiper styles
//import "swiper/swiper.min.css";

export default {
    name : 'cafeSwiper',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
         baseUrl : process.env.VUE_APP_SERVER_URL
        }
    },
    props:['cafeLst'],
    created(){
        this.init();
    },
    methods :{
        init : async function(){

        },
        goGetCafeList : async function(cafe){
            let tmpCafeLst = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/cafe/lst', 
                data : cafe
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpCafeLst = data.resultObj;
                }
            })
            return tmpCafeLst;
        }, 
        goCafeDetail(event, cafeNum) {
            this.$router.push({
                name: 'cafeInfo',
                params : { cafeNum:cafeNum}
            }).then((() =>window.scrollTo(0,0) ));
        },
    }
}

</script>