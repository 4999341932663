<template>
    <!-- Start Content-->
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{title}} </h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrNm" class="form-label">제목</label>
                                    <input type="text" id="artiNm" class="form-control" v-model="arti.ttl" placeholder="제목을 입력해주세요.">
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">게시판명</label>
                                    <select class="form-control" v-model="arti.boardNum" required="" placeholder="사용여부를 선택해주세요">
                                        <template v-for="(board, index) in boardLst" :key="index">
                                            <option :value="board.boardNum" >{{ board.boardNm }}</option>
                                        </template>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">사용여부</label>
                                    <select class="form-control" v-model="arti.useYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">알림여부</label>
                                    <select class="form-control" v-model="arti.alrmYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">댓글사용여부</label>
                                    <select class="form-control" v-model="arti.cmntUseYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">비밀글여부</label>
                                    <select class="form-control" v-model="arti.undcovYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                            </div> <!-- end col-->

                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="bnrCode" class="form-label">카테고리 코드</label>
                                    <select class="form-control" v-model="arti.ctgrCode" required="">
                                        <sel-option upCodeNum="5003" :selCodeNum="arti.ctgrCode" codename="ctgrCodeNm"></sel-option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="useYn" class="form-label">상단고정여부</label>
                                    <select class="form-control" v-model="arti.topFixYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">알림 일시</label>
                                    <div class="row g-2">
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="alrmStartDt" class="form-control" v-model="arti.alrmStartDt" placeholder="">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="alrmEndDt" class="form-control" v-model="arti.alrmEndDt" placeholder="">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">이벤트 일시</label>
                                    <div class="row g-2">
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="evntStartDt" class="form-control" v-model="arti.evntStartDt" placeholder="">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="evntEndDt" class="form-control" v-model="arti.evntEndDt" placeholder="">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="disYn" class="form-label">노출 여부</label>
                                    <select class="form-control" v-model="arti.disYn" required="" placeholder="노출여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">노출 일시</label>
                                    <div class="row g-2">
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disStartDt" class="form-control" v-model="arti.disStartDt" placeholder="">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="datetime-local" id="disEndDt" class="form-control" v-model="arti.disEndDt" placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- end col-->
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">내용</label>
                                    <div class="row">
                                        <div class="col-xl-3 mb-2" style="border: 1px solid var(--bs-gray-400); border-radius : 0.25rem">
                                            <img v-if="file.fileNum != undefined || file.fileNum != null" :src="`${baseUrl}/file/getImg/${file.fileNum}`" ref="previewImg" id="previewImg" alt="" class="image-box" style="width:100%">
                                            <img v-else id="previewImg" ref="previewImg" alt="이미지 미리보기" class="image-box" style="width:100%">
                                        </div>
                                        <div class="col-xl-9 mb-2">
                                            <textarea class="form-control" id="arti" rows="5" v-model="arti.cntn" readonly ></textarea>
                                        </div>
                                            <QuillEditor v-model:content="arti.cntn" contentType="html" theme="snow" />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrUrl" class="form-label">썸네일 이미지</label>
                                    <div v-if="file.fileNum == undefined || file.fileNum == null">
                                        <input type="file" ref="fileDom" id="fileDom" class="form-control" @change="imgChange" >
                                    </div>
                                    <div v-else>
                                        {{file.fileNm}}
                                        <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" @click="goDelBnrImg()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="bnrNm" class="form-label">태그</label>
                                    <input type="text" id="artiNm" class="form-control" v-model="arti.tag" placeholder="제목을 입력해주세요.">
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="text-sm-end">
                                    <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" v-show="delBtnShow" @click="goDel()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    <button type="button" id="cancelBtn" class="btn btn btn-success mb-2 btn-sm" @click="goLst()"><i class="mdi dripicons-wrong me-2"></i> 취소 </button>
                                    <button type="button" id="modifyBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="modBtnShow" @click="goReg()"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                    <button type="button" id="addBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="addBtnShow" @click="goReg()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                </div>
                            </div>
                        </div>
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>
    </div> <!-- container -->
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

//quill 에디터
import { QuillEditor } from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore();

const route = useRoute()
const router = useRouter()

//components
import selOption from '@/page/admin/common/selOption.vue';
import StringUtil from '@/util/StringUtil.js'


const arti = ref({});
const boardLst = ref([]);

arti.value.boardNum = store.state.board.board.boardNum;
arti.value.cafeNum = 0;

const delBtnShow = ref(false);
const modBtnShow = ref(false);
const addBtnShow = ref(true);

const selArtiNum = ref();

const title = ref("게시물 추가");

arti.value.useYn = 'Y';
arti.value.disYn = 'N';
arti.value.topFixYn = 'N';
arti.value.alrmYn = 'N';
arti.value.cmntUseYn = 'N';
arti.value.undcovYn = 'N';

const previewImg = ref(null);
const fileDom = ref(null);
const file = ref({});

async function goGet(){
    await $axios({
        method: 'POST',
        data: arti.value,
        url: '/arti/get',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                arti.value = resultObj;
                updSet();
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

async function goGetBoardLst(){
    const test = await $axios({
        method: 'POST',
        url: '/board/lst', 
    }).then((data) => {
            if( data.resultObj != null  ){
                boardLst.value = data.resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

function updSet(){
    delBtnShow.value = true;
    modBtnShow.value = true;
    addBtnShow.value = false;
}

async function goReg(){
    const formData = new FormData();
    const bnrFileDom = document.querySelectorAll('#fileDom');

    for(const files of fileDom.value.files){
        formData.append('thmnFile', files);
    }

    arti.value.disStartDt = Math.floor(new Date(arti.value.disStartDt).getTime());
    arti.value.disEndDt = Math.floor(new Date(arti.value.disEndDt).getTime());
    arti.value.alrmStartDt = Math.floor(new Date(arti.value.alrmStartDt).getTime());
    arti.value.alrmEndDt = Math.floor(new Date(arti.value.alrmEndDt).getTime());
    arti.value.evntStartDt = Math.floor(new Date(arti.value.evntStartDt).getTime());
    arti.value.evntEndDt = Math.floor(new Date(arti.value.evntEndDt).getTime());

    for(var key in arti.value) {
        if(arti.value[key] != null)
        formData.append(key, arti.value[key]);
    }

    await $axios({
        method: 'POST',
        data: formData,
        url: '/arti/reg',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                arti.value = data.resultObj;
                alert("등록이 완료되었습니다. ");
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

onMounted(async () => {
    arti.value.artiNum = route.query.artiNum;
    arti.value.boardNum = store.state.board.board.boardNum;
    await goGetBoardLst();
    await goGet();

    if(arti.value.imgFile != null)
                    file.value = arti.value.imgFile;
    

    arti.value.evntStartDt = StringUtil.Unix_timestamp(arti.value.evntStartDt);
    arti.value.evntEndDt = StringUtil.Unix_timestamp(arti.value.evntEndDt);
    arti.value.alrmStartDt = StringUtil.Unix_timestamp(arti.value.alrmStartDt);
    arti.value.alrmEndDt = StringUtil.Unix_timestamp(arti.value.alrmEndDt);
})

function imgChange(e){
    const fileDom = e.target;

    previewImg.value.src = URL.createObjectURL(fileDom.files[0]);
}

function goLst(artiNum) {
    router.push({
        name: "artiLst",
        params: { artiNum: artiNum},
    });
}

function timestampToDate(dateTime){
        if(dateTime == '') return '';
    // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
    var js_date = new Date(dateTime);

    // 연도, 월, 일 추출
    var year = js_date.getFullYear();
    var month = js_date.getMonth() + 1;
    var day = js_date.getDate();
    var hh = js_date.getHours();
    var mm = js_date.getMinutes();
    var ss = js_date.getSeconds();

    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
    if(month < 10){
        month = '0' + month;
    }

    if(day < 10){
        day = '0' + day;
    }

    if(hh < 10){
        hh = '0' + hh;
    }

    if(mm < 10){
        mm = '0' + mm;
    }

    if(ss < 10){
        ss = '0' + ss;
    }

    // 최종 포맷 (ex - '2021-10-08 05:17:20')
    return year + '-' + month + '-' + day + " " + hh + ":" + mm + ":" + ss;
}
</script>