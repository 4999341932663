<template>
    <div>
        <span v-for="(item, index) in codeLst" :class="{ active: item.chkYn == 'Y' }" :key="index" class="tag"
            :data-value="item.codeNum" :up-code-num="item.upCodeNum" :name="codename" @click="activeToggle">{{ item.codeNm
            }}</span>
    </div>
</template>

<script>
import axiosUtil from '@/util/request';

export default {
    name: 'tagSpan',
    data: function () {
        return {
            codeLst: [],
        }
    },
    created() {
        this.init();


    },
    props: ['upCodeNum', 'codename', 'selArray', 'value'],
    methods: {
        init: async function () {
            this.codeLst = await this.goGetCode();
        },
        goGetCode: async function () {
            const code = { "upCodeNum": this.upCodeNum };
            let tmpCode = [];
            const test = await axiosUtil({
                method: 'POST',
                url: '/code/lst',
                data: code
            }).then((data) => {
                var resultObj = data.resultObj;
                if (resultObj != null) {
                    tmpCode = data.resultObj;
                }
            });
            return tmpCode;
        },
        activeToggle: function (e) {
            e.target.classList.toggle("active");
            var activeYn = 'N'
            if (e.target.classList.contains('active')) {
                activeYn = 'Y';
            }
            var codeInfo = {
                code: e.target.getAttribute('data-value')
                , activeYn: activeYn
                , upCodeNum: e.target.getAttribute('up-code-num')
            }
            this.$emit('tagSpanEmit', codeInfo);

        },
        addActive: function (e) {
            console.log("adActive");

            let selAry = this.selArray;
            let selArraySplit = [];
            if (selAry == undefined) {
                selAry = '';
            }
            selArraySplit = selAry.split(',');

            const codeAllLst = document.querySelectorAll('[name="' + this.codename + '"]');
            codeAllLst.forEach(function (e) {
                selArraySplit.forEach(function (i) {
                    if (i == e.dataset.value) {
                        e.classList.add('active')
                    }
                });
            });
        }
    },
    watch: {
        'selArray': 'addActive'
    }
}
</script>
