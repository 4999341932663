import { createRouter, createWebHistory } from "vue-router";

import error404 from "@/page/mngr/views/Error404.vue";

import axiosHelper from "@/util/axiosHelper";
import cnst from "@/util/cnst";
import myCafeLoginUser from "@/user/myCafeLoginUser.js";

import structure from "../views/structure.vue";
import login from "@/page/mngr/views/login.vue";
import join from "@/page/mngr/views/mngrJoin.vue";

import dashboard from "../views/dashboard/dashboard.vue";
import cafeinfo from "../views/cafemngr/cafeinfo.vue";
import mngrinfo from "../views/cafemngr/mngrinfo.vue";
import cafervw from "../views/cafemngr/cafervw.vue";

import store from "@/store/store.js";

const routes = [
    {
        path: "/manager",
        name: "structure",
        component: structure,
        children: [
            {
                path: "",
                alias: ['main', 'index', '/', ''],
                component: dashboard,
                name: "index",
                props: true,
                // beforeEnter: requireAuth(),
            },
            {
                path: "dashboard",
                component: dashboard,
                name: "dashboard",
                props: true,
                // beforeEnter: requireAuth()
            },
            {
                path: "join",
                component: join,
                name: "join",
            },
            {
                path: "cafeinfo",
                component: cafeinfo,
                name: "cafeinfo",
                props: true,
                // meta: {
                //     roles: ['ROLE_MNGR', 'ROLE_USER']
                // },
                // beforeEnter: requireAuth()
            },
            {
                path: "mngrinfo",
                component: mngrinfo,
                name: "mngrinfo",
                props: true,
                // beforeEnter: requireAuth()
            },
            {
                path: "cafervw",
                component: cafervw,
                name: "cafervw",
                props: true,
                // beforeEnter: requireAuth()
            },
        ],
    },
    // {
    //   path: '/admin/test1',
    //   name: 'test1',
    //   components: {
    //     default:test1,
    //     a:test2,
    //     b:test3
    //   }
    // },
    {
        path: "/manager/login",
        name: "login",
        component: login,
        // beforeEnter: requireAuth()
    },
    {
        path: "/manager/:afterUser(.*)",
        name: "error404",
        component: error404,
    },
];

const mngrRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes,
});

mngrRouter.beforeEach(async (to, from, next) => {
    let result = false;
    const test = await axiosHelper({
        method: 'POST',
        url: '/auth/chkViewUrlAuth',
        data: { 'url': to.path },
    }).then((data) => {

        if (data.resultYn == 'Y') {
            next();
        } else {
            next('/manager/login');
        }
    })
})

export default mngrRouter;
