import axios from "axios";
import qs from "qs";

const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //timeout: 0,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// request interceptor 설정
instance.interceptors.request.use(
  function (config) {
    // 1. 요청 보내기 전에 실행
    // store의 토큰 값 설정
    let token = localStorage.getItem("authorization");
    if (token != null && token != "") {
      config.headers.Authorization = localStorage.getItem("authorization");
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// repose interceptor 설정
instance.interceptors.response.use(
  function (response) {
    console.log(response);
    // alert(response.headers["authorization"]);
    // if (
    //   response.headers["authorization"] != null &&
    //   response.headers["authorization"] != ""
    // ) {
    //   alert('11');
    //   localStorage.setItem("authorization", response.headers["authorization"]);
    // }

    localStorage.setItem("authorization", response.headers["authorization"]);

    return response;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.go = function (url, data, successHandle, errorHandler) {
  var queryString = qs.stringify(data);
  return instance
    .post(url, queryString)
    .then((response) => {
      console.log(response.data);
      if (response.status == 200) {
        successHandle(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
      errorHandler(error);
    });
};

instance.go2 = function (obj) {
  let url = obj.url;
  let data = obj.data;
  let successHandle = obj.succHndl;
  let errorHandler = obj.errHndl;

  var queryString = qs.stringify(data);
  instance
    .post(url, queryString)
    .then((response) => {
      console.log(response.data);
      if (response.status == 200) {
        successHandle(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }

      errorHandler(error);
    });
};

export default instance;
