<template>
    <nav class="navi-area">
        <div class="navi-box">
            <a role="button" class="btn-main" ref="btnMain" @click="this.clickNav($event, 'main')" ><i class="blind">메인</i></a><!-- 현재 페이지 .on -->
            <a role="button" class="btn-cafe" ref="btnCafe" @click="this.clickNav($event, 'cafeLst')"><i class="blind">카페</i></a>
            <a role="button" class="btn-map" ref="btnMap" @click="this.clickNav($event, 'map')"><i class="blind">지도</i></a>
            <a role="button" class="btn-mypage" ref="btnMypage" @click="this.clickNav($event, 'mypage')"><i class="blind">마이페이지</i></a>
        </div>
    </nav>
</template>

<script>

export default {
  name : 'custFooter'
  ,components:{
  },
  data : function() {
    return {
      curRouteName : '/'
    }
  },
  created(){
    this.init();
    this.curRouteName = this.$route.name;
    //this.addOn();
  },
  mounted(){
    this.addOn();
  },
    methods:{
    init:async function(){
    },
    clickNav: function(e, name){
      this.removeOn();

      e.target.classList.add('on');

      this.goNav(name);
    },
    addOn : function(){

      if(this.curRouteName.indexOf('cafe') != -1){
        this.$refs.btnCafe.classList.add('on');
      }else if(this.curRouteName.indexOf('main') != -1){
        this.$refs.btnMain.classList.add('on');          
      }else if(this.curRouteName.indexOf('map') != -1){
        this.$refs.btnMap.classList.add('on');
      }else if(this.curRouteName.indexOf('mypage') != -1
         || this.curRouteName.indexOf('favCafe') != -1){
        this.$refs.btnMypage.classList.add('on');
      }
    },
    removeOn : function(){
      let btnMain = document.querySelector('.btn-main').classList.remove('on');
      let btnCafe = document.querySelector('.btn-cafe').classList.remove('on');
      let btnMap = document.querySelector('.btn-map').classList.remove('on');
      let btnMypage = document.querySelector('.btn-mypage').classList.remove('on');
    },
    goNav(name) {
        this.$router.push({
            name: name,
        }).then((() =>window.scrollTo(0,0) ));
    },
  },watch:{
    $route(to, from) {
        this.curRouteName = this.$route.name;
        this.removeOn();
        this.addOn();
      }    
  }
}

</script>