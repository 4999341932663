<template>
    <div class="wrap">
        <header class="header-type--Account">
            <h1 class="logo"><i class="blind">MyCafe</i></h1>
        </header>
        <div class="account-wrap">
            <div class="complete-area">
                <button type="button" class="btn-pic-add"><i class="blind">프로필 사진 등록</i></button>
                <p class="complete-txt"><em>{{ user.nickNm }}</em>님, <br>회원가입이 완료되었습니다.</p>
            </div>
            <div class="btm-area">
                <button type="button" class="btn-full" @click="this.goMain">회원가입 완료</button>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//회원정보 처리 부분 시작
const user = ref({
                nickNm : '닉네임',
                id : '아이디',
            });

async function goGetUser(){
    await $axios({
        method: 'POST',
        url: '/cust/getCurrent',
    }).then((data) => {
        user.value.id = data.resultObj.id;
        user.value.nickNm = data.resultObj.nickNm;
    }).catch((error) => {
        alert("에러 발생");
    })
}

//회원정보 처리 부분 끝



function goMain(event) {
    this.$router.push({
        name: 'main',
    }).then((() =>window.scrollTo(0,0) ));
}

onMounted( () => {
    goGetUser();
})

</script>