<template>
    <div id="tabCont" class="tabcontent" style="display: block">
        <div class="section top-bg">
            <div class="sort-area">
                <em class="count">{{ totalCnt }}개</em>
                <div class="select-box">
                    <select title="정렬">
                        <option>추천 순</option>
                    </select>
                </div>
            </div>
            <div class="list-wrap full-type">
                <div v-for="(cafe, index) in cafeLst" :key="index" class="list-item">
                    <a class="list-box" @click="goCafeDetail(event, cafe.cafeNum)">
                        <div class="list-thumb"><img :src="`${baseUrl}/file/getImg/${cafe.thmbnFileLst[0].fileNum}`" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">{{cafe.cafeNm}}</strong>
                            <span class="desc">{{cafe.intr}}</span>
                            <div class="tag-box">
                                <span v-for="(item, index) in cafe.tag.split(',') " :key="index" class="tag">{{ item }}</span>
                            </div>
                        </div>
                    </a>
                    <span class="like-box"><input type="checkbox" :id="'bkmr_' + cafe.cafeNum" :checked="cafe.bkmrCafeNum != null" @click="bkmr.clickBkmr($event, cafe, 'CAFE');" ><label :for="'bkmr_' + cafe.cafeNum"><i class="blind">좋아요</i></label></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//공통함수
import bkmr from "@/util/bkmr.js";


//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

//부모 컴포넌트에서 받은 값
const props = defineProps({
  cafeLst: Array,
  totalCnt : Number,
})

const baseUrl = process.env.VUE_APP_SERVER_URL;


function goCafeDetail(event, cafeNum) {
    router.push({
        name: 'cafeInfo',
        params : { cafeNum:cafeNum}
    }).then((() =>window.scrollTo(0,0) ));
}

</script>