<template>
  <section class="section-wrap tabsboard">
    <!-- Tab links -->
        <div class="tab">
            <a href="./cafeinfo" class="tablinks">카페정보</a>
            <a href="./mngrinfo" class="tablinks">사장님정보</a>
            <a href="./cafervw" class="tablinks active">후기</a>
        </div>
    <!-- Tab content -->
    <div id="tabCont-03" class="tabcontent" style="display: block">
      <h3 class="blind">후기</h3>
      <article class="article-box full-box">
        <strong class="article-tit"
          >후기<span class="count">2,679개</span></strong
        >
        <div class="list-wrap line-type">
          <!-- 전체보기(.all-open) -->
          <div class="list-item">
            <div class="list-box type-01">
              <strong class="tit">커피가 맛있어요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 80%"
                  ><i class="blind">80%</i></span>
              </div>
              <span class="count">9,999</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-02">
              <strong class="tit">디저트가 다양해요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 60%"
                  ><i class="blind">60%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-03">
              <strong class="tit">접근성이 좋아요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 20%"
                  ><i class="blind">20%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-04">
              <strong class="tit">직원분이 친절해요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 20%"
                  ><i class="blind">20%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-05">
              <strong class="tit">콘센트가 많아요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 20%"
                  ><i class="blind">20%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-06">
              <strong class="tit">공부하기 좋아요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 20%"
                  ><i class="blind">20%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-box type-07">
              <strong class="tit">특별한 메뉴가 있어요</strong>
              <div class="graph-box">
                <span class="bar" style="width: 20%"
                  ><i class="blind">20%</i></span
                >
              </div>
              <span class="count">198</span>
            </div>
          </div>
        </div>
        <div class="frm-box switch-box">
          <span class="label">후기사용</span>
          <label class="switch">
            <input type="checkbox" title="후기사용여부" checked />
            <span class="slider round"></span>
          </label>
        </div>
      </article>
      <div class="article-search-wrap">
        <div class="label-grp">
          <input
            type="text"
            value="2021. 01. 01 ~ 2021. 03. 18"
            class="date"
            title="날짜선택"
          />
          <button type="button" class="btn-blk">조회</button>
          <span class="desc">* 조회 가능한 최대 기간은 6개월입니다.</span>
        </div>
        <div class="article-box-grp">
          <article class="article-box l-box">
            <div class="list-info">
              <div class="account-info">
                <span class="pic-box"
                  ><img src="/mycafemngr/images/dummy/@my-pic.jpg" alt=""
                /></span>
                <div class="txt-box">희정이밥버거</div>
                <div class="date-box">3일전</div>
              </div>
              <div class="tag-box">
                <span class="tag">잉글리쉬 블랙퍼스트 티라떼</span>
                <span class="tag">아메리카노</span>
              </div>
              <div class="tag-box img-type">
                <span class="tag type-01">커피가 맛있어요</span>
                <span class="tag type-02">디저트가 다양해요</span>
              </div>
            </div>
          </article>
          <article class="article-box r-box">
            <div class="list-info">
              <figure class="item">
                <img src="/mycafemngr/images/dummy/@thum_105x80-01.jpg" alt="" />
              </figure>
              <div class="txt">
                프리미엄 디저트 카페!<br />
                새로운 시도였고 새로운 맛이었습니다. 디저트는 1인 1주문을
                해야했구요, 저는 차 두 잔에 디저트 핀즈 시그니처와 피치 베르를
                주문했습니다.
              </div>
              <div class="txt-line-box">
                <div class="textarea">내용이 있을경우..</div>
                <div class="btn-area">
                  <button type="button" class="btn-line">삭제</button>
                  <button type="button" class="btn-full">수정</button>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="article-box-grp">
          <article class="article-box l-box">
            <div class="list-info">
              <div class="account-info">
                <span class="pic-box"
                  ><img src="/mycafemngr/images/dummy/@my-pic.jpg" alt=""
                /></span>
                <div class="txt-box">희정이밥버거</div>
                <div class="date-box">3일전</div>
              </div>
              <div class="tag-box">
                <span class="tag">잉글리쉬 블랙퍼스트 티라떼</span>
                <span class="tag">아메리카노</span>
              </div>
              <div class="tag-box img-type">
                <span class="tag type-01">커피가 맛있어요</span>
                <span class="tag type-02">디저트가 다양해요</span>
              </div>
            </div>
          </article>
          <article class="article-box r-box">
            <div class="list-info">
              <div class="txt">
                프리미엄 디저트 카페!<br />
                새로운 시도였고 새로운 맛이었습니다. 디저트는 1인 1주문을
                해야했구요, 저는 차 두 잔에 디저트 핀즈 시그니처와 피치 베르를
                주문했습니다.
              </div>
              <div class="txt-line-box">
                <textarea class="textarea" title="내용입력">내용입력</textarea>
                <div class="btn-area">
                  <button type="button" class="btn-line">삭제</button>
                  <button type="button" class="btn-full">등록</button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <button @click="count++">{{ count }}</button>
    <button @click="getCount()">버튼</button>

  </section>
</template>



<script setup>
  import { ref, getCurrentInstance, computed  } from 'vue'
  import { useStore } from 'vuex'

  let store = useStore();

  let cafeInfo = ref({});
  const { proxy } = getCurrentInstance();

  function getCount() { 
    console.log(this.count);
  }


  const setCafeInfo = () => store.commit('cafe/setMyCafe', cafeInfo)
  const vuexCafeInfo = computed(() => state.cafe.myCafe)

  async function goGetCafe(){
    proxy.$axios({
        method: "POST",
        url: "/cafe/get"
    }).then((data) => {
        var resultObj = data.resultObj;
        console.log(resultObj);
        if (resultObj != null) {

          console.log( data.resultObj.cafe );

          cafeInfo = data.resultObj.cafe;
          
          
          // this.setMyCafe(data.resultObj.cafe);
            // this.srvcCodeLst = data.resultObj.srvcCodeLst;
            // let tmpCodeLst = data.resultObj.ctgrCodeLst;
            // let tmpCafeCtgrLst = data.resultObj.cafe.cafeCtgrLst;
            // this.ctgrCodeLst = CodeClass.getCodeListByCmprList(tmpCodeLst, tmpCafeCtgrLst, 'cafeCtgrNum');
        };
    })
  }
  goGetCafe();
  setCafeInfo();

  console.log( cafeInfo );
  console.log( vuexCafeInfo );






  const count = ref(0);

</script>