<template>
    <div class="list-section">
        <ul class="list-box line-box toggle-box">
            <li v-for="(arti, index) in boardLst" :key="index">
                <a role="button" class="btn-link" @click="goDetail" >{{ arti.ttl }}</a>
                <div class="box-Cont">
                    <div v-html="arti.cntn"></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//components
import StringUtil from '@/util/StringUtil.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

//부모 컴포넌트에서 받은 값
const props = defineProps({
  boardNum: String
})


//게시판 정보 처리 시작
const board = ref({});
const boardLst = ref([]);

function goDetail(e){
    e.target.classList.toggle("active");
}

async function goGetLst(board){
    let tmpBoardLst = [];
    await $axios({
        method: 'POST',
        url: '/arti/lst',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoardLst = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoardLst;
}


onMounted( async () => {
    board.value.boardNum = props.boardNum;
    boardLst.value = await goGetLst(board.value);

})
//유저 정보 처리 끝


</script>