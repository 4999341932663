// String Util
class StringUtil {

/* 2023.04.19
 * 백국현
 * timestamp 값을 Date 값으로 변경한다.
 */ 
  static timestampToDate(dateTime){
      console.log(dateTime);
      if(dateTime == '' || dateTime == undefined) return '';
      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(dateTime);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();
      var hh = js_date.getHours();
      var mm = js_date.getMinutes();
      var ss = js_date.getSeconds();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if(month < 10){
          month = '0' + month;
      }

      if(day < 10){
          day = '0' + day;
      }

      if(hh < 10){
          hh = '0' + hh;
      }

      if(mm < 10){
          mm = '0' + mm;
      }

      if(ss < 10){
          ss = '0' + ss;
      }

      // 최종 포맷 (ex - '2021-10-08 05:17:20')
      return year + '-' + month + '-' + day + " " + hh + ":" + mm + ":" + ss;
  }

  static Unix_timestamp (t){
    var date = new Date(t);
    var year = date.getFullYear();
    var month = "0" + (date.getMonth()+1);
    var day = "0" + date.getDate();
    var hour = "0" + date.getHours();
    var minute = "0" + date.getMinutes();
    var second = "0" + date.getSeconds();
    return year + "-" + month.substr(-2) + "-" + day.substr(-2) + " " + hour.substr(-2) + ":" + minute.substr(-2) + ":" + second.substr(-2);
  }
}
export default StringUtil;