<template>
  <option v-for="(item, index) in codeLst" :key="index" :value="item.codeNum" :name="codename" >{{ item.codeNm}}</option>
</template>

<script>
import axiosUtil from '@/util/request';

export default {
  name : 'selOption',
  props:['upCodeNum', 'selCodeNum', 'codename'],
  data(){
    return {
      codeLst : [],
      code : {}
    };
  },
  computed:{
  },
  created() { 
      this.init();
  },
  methods: {
    init:async function(){
      this.code = new Object();
      this.codeLst =  await this.goGetCodeLst();
    },
    goGetCodeLst: async function(){
      const code = {"upCodeNum": this.upCodeNum};
      let tmpCode = [];
      const test = await axiosUtil({
          method: 'POST',
          url: '/code/lst', 
          data : code
        }).then((data) => {
          var resultObj =  data.resultObj;
          if( resultObj != null){
              tmpCode = data.resultObj;
              }
        });
        return tmpCode;
    },
    addSelected: function(e){

      let selCodeNum = this.selCodeNum;
      if(selCodeNum == undefined){ 
        selCodeNum = '';
      }

      const codeAllLst = document.querySelectorAll('[name="' + this.codename + '"]');
      codeAllLst.forEach(function(e){
          if(selCodeNum == e.value){
            e.selected = true
          }
      });
    }
  },
  watch:{
    'selCodeNum':'addSelected'
  }
}
</script>