<template>
    <header class="layer-header">
        <button type="button" class="btn-close-bar" @click="closeLayer()"><i class="blind">Layer Close</i></button>
        <h1 class="blind">Filter</h1>
    </header>
    <section class="layer-cont">
        <dl class="filter">
            <template v-for ="(item, index) in moodLst" :key="index" >
                <dt v-if="index == 0" class="tit">
                    {{ item.upCodeNm }}
                </dt>
                <dd>
                    <span class="tag-chk"><input type="checkbox" :id="'layer_' + item.codeNum" :value="item.codeNum" v-model="selMoodLst" ><label :for="'layer_' + item.codeNum" :value="item.codeNum">{{ item.codeNm }}</label></span>
                </dd>    
            </template>
            <template v-for ="(item, index) in srvcLst" :key="index" >
                <dt class="tit">
                    {{ item.codeNm }}
                </dt>
                <dd>
                    <span class="tag-chk"><input type="checkbox" :id="'layer_' + item.codeNum" :value="item.codeNum" v-model="selSrvcLst"><label :for="'layer_' + item.codeNum">가능</label></span>
                </dd>
                <dd>
                    <span class="tag-chk"><input type="checkbox" :id="'layer_' + item.codeNum + '_N'" :value="item.codeNum"><label :for="'layer_' + item.codeNum+ '_N'">불가능</label></span>
                </dd>
            </template>
        </dl>
    </section>
    <footer class="layer-footer">
        <button type="button" class="btn-full" @click="enterLayer" >확인</button>
    </footer>
</template>

<script setup>

import { useStore } from "vuex";
import { ref, onMounted, getCurrentInstance, computed, reactive, watch } from 'vue'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore();

const code = reactive({});
const moodLst = ref([]);
const srvcLst = ref([]);

const selMoodLst = ref([]);
const selSrvcLst = ref([]);


//레이어 처리
function enterLayer(){
    store.dispatch('layer/setCafeFilterMoodLst', selMoodLst.value)
    store.dispatch('layer/setCafeFilterSrvcLst', selSrvcLst.value)
    closeLayer();
}

function closeLayer(){
    const layer = {};
    layer.show = false;
    layer.name = 'cafeFilter';
    store.dispatch('layer/setLayerLst', layer)
}

async function goGetCodeLst(code){
    let tmpCode = [];
    await $axios({
        method: 'POST',
        url: '/code/lst', 
        data : code
        }).then((data) => {
        var resultObj =  data.resultObj;
        if( resultObj != null){
            tmpCode = data.resultObj;
        }
    });
    return tmpCode;
}

onMounted( async () => {
    code.upCodeNum = '4001'; //분위기
    moodLst.value = await goGetCodeLst(code);

    code.upCodeNum = '7'; // 서비스 분류
    srvcLst.value = await goGetCodeLst(code);

});


watch(store.state.layer.layerLst, (currentValue, oldValue) => {
        selMoodLst.value = store.state.layer.layerLst.cafeFilter.selMoodLst;
    }
    ,{ deep: true });

</script>