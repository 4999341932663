<template>
    <div class="section bg-gray">
        <h2 class="sec-tit">내가 찜한 카페</h2>
        <button type="button" class="btn-list-more" @click="this.goCafeLst(e, '2006')">전체보기</button>
        <cafe-swiper :cafeLst="this.cafeLst"/>
    </div>
</template>

<script>
import axiosUtil from '@/util/request';
import cafeSwiper from '@/page/main/common/cafeSwiper.vue';
import cafeEnum from '@/util/cafe.js'

import cafeJs from '@/util/cafe.js';

export default {
  name : 'custFavCafe'
  ,components:{
      'cafe-swiper' : cafeSwiper
  }
  ,data(){
    return {
      cafeLst : []
      ,cafe : {}
      ,pageObj :{totalCnt : 0 //최대 리스트 개수
                ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                ,pageSize : 1 //보여줄 페이징의 최대 개수
                ,pageBlockNum : 10 //한 페이지 내에 보여줄 리스트 개수
                }
    };
  }
  ,created(){
    this.init();

  },methods:{
    init:async function(){
        this.cafe = new Object();

        this.cafe.pageNum = this.pageObj.pageNum; //현재 페이지 번호
        this.cafe.pageSize = this.pageObj.pageSize; //최대 페이지 개수
        this.cafe.pageBlockNum = this.pageObj.pageBlockNum; //페이지 내에 보여줄 리스트 개수


        //로그인 정보가 없을 경우, 해당 부분에서 분기 필요. 찜한 카페 / 추천 카페
        let token = localStorage.getItem("authorization");
        token != null ? this.cafe.cafeSrchCode = cafeEnum.SRCH.SRCH_CODE.FAV : this.cafe.cafeSrchCode = cafeEnum.SRCH.SRCH_CODE.RCMN

        this.cafeLst =  await this.goGetCafeList(this.cafe);
    },
    goGetCafeList : async function(cafe){
        let tmpCafeLst = null
        const test = await axiosUtil({
            method: 'POST',
            url: '/cafe/lst', 
            data : cafe
            }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null && resultObj.length > 0 ){
                tmpCafeLst = data.resultObj;
            }
        })
        return tmpCafeLst;
    }, 
    goCafeLst(event, searchCafe) {
        this.$router.push({
            name: 'cafeLst',
            query : { paramSrchCafe:searchCafe}
        }).then((() =>window.scrollTo(0,0) ));;
    },
  }
}

</script>