<template>
    <div id="tabCont-04" class="tabcontent">
        <h3 class="blind">공지/이벤트</h3>
        <!-- sub-Tab links -->
        <div class="sub-tab">
            <button class="sub-tablinks active" onclick="subOpenView(event, 'sub-tabCont-041')">공지사항</button>
            <button class="sub-tablinks" onclick="subOpenView(event, 'sub-tabCont-042')">이벤트</button>
        </div>
        <!-- sub-Tab content -->
        <div id="sub-tabCont-041" class="sub-tabCont" style="display: block">
            <strong class="blind">공지사항</strong>
            <div class="list-wrap evt-type">
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@evt_339x180-01.png" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">디저트39 인증샷 이벤트</strong>
                            <span class="date">2022-08-01</span>
                        </div>
                    </a>
                </div>
                <div class="list-item">
                    <a href="#" class="list-box">
                        <div class="list-thumb"><img src="../../../../../../public/mycafe/images/dummy/@evt_339x180-02.png" alt=""></div>
                        <div class="list-info">
                            <strong class="tit">친구초대 이벤트</strong>
                            <span class="date">2022-08-01</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div id="sub-tabCont-042" class="sub-tabCont" style="display: none">
            <strong class="blind">이벤트</strong>
            <div class="list-wrap evt-type">
                진행중인 이벤트가 없습니다.
            </div>
        </div>
    </div>
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import SwiperCore, { Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

import axiosUtil from '@/util/request';
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name : 'custBnr',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
         cafe : {}
         ,baseUrl : process.env.VUE_APP_SERVER_URL
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
        },

        goGetBnrList : async function(bnr){
            let tmpCafe = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/lst', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpBnrList = data.resultObj;
                }
            })
            return tmpBnrList;
        }, 
  }
  
}
</script>