import { createStore } from "vuex";
import cafeModule from "./cafe/cafe.module.js";
import layerModule from "./common/layer.module.js";
import locationModule from "./common/location.module.js";
import custModule from "./user/cust.module.js";
import loginUserModule from "./user/loginUser.module.js";

export default createStore({
  state: {
    test: "1222",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    cafe: cafeModule,
    layer: layerModule,
    location: locationModule,
    cust: custModule,
    loginUserMdl: loginUserModule
  },
});
