// BOARD 분류
var board = {
  SKIN: {
      NORMAL : "6022",
      DROPDOWN: "6023",
      EVNT : "6024",
  },
  EVNT :{
    ACTIVE_EVNT : "6027",
    END_EVNT : "6028",
  }
};

export default board;
