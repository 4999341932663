import axiosHelper from "@/util/axiosHelper";

var BKMR ={
  CAFE : {
    REG : '/bkmrCafe/reg',
    GET : '/bkmrCafe/get',
    LST : '/bkmrCafe/lst',
    DEL : '/bkmrCafe/del',
  },
  MENU : {
    REG : '/bkmr/reg',
    GET : '/bkmr/get',
    LST : '/bkmrCafe/lst',
    DEL : '/bkmrCafe/del',
  },
  TAG : {
    REG : '/bkmr/reg',
    GET : '/bkmr/get',
    LST : '/bkmrCafe/lst',
    DEL : '/bkmrCafe/del',
  }
}

var BKMR_ITEM ={
  CAFE : {
    REG : cafeItem,
    DEL : cafeItem,
  },
}


function cafeItem(item){
  let returnItem = new Object;
  returnItem.cafeNum = item.cafeNum;
  returnItem.bkmrCafeNum = item.bkmrCafeNum;
  return returnItem;
}


const bkmr = {

}

bkmr.clickBkmr = async function(e, item, bkmrname){
  //로그인 유무 확인
  let token = localStorage.getItem("authorization");
  if(token == null){
    e.target.checked = false;
    alert('로그인이 필요한 서비스입니다.');
    return false;
  }

  let isChecked = e.target.checked;
  let callname;

  isChecked ? callname = 'REG' : callname = 'DEL'

  let resultBkmr = await this.callAxios(item, bkmrname, callname);

  item.bkmrCafeNum = resultBkmr.bkmrCafeNum;
}


bkmr.callAxios =  async function (item, bkmrname, callname){

  let bkmrUrl = BKMR[bkmrname][callname];
  let bkmrItem = BKMR_ITEM[bkmrname][callname](item);
  let tmpItem = new Object();
  
  const test = await axiosHelper({
    method: 'POST',
    data: bkmrItem,
    url: bkmrUrl,
    }).then((data) => {
      var resultObj = data.resultObj;
          if( resultObj != null  ){
            tmpItem = resultObj;
          }
    }).catch((error) => {
        alert("에러 발생");
    })

    return tmpItem;
}

export default bkmr;
