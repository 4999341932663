// USER 분류
var cnst = {
  USER: {
    GBN: {
      GUEST: "00",
      CUST: "10",
      MNGR: "20",
      ADMN: "30",
    },
  },
  COOKIE: {
    USER_GBN_NM: "myBeanUgc"
  },
  SMPL: {
    NORMAL: {
      CUST_REG_TYPE_CODE: '6006',
    },
    NAVER: {
      CUST_REG_TYPE_CODE: '6007',
      CLIENT_ID: process.env.VUE_APP_SMPL_NAVER_CLIENT_ID,
      CALLBACK_URL: 'login/naver',
    },
    KAKAO: {
      CUST_REG_TYPE_CODE: '6008',
      CLIENT_ID: '2b5ede0a7862ce881958f605430160ce',
      CALLBACK_URL: 'login/kakao',
    },
    GOOGLE: {
      CUST_REG_TYPE_CODE: '6009',
      CLIENT_ID: '972004952593-5ulp94716bsk9k9og8ga7ofpjk6sr9ba.apps.googleusercontent.com',
      CALLBACK_URL: 'login/google',
    }
  }
};

export default cnst;
