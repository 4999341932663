<template>
    <body>
        <div class="wrap">
            <header class="header-type--List">
                <button type="button" class="btn-prev" @click="clickBack"><i class="blind">이전</i></button>
                <em class="txt-cafe">지도</em>
                <div class="util-group">
                    <button type="button" class="btn-search" @click="goSrch"><i class="blind">검색</i></button>
                </div>
            </header>
            <div class="map-wrap" id="map">
                <!-- <img src="../../../../../public/mycafe/images/dummy/map.png" alt=""> -->



            </div>
            <!-- Map(지도검색)(L) -->
            <div class="layer-wrap map-search-layer hide" id="myMap" ref="cafeLstLayer">
                <header class="layer-header" @touchstart="start" @touchmove="move" @touchend="end" @mousedown="start" @mouseup="end" @mousemove="move" @mouseleave="end">
                    <button type="button" class="btn-close-bar"><i class="blind">Layer Close</i></button>
                    <span class="desc">위로 끌어올려보세요.</span>
                    <h1 class="blind">Map</h1>
                    <div class="map-layer-option">
                        <span class="frm-area"><input type="radio" id="chk-01" name="chk" checked><label
                                for="chk-01">내위치중심</label></span>
                        <span class="frm-area"><input type="radio" id="chk-02" name="chk"><label
                                for="chk-02">관련도순</label></span>
                    </div>
                </header>
                <section class="layer-cont">
                    <div class="list-wrap full-type">
                        <div class="list-item" v-for="(cafe, index) in cafeLstShow" :key="index">
                            <a class="list-box" @click="goCafeDetail(cafe.cafeNum)" >
                                <div class="list-info">
                                    <strong class="tit">{{ cafe.cafeNm }}</strong>
                                    <span class="desc"> {{ dstnReg(cafe.dstn) }}| {{ cafe.intr}} </span>
                                    <div class="tag-box">
                                        <span v-for="(item, index) in cafe.tag.split(',')" :key="index" class="tag">#{{ item }}</span>
                                    </div>
                                </div>
                            </a>
                            <span class="like-box"><input type="checkbox" id="chk-11"><label for="chk-11"><i
                                        class="blind">좋아요</i></label></span>
                            <div class="scroll-cont">
                                <ul class="pic-list">
                                    <li v-for="(img, index) in cafe.imgFileLst" :key="index" >
                                        <a href="#Modal" role="button" class="list-thumb"><img
                                                :src="`${baseUrl}/file/getImg/${img.fileNum}`" alt=""></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <button type="button" class="btn-map_locate" @click="currentLocation" ref="mapLocate" ><i class="blind">location</i></button>
    </body>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, watch, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

var mapOptions = {
    center: new naver.maps.LatLng(37.3595704, 127.105399),
    zoom: 18,
    scaleControl: false,
    mapDataControl: true,
};
const baseUrl = ref('');

const cafe = reactive({});
const cafeLst = ref([]);
const cafeLstShow = ref([]);
const cafeLstLayer = ref(null); //카페 리스트 레이어
const mapLocate = ref(null); //현재위치 버튼

var map = {};
const markers = ref([]);
let selectedMarker = {};

if(store.state.location.latlot.lat > 0){
    var curLatlot = new naver.maps.LatLng(store.state.location.latlot.lat, store.state.location.latlot.lot);
    mapOptions.center = curLatlot;
    cafe.curLot = store.state.location.latlot.lot
    cafe.curLat = store.state.location.latlot.lat
}

const isPress = ref(false);
const prevPosX = ref(0);
const prevPosY =  ref(0);
const hideBarTop =  ref(168);


function start(e) {
    let eventType = e.type;
    if(eventType == "touchstart"){
        prevPosX.value = e.touches[0].clientX;
        prevPosY.value = e.touches[0].clientY;

    }else{
        prevPosX.value = e.clientX;
        prevPosY.value = e.clientY;
    }

    hideBarTop.value = cafeLstLayer.value.clientHeight;

    isPress.value = true;
}

function move(e) {

    if (!isPress.value) return;

    let eventType = e.type;
    let posX, posY;

    if(eventType == "touchmove"){
        posX = prevPosX.value - e.touches[0].clientX;
        posY = prevPosY.value - e.touches[0].clientY;
        prevPosY.value = e.touches[0].clientY;
    }else{
        posX = prevPosX.value - e.clientX;
        posY = prevPosY.value - e.clientY;
        prevPosY.value = e.clientY;
    }


    let myMapHeight = cafeLstLayer.value.clientHeight + posY;

    cafeLstLayer.value.style.height = (myMapHeight + "px");
    mapLocate.value.style.bottom = (6 + myMapHeight + "px");

    cafeLstLayer.value.classList.remove("hide");
    cafeLstLayer.value.classList.remove("show-lst");
    cafeLstLayer.value.classList.remove("show-one");

    mapLocate.value.classList.remove("high");
    mapLocate.value.classList.remove("middle");
    mapLocate.value.classList.remove("low");
}

function end() {
    isPress.value = false;

    if (cafeLstLayer.value.clientHeight > 500) {
        cafeLstLayer.value.classList.add("show-lst");
        mapLocate.value.classList.add("high");
    }else if (cafeLstLayer.value.clientHeight < 250) {
        cafeLstLayer.value.classList.add("hide");
        mapLocate.value.classList.add("low");
    }else{
        cafeLstLayer.value.classList.add("show-one");
        mapLocate.value.classList.add("middle");
    }

    cafeLstLayer.value.style.height = "";
    mapLocate.value.style.bottom = "";

}

onMounted( () => {
    baseUrl.value = process.env.VUE_APP_SERVER_URL;

    map = new naver.maps.Map('map', mapOptions);
    goGetCafeLst();
})

//카페 정보 가져오기

async function goGetCafeLst(){
    const test = await $axios({
        method: 'POST',
        data: cafe,
        url: '/cafe/lst',
        }).then((data) => {
            cafeLst.value =  data.resultObj;
            cafeLstShow.value =  data.resultObj;
            makeMarker();
        }).catch((error) => {
            alert("에러 발생");
        })
    
}
function makeMarker(){
    if(markers.value.length > 0){
        markers.value.forEach(function(marker){
            marker.setMap(null);
        });
    }

    markers.value = [];
    cafeLst.value.forEach(function(cafe){
        var position = new naver.maps.LatLng(cafe.lat, cafe.lot);
        var markerOptions = {
            position: position,
            map: map,
            icon: {
                content: '<div class="map-marker" data-value="' + cafe.cafeNum + '" ><img src="" /> </div>',
                size: new naver.maps.Size(30, 30),
                origin: new naver.maps.Point(0, 0),
                anchor: new naver.maps.Point(11, 35)
            }
        };
        var marker = new naver.maps.Marker(markerOptions);
        markers.value.push(marker);
    });

    //마커 이벤트 추가
    for(let i=0; i<markers.value.length; i++){
        naver.maps.Event.addListener(markers.value[i], "click", function(e) {

            if(selectedMarker.domEvent != undefined)
                selectedMarker.domEvent.target.classList.remove("active");

            //기존 다른 마커의 정보를 찾아서 active 클래스를 제거해준다.
            selectedMarker = e;
            
            e.domEvent.target.classList.add("active");

            //클릭된 카페의 정보만 노출되도록 변경
            cafeLstShow.value = cafeLst.value.filter(cafe => cafe.cafeNum == e.domEvent.target.dataset.value);
            cafeLstLayer.value.classList.remove("hide");
            cafeLstLayer.value.classList.remove("show-lst");
            cafeLstLayer.value.classList.add("show-one");

            mapLocate.value.classList.remove("high");
            mapLocate.value.classList.remove("low");
            mapLocate.value.classList.add("middle");

        });
    }
}

function currentLocation(){
    navigator.geolocation.getCurrentPosition(
        function(position) {

        store.dispatch('location/setLocation', position.coords)

        var curLatlot = new naver.maps.LatLng(store.state.location.latlot.lat, store.state.location.latlot.lot);
        map.setCenter(curLatlot)
        }, 
    );
}

watch(store.state.location.latlot, (currentValue, oldValue) => {
        //맵에서 새로고침할 경우 위치를 집어넣기 위해 사용
        var curLatlot = new naver.maps.LatLng(store.state.location.latlot.lat, store.state.location.latlot.lot);
        map.setCenter(curLatlot)
        cafe.curLot = store.state.location.latlot.lot;
        cafe.curLat = store.state.location.latlot.lat;
        goGetCafeLst();
    }
    ,{ deep: true });


function dstnReg(dstn){
    if(dstn < 0.1){
        return "100m 이내";
    }else if(dstn <= 1){
        return Math.round(dstn * 10) * 100 + "m"
    }else{
        return Math.round(dstn * 10) / 10 + "km"
    }
}

function goCafeDetail(cafeNum) {
    router.push({
        name: 'cafeInfo',
        params : { cafeNum:cafeNum}
    }).then((() =>window.scrollTo(0,0) ));
}


function goSrch() {
    router.push({
        name: 'srch',
    }).then((() =>window.scrollTo(0,0) ));;
}

function clickBack(){
    if(selectedMarker.domEvent != undefined){
        selectedMarker.domEvent.target.classList.remove("active");
        selectedMarker = {};

        cafeLstShow.value = cafeLst.value
    }else{
        router.go(-1);
    }
}
</script>