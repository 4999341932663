<template>
    <!-- Start Content-->
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{title}} </h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="boardNm" class="form-label">게시판명</label>
                                    <input type="text" id="boardNm" class="form-control" v-model="board.boardNm" placeholder="게시판명을 입력해주세요.">
                                    <input type="hidden" id="cafeNum" class="form-control" v-model="board.cafeNum" />
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">사용여부</label>
                                    <select class="form-control" v-model="board.useYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">비밀글 사용 여부</label>
                                    <select class="form-control" v-model="board.undcovUseYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">에디터 사용 여부</label>
                                    <select class="form-control" v-model="board.edtrUseYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">일대일 사용 여부</label>
                                    <select class="form-control" v-model="board.onoUseYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">익명사용여부</label>
                                    <select class="form-control" v-model="board.anonUseYn" required="" placeholder="사용여부를 선택해주세요">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                            </div> <!-- end col-->

                            <div class="col-xl-6">
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">게시판 구분 코드</label>
                                    <select class="form-control" v-model="board.boardCode" required="">
                                        <option value="100" selected>Code1</option>
                                        <option value="200">Code2</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">게시판 스킨 코드</label>
                                    <select class="form-control" v-model="board.boardSkinCode" required="">
                                        <sel-option upCodeNum="6021" :selCodeNum="board.boardSkinCode" codename="boardSkinCode"></sel-option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">첨부파일 사용 여부</label>
                                    <select class="form-control" v-model="board.fileUseYn" required="">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">카테고리 사용 여부</label>
                                    <select class="form-control" v-model="board.ctgrUseYn" required="">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">댓글 사용 여부</label>
                                    <select class="form-control" v-model="board.cmntUseYn" required="">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="subCodeNm" class="form-label">답글 사용 여부</label>
                                    <select class="form-control" v-model="board.ansrUseYn" required="">
                                        <option value="Y">Y</option>
                                        <option value="N" selected>N</option>
                                    </select>
                                </div>
                            </div> <!-- end col-->
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="text-sm-end">
                                    <button type="button" id="delBtn" class="btn btn btn-secondary mb-2 btn-sm" v-show="delBtnShow" @click="goDelBoard()"><i class="mdi mdi-trash-can me-2"></i> 삭제 </button>
                                    <button type="button" id="cancelBtn" class="btn btn btn-success mb-2 btn-sm" @click="goBoard()"><i class="mdi dripicons-wrong me-2"></i> 취소 </button>
                                    <button type="button" id="modifyBtn" class="btn btn btn-danger mb-2 btn-sm" v-show="modifyBtnShow" @click="goRegBoard()"><i class="mdi mdi-plus-circle me-2"></i> 수정 </button>
                                    <button type="button" id="addBtn" class="btn btn btn-danger mb-2 btn-sm"  v-show="addBtnShow" @click="goRegBoard()"><i class="mdi mdi-plus-circle me-2"></i> 추가 </button>
                                </div>
                            </div>
                        </div>

                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>
    </div> <!-- container -->
</template>

<script>
import selOption from '@/page/admin/common/selOption.vue';

export default {
    name: 'boardWrite',
    components: { selOption },
    data : function() {
        return {
         board : {}
         ,mode : ''   // GET, DEL, MOD, REG
         ,selBoardNum : 0
         ,title : '게시판 추가'
         ,modifyBtnShow : false
         ,delBtnShow : false
         ,addBtnShow : true
        }
    },
    created() { 
        this.init();
    },
    methods:{
        init : async function(){
            this.board = new Object();

            this.board.useYn = 'N';
            this.board.fileUseYn = 'N';
            this.board.ctgrUseYn = 'N';
            this.board.cmntUseYn = 'N';
            this.board.ansrUseYn = 'N';
            this.board.undcovUseYn = 'N';
            this.board.edtrUseYn = 'N';
            this.board.onoUseYn = 'N';
            this.board.alrmUseYn = 'N';
            this.board.anonUseYn = 'N';
            this.board.cafeNum = 0;

            this.selBoardNum = this.$route.query.boardNum;

            if(this.selBoardNum > 0){
                this.title = '게시판 수정';
                this.board.boardNum = this.selBoardNum;
                this.board = await this.goGetBoard(this.board);

                this.modifyBtnShow = true;
                this.addBtnShow = false;
                this.delBtnShow = true;
            }
        },
        /***
         *게시판 리스트를 조회를 한다. 
         */
        goGetBoard : async function(board){
            let tmpBoard = new Object();
            const test = await this.$axios({
                method: 'POST',
                url: '/board/get', 
                data : board
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null  ){
                    tmpBoard = resultObj;
                }
            })
            return tmpBoard;
        },
        goBoard() {
            this.$router.push({
                path: `/admin/board/lst`
            });
        },
        goRegBoard: function(){
            this.$axios({
                method: 'POST',
                url: '/board/reg', 
                data : this.board
             }).then((data) => {
                this.goBoard();
            })
        }, 
        goDelBoard: function(){
            if(confirm('정말로 삭제하시겠습니까?')){
                this.board.delYn = 'Y'
                this.$axios({
                    method: 'POST',
                    url: '/board/reg', 
                    data : this.board
                }).then((data) => {
                    this.goBoard();
                })
            }
        }, 
    }
}
</script>
