<template>
  <select title="시간" :name="name" @change="changeTime">
    <option v-for="(item, index) in hours" :key="index" :value="item" :selected="item == this.value ? true : false ">{{ item }}</option>
  </select> 
</template>

<script>

export default {
  name: 'timeSelect',
  data : function() {
      return {
        hours : []
      }
  },
  created(){
    this.makeTimeSelect();
  },
  props:['startTime', 'endTime', 'value', 'name', 'changeEvent'],
  methods:{
    makeTimeSelect:function(){
      let tmpHour = "";
      console.log("timeSelect created : " + this.startTime);
      
      for(var i = this.startTime; i <= this.endTime; i++){
        if(i < 10){
          tmpHour ="0"+i;
        }else{
          tmpHour = i;
        }
        this.hours.push(tmpHour+":00");
      }
    },changeTime:function(e){
      this.changeEvent(e);
    }
  }
}
</script>
