<template>
  <!-- Start Content-->
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">메뉴관리</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8">
                <h4 class="header-title">메뉴 목록</h4>
              </div>
              <div class="col-sm-4">
                <div class="text-sm-end">
                  <button
                    type="button"
                    id="addNodeBtn"
                    class="btn btn btn-danger mb-2 btn-sm"
                    @click="addNode()"
                  >
                    <i class="mdi mdi-plus-circle me-2"></i> 추가
                  </button>
                </div>
              </div>
            </div>
            <!-- end nav-->
            <div class="tab-content">
              <div id="tree"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">메뉴</h4>
            <!-- end nav-->
            <div class="mb-3">
              <label for="upMenuNm" class="form-label">상위메뉴명</label>
              <input
                type="text"
                id="upMenuNm"
                class="form-control"
                v-model="menu.upMenuNm"
                placeholder=""
                readonly
              />
            </div>
            <div class="mb-3">
              <label for="menuNm" class="form-label">메뉴명</label>
              <input
                type="text"
                id="menuNm"
                class="form-control"
                v-model="menu.menuNm"
                placeholder=""
              />
            </div>
            <div class="mb-3">
              <label for="menuGbnCode" class="form-label">메뉴구분코드</label>
              <select
                class="form-control"
                type="text"
                v-model="menu.menuGbnCode"
                required=""
              >
                <option value="100">Y</option>
                <option value="200" selected>N</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="icon" class="form-label">아이콘</label>
              <input
                type="text"
                id="icon"
                class="form-control"
                v-model="menu.icon"
                placeholder=""
              />
            </div>
            <div class="mb-3">
              <label for="sortNum" class="form-label">정렬 번호</label>
              <input
                type="text"
                id="sortNum"
                class="form-control"
                v-model="menu.sortNum"
                placeholder=""
              />
            </div>
            <div class="mb-3">
              <label for="useYn" class="form-label">사용 여부</label>
              <select
                class="form-control"
                type="text"
                v-model="menu.useYn"
                required=""
              >
                <option value="Y">Y</option>
                <option value="N" selected>N</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="url" class="form-label">URL</label>
              <input
                type="text"
                id="url"
                class="form-control"
                v-model="menu.url"
                placeholder=""
              />
            </div>
            <div class="mb-3">
              <label for="routerNm" class="form-label">라우터명</label>
              <input
                type="text"
                id="routerNm"
                class="form-control"
                v-model="menu.routerNm"
                placeholder=""
              />
            </div>
            <div class="text-sm-end">
              <button
                type="button"
                id="delBtn"
                class="btn btn btn-secondary mb-2 btn-sm"
                v-show="delBtnShow"
                @click="goDelMenu()"
              >
                <i class="mdi mdi-trash-can me-2"></i> 삭제
              </button>
              <button
                type="button"
                id="modBtn"
                class="btn btn btn-danger mb-2 btn-sm"
                v-show="modBtnShow"
                @click="goRegMenu()"
              >
                <i class="mdi mdi-plus-circle me-2"></i> 수정
              </button>
              <button
                type="button"
                id="addBtn"
                class="btn btn btn-danger mb-2 btn-sm"
                v-show="addBtnShow"
                @click="goRegMenu()"
              >
                <i class="mdi mdi-plus-circle me-2"></i> 추가
              </button>
            </div>
          </div>
          <!-- end col-->
        </div>
      </div>
    </div>
  </div>
  <!-- container -->
</template>

<script>
import axiosUtil from "../../../../util/request";
import jstree from "../../../../assets/js/jstree/dist/jstree.js";
import "../../../../assets/js/jstree/dist/themes/default/style.min.css";

export default {
  components: {
    jstree,
  },
  name: "menuLst",
  data: function () {
    return {
      menuTreeList: [],
      menu: {},
      mode: "", // GET, DEL, MOD, REG
      treeData: [],
      selectedMenu: {},
      delBtnShow: false,
      modBtnShow: false,
      addBtnShow: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init: async function () {
      console.log("menu.vue init");
      this.selectedMenu = this.menu;
      this.menu = new Object();

      this.menuTreeList = await this.goGetMenuList(this.menu);
      this.makeNode();
      this.makeTree();
    },
    /***
     *메뉴 목록을 조회한다.
     */
    goGetMenuList: async function (menu) {
      alert("11");

      let tmpMenuList = null;
      const test = await axiosUtil({
        method: "POST",
        url: "/menu/lst",
        data: menu,
      }).then((data) => {
        var resultObj = data.resultObj;
        if (resultObj != null && resultObj.length > 0) {
          tmpMenuList = data.resultObj;
        }
      });
      return tmpMenuList;
    },
    goRegMenu: function () {
      if (this.menu.upMenuNum == null) {
        this.menu.upMenuNum = -1;
      }

      axiosUtil({
        method: "POST",
        url: "/menu/reg",
        data: this.menu,
      }).then((data) => {
        this.init();
      });
    },
    goDelMenu: function () {
      if (confirm("정말로 삭제하시겠습니까?")) {
        this.menu.delYn = "Y";
        axiosUtil({
          method: "POST",
          url: "/menu/reg",
          data: this.menu,
        }).then((data) => {
          this.init();
        });
      }
    },
    goMenu: function () {
      this.$router.go();
    },
    makeNode: function () {
      var treeNodes = [];
      this.menuTreeList.forEach((item) => {
        var node = {};
        node.id = item.menuNum;
        item.upMenuNum == -1
          ? (node.parent = "#")
          : (node.parent = item.upMenuNum);
        node.text = item.menuNm;
        treeNodes.push(node);
      });
      this.treeData = treeNodes;
    },
    getMenu() {
      this.menu = {};
      let parentMenu = {};
      let selectedNode = $("#tree").jstree(true).get_selected("full")[0];
      this.menuTreeList.forEach((item) => {
        if (item.menuNum === selectedNode.id) {
          this.menu = item;
        }
        if (item.menuNum == selectedNode.parent) {
          parentMenu = item;
        }
      });

      this.menu.upMenuNum = parentMenu.menuNum;
      this.menu.upMenuNm = parentMenu.menuNm;

      console.log(this.menu);

      if (this.menu.menuNum != null) {
        this.delBtnShow = true;
        this.modBtnShow = true;
        this.addBtnShow = false;
      } else {
        this.delBtnShow = false;
        this.modBtnShow = false;
        this.addBtnShow = true;
        this.menu.useYn = "Y";
      }
    },
    makeTree() {
      $("#tree").jstree({
        core: {
          check_callback: true,
          data: this.treeData,
        },
      });

      $("#tree").jstree(true).settings.core.data = this.treeData;
      $("#tree").jstree(true).refresh();

      //처음 시작 시 모든 노드가 확장된 상태로 유지하기 위해 사용. 설정값 찾으면 삭제 필요
      setTimeout(function () {
        $("#tree").jstree("open_all");
      }, 200);
    },
    addNode: function () {
      var ref = $("#tree").jstree(true);
      var sel = ref.get_selected();
      var newNode = false;

      if (!sel.length) {
        return false;
      }
      sel = sel[0];
      newNode = ref.create_node(sel, { text: "새로운 메뉴" });
      if (newNode) {
        ref.open_node(sel);
        ref.deselect_all();
        ref.select_node(newNode);
        this.menu = {};
        this.menu.upMenuNum = sel;
        this.menu.upMenuNm = ref.get_text(sel);
        this.menu.useYn = "Y";
      }
    },
  },
  filters: {},
  mounted() {
    $("#tree").on("select_node.jstree Event", this.getMenu);
  },
  computed: {},
};
</script>
