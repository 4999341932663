<template>
<!-- main-banner(Swiper) -->
    <div class="section">
        <h2 class="sec-tit">지금 뜨는 Keyword</h2>
        <div class="swiper row-swiper keyword-swiper">
            <swiper
                :slides-per-view="2.3"
                :space-between="10"
                :loop="true"
                class="default-slider">
                <swiper-slide v-for="n in bnrLst" :key="n"> 
                    <a href="#n" class="item list-box">
                        <div class="list-thumb"><img :src="`${baseUrl}/file/getImg/${n.imgFile.fileNum}`" alt=""></div>
                        <div class="list-info">
                            <strong class="tit" v-html="n.bnrNm"></strong>
                            <div class="tag-box">
                                <span class="tag">{{ n.cntn }}</span>
                            </div>
                        </div>
                    </a>    
                </swiper-slide>
            </swiper>
        </div>
  </div>
  <!-- //main-banner -->
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";

import axiosUtil from '@/util/request';
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name : 'custBnr',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
          bnrLst:[]
         ,bnr : {}
         ,baseUrl : process.env.VUE_APP_SERVER_URL
         ,pagination: {
                type: "fraction",
         }
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
            this.bnr = new Object();
            this.bnr.bnrCode = '1011'
            this.bnrLst =  await this.goGetBnrList(this.bnr);
        },
        /***
         *게시판 리스트를 조회를 한다. 
         */
        goGetBnrList : async function(bnr){
            let tmpBnrList = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/lst', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpBnrList = data.resultObj;
                }
            })
            return tmpBnrList;
        }, 
  }
  
}
</script>