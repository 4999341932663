<template>
    <div class="wrap">
        <section class="view-wrap">
            <div class="evt-info-wrap">
                <div class="evt-info">
                    <strong class="tit">{{ arti.ttl }} </strong>
                    <span class="date">{{ StringUtil.timestampToDate(arti.regDt) }}</span>
                    <div class="desc" v-html="arti.cntn"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//components
import StringUtil from '@/util/StringUtil.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();



//게시판 정보 처리 시작
const board = ref({});
const arti = ref({});

async function goGetBoard(){
    let tmpBoard = {};
    await $axios({
        method: 'POST',
        url: '/board/get',
        data: board.value
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoard = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoard;
}

async function goGetArti(){
    let tmpArti;
    await $axios({
        method: 'POST',
        url: '/arti/get',
        data: arti.value
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpArti = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })

    return tmpArti;
}

onMounted( async () => {
    arti.value.artiNum = route.params.artiNum;
    board.value.boardNum = route.params.boardNum;

    board.value = await goGetBoard();
    arti.value = await goGetArti();
})
//유저 정보 처리 끝


</script>