<template>

  <div class="wrapper">
      <!-- ========== Left Sidebar Start ========== -->
      <div class="leftside-menu">

          <!-- LOGO -->
          <a href="index.html" class="logo text-center logo-light">
              <span class="logo-lg">
                  <img src="/assets/images/logo.png" alt="" height="16">
              </span>
              <span class="logo-sm">
                  <img src="/assets/images/logo_sm.png" alt="" height="16">
              </span>
          </a>

          <!-- LOGO -->
          <a href="index.html" class="logo text-center logo-dark">
              <span class="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height="16">
              </span>
              <span class="logo-sm">
                  <img src="/assets/images/logo_sm_dark.png" alt="" height="16">
              </span>
          </a>

          <div class="h-100" id="leftside-menu-container" data-simplebar>

              <!--- Sidemenu -->
              <ul class="side-nav">
                  <li class="side-nav-item" v-for="(menu, index) in menuLst" :key="index"  >
                      <menuNav :menu="menu" />
                  </li>
              </ul>
              <!-- End Sidebar -->

              <div class="clearfix"></div>

          </div>
          <!-- Sidebar -left -->

      </div>
      <!-- Left Sidebar End -->

      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="content-page">
          <div class="content">
              <!-- Topbar Start -->
              <div class="navbar-custom">
                  <ul class="list-unstyled topbar-menu float-end mb-0">
                      <li class="dropdown notification-list d-lg-none">
                          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                              <i class="dripicons-search noti-icon"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                              <form class="p-3">
                                  <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                              </form>
                          </div>
                      </li>
                      <li class="dropdown notification-list topbar-dropdown">
                          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                              <img src="/assets/images/flags/us.jpg" alt="user-image" class="me-0 me-sm-1" height="12"> 
                              <span class="align-middle d-none d-sm-inline-block">English</span> <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <img src="/assets/images/flags/germany.jpg" alt="user-image" class="me-1" height="12"> <span class="align-middle">German</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <img src="/assets/images/flags/italy.jpg" alt="user-image" class="me-1" height="12"> <span class="align-middle">Italian</span>
                              </a>
          
                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <img src="/assets/images/flags/spain.jpg" alt="user-image" class="me-1" height="12"> <span class="align-middle">Spanish</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <img src="/assets/images/flags/russia.jpg" alt="user-image" class="me-1" height="12"> <span class="align-middle">Russian</span>
                              </a>

                          </div>
                      </li>

                      <li class="dropdown notification-list">
                          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                              <i class="dripicons-bell noti-icon"></i>
                              <span class="noti-icon-badge"></span>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">

                              <!-- item-->
                              <div class="dropdown-item noti-title">
                                  <h5 class="m-0">
                                      <span class="float-end">
                                          <a href="javascript: void(0);" class="text-dark">
                                              <small>Clear All</small>
                                          </a>
                                      </span>Notification
                                  </h5>
                              </div>

                              <div style="max-height: 230px;" data-simplebar>
                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon bg-primary">
                                          <i class="mdi mdi-comment-account-outline"></i>
                                      </div>
                                      <p class="notify-details">Caleb Flakelar commented on Admin
                                          <small class="text-muted">1 min ago</small>
                                      </p>
                                  </a>

                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon bg-info">
                                          <i class="mdi mdi-account-plus"></i>
                                      </div>
                                      <p class="notify-details">New user registered.
                                          <small class="text-muted">5 hours ago</small>
                                      </p>
                                  </a>

                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon">
                                          <img src="/assets/images/users/avatar-2.jpg" class="img-fluid rounded-circle" alt="" /> </div>
                                      <p class="notify-details">Cristina Pride</p>
                                      <p class="text-muted mb-0 user-msg">
                                          <small>Hi, How are you? What about our next meeting</small>
                                      </p>
                                  </a>

                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon bg-primary">
                                          <i class="mdi mdi-comment-account-outline"></i>
                                      </div>
                                      <p class="notify-details">Caleb Flakelar commented on Admin
                                          <small class="text-muted">4 days ago</small>
                                      </p>
                                  </a>

                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon">
                                          <img src="/assets/images/users/avatar-4.jpg" class="img-fluid rounded-circle" alt="" /> </div>
                                      <p class="notify-details">Karen Robinson</p>
                                      <p class="text-muted mb-0 user-msg">
                                          <small>Wow ! this admin looks good and awesome design</small>
                                      </p>
                                  </a>

                                  <!-- item-->
                                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                                      <div class="notify-icon bg-info">
                                          <i class="mdi mdi-heart"></i>
                                      </div>
                                      <p class="notify-details">Carlos Crouch liked
                                          <b>Admin</b>
                                          <small class="text-muted">13 days ago</small>
                                      </p>
                                  </a>
                              </div>

                              <!-- All-->
                              <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                                  View All
                              </a>

                          </div>
                      </li>

                      <li class="dropdown notification-list d-none d-sm-inline-block">
                          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                              <i class="dripicons-view-apps noti-icon"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">

                              <div class="p-2">
                                  <div class="row g-0">
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/slack.png" alt="slack">
                                              <span>Slack</span>
                                          </a>
                                      </div>
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/github.png" alt="Github">
                                              <span>GitHub</span>
                                          </a>
                                      </div>
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/dribbble.png" alt="dribbble">
                                              <span>Dribbble</span>
                                          </a>
                                      </div>
                                  </div>

                                  <div class="row g-0">
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/bitbucket.png" alt="bitbucket">
                                              <span>Bitbucket</span>
                                          </a>
                                      </div>
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/dropbox.png" alt="dropbox">
                                              <span>Dropbox</span>
                                          </a>
                                      </div>
                                      <div class="col">
                                          <a class="dropdown-icon-item" href="#">
                                              <img src="/assets/images/brands/g-suite.png" alt="G Suite">
                                              <span>G Suite</span>
                                          </a>
                                      </div>
                                  </div> <!-- end row-->
                              </div>

                          </div>
                      </li>

                      <li class="notification-list">
                          <a class="nav-link end-bar-toggle" href="javascript: void(0);">
                              <i class="dripicons-gear noti-icon"></i>
                          </a>
                      </li>

                      <li class="dropdown notification-list">
                          <a class="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                              aria-expanded="false">
                              <span class="account-user-avatar"> 
                                  <img src="/assets/images/users/avatar-1.jpg" alt="user-image" class="rounded-circle">
                              </span>
                              <span>
                                  <span class="account-user-name">Dominic Keller</span>
                                  <span class="account-position">Founder</span>
                              </span>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                              <!-- item-->
                              <div class=" dropdown-header noti-title">
                                  <h6 class="text-overflow m-0">Welcome !</h6>
                              </div>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <i class="mdi mdi-account-circle me-1"></i>
                                  <span>My Account</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <i class="mdi mdi-account-edit me-1"></i>
                                  <span>Settings</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <i class="mdi mdi-lifebuoy me-1"></i>
                                  <span>Support</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <i class="mdi mdi-lock-outline me-1"></i>
                                  <span>Lock Screen</span>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <i class="mdi mdi-logout me-1"></i>
                                  <span>Logout</span>
                              </a>
                          </div>
                      </li>

                  </ul>
                  <button class="button-menu-mobile open-left">
                      <i class="mdi mdi-menu"></i>
                  </button>
                  <div class="app-search dropdown d-none d-lg-block">
                      <form>
                          <div class="input-group">
                              <input type="text" class="form-control dropdown-toggle"  placeholder="Search..." id="top-search">
                              <span class="mdi mdi-magnify search-icon"></span>
                              <button class="input-group-text btn-primary" type="submit">Search</button>
                          </div>
                      </form>

                      <div class="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
                          <!-- item-->
                          <div class="dropdown-header noti-title">
                              <h5 class="text-overflow mb-2">Found <span class="text-danger">17</span> results</h5>
                          </div>

                          <!-- item-->
                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                              <i class="uil-notes font-16 me-1"></i>
                              <span>Analytics Report</span>
                          </a>

                          <!-- item-->
                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                              <i class="uil-life-ring font-16 me-1"></i>
                              <span>How can I help you?</span>
                          </a>

                          <!-- item-->
                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                              <i class="uil-cog font-16 me-1"></i>
                              <span>User profile settings</span>
                          </a>

                          <!-- item-->
                          <div class="dropdown-header noti-title">
                              <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
                          </div>

                          <div class="notification-list">
                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <div class="d-flex">
                                      <img class="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-2.jpg" alt="Generic placeholder image" height="32">
                                      <div class="w-100">
                                          <h5 class="m-0 font-14">Erwin Brown</h5>
                                          <span class="font-12 mb-0">UI Designer</span>
                                      </div>
                                  </div>
                              </a>

                              <!-- item-->
                              <a href="javascript:void(0);" class="dropdown-item notify-item">
                                  <div class="d-flex">
                                      <img class="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height="32">
                                      <div class="w-100">
                                          <h5 class="m-0 font-14">Jacob Deo</h5>
                                          <span class="font-12 mb-0">Developer</span>
                                      </div>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- end Topbar -->


              <router-view></router-view>

          </div> <!-- content -->

          <!-- Footer Start -->
          <footer class="footer">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-md-6">
© Hyper - Coderthemes.com
                      </div>
                      <div class="col-md-6">
                          <div class="text-md-end footer-links d-none d-md-block">
                              <a href="javascript: void(0);">About</a>
                              <a href="javascript: void(0);">Support</a>
                              <a href="javascript: void(0);">Contact Us</a>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
          <!-- end Footer -->

      </div>

      <!-- ============================================================== -->
      <!-- End Page content -->
      <!-- ============================================================== -->


  </div>

</template>

<script>
 import axiosUtil from '../../../util/request';
import menuNav from '../../admin/common/menuNav.vue';

export default {
  components: { menuNav },
  name: 'structure',
  data : function(){
        return {
            menuLst:[]
        }
  }, 
  created() { 
    this.init();
  },
  methods:{
    init : async function(){
      let tmpMenuLst = await this.getMenuList();
      if( tmpMenuLst != null && tmpMenuLst.length > 0 ){
          
          this.menuLst = this.makeMenu(tmpMenuLst);
      }
    },
    getMenuList : async function(){
        let menuLst = new Object();
        const test = await axiosUtil({
            method: 'POST',
            url: '/mycafemenu/treeLst',
            }).then((data) => {
                console.log(data);
                var resultObj =  data.resultObj;
                if( resultObj != null  ){
                    menuLst = data.resultObj;
            }
        })
        return menuLst;
    }, 
    makeMenu : function(menuLst) {
        var tmpMenuLst = [];
        menuLst.forEach((item) => {
            var menu = item;
            if(menu.lvl === 1){
                if(menu.leafYn != 'Y'){ //마지막 메뉴가 아니라면 하위메뉴가 존재하므로 하위메뉴 정보를 가져오도록 함수를 호출한다.
                    menu.subMenuLst = this.makeSubMenu(menu.menuNum, menu.lvl+1, menuLst);
                }
                tmpMenuLst.push(menu);
            }
        });
        return tmpMenuLst;
    },
    makeSubMenu : function(paramMenuNum, paramLvl, menuLst){
        var tmpMenuLst = [];
        menuLst.forEach((item) =>{
            var menu = item;
            if(paramMenuNum === menu.upMenuNum && paramLvl === menu.lvl){
                if(item.leafYn != 'Y'){ //서브메뉴의 서브메뉴를 찾기 위한 재귀함수 호출
                    menu.subMenuLst = this.makeSubMenu(menu.menuNum, menuLst);
                }
            tmpMenuLst.push(menu)
            }
        });
        return tmpMenuLst;
    },
  }


}
</script>
