<template>
    <div class="wrap">
        <header>
            <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
            <em class="txt-cafe">내 정보 수정</em>
        </header>
        <div class="account-wrap">
            <h2 class="blind">정보입력</h2>
            <div class="account-pic">
                <span class="pic-box"><img src="" alt="" ref="custImg" ></span>
                <button type="button" class="btn-pic-add" @click="clickCustFile"><i class="blind">프로필 사진 등록</i></button>
                <input type="file" id="custFile" ref="custFile" class="blind" @change="custFileChg" accept="image/*">
            </div>
            <div class="frm-box line-box">
                <label for="user-id">아이디</label>
                <span class="frm-flex">
                    <input type="text" id="user-id" v-model="user.id" tite="아이디" readonly>
                    <input type="hidden" v-model="user.custNm" />
                </span>
            </div>
            <div class="frm-box line-box" v-show="pwChgShow">
                <label for="user-pwd">비밀번호</label>
                <span class="frm-flex">
                    <input type="password" id="user-pwd" :value="user.id" tite="비밀번호" readonly>
                    <button type="button" class="btn-line" @click="clickOpenLayer" >변경</button>
                </span>
            </div>
            <div class="frm-box line-box">
                <label for="user-nick">닉네임</label>
                <span class="frm-flex">
                    <input type="text" id="user-nick" v-model="user.nickNm" tite="닉네임" @keyup="resetNickNmChkObj">
                    <button type="button" class="btn-line" @click="goCheckNickNmDup" >중복 확인</button>
                </span>
                <p class="err-txt" v-show="!nickNmChkObj.status" >{{ nickNmChkObj.message }}</p>
            </div>
            <div class="frm-box line-box">
                <label for="user-phone">휴대폰 번호</label>
                <span class="frm-flex frm-phone">
                    <div class="select-box">
                        <select title="휴대폰 앞자리" v-model="user.cpNum1">
                            <option selected>010</option>
                        </select>
                    </div>
                    <input type="tel" id="user-phone2" v-model="user.cpNum2" tite="휴대폰 번호">
                    <input type="tel" id="user-phone3" v-model="user.cpNum3" tite="휴대폰 번호">
                    <!-- <button type="button" class="btn-line">인증</button> -->
                </span>
            </div>
            <div class="frm-box">
                <span class="label">성별</span>
                <span class="frm-flex">
                    <span class="frm-chk">
                        <input type="radio" id="sex-female" name="frm-sex" value="1" v-model="user.gndrCode">
                        <label for="sex-female">여자</label>                 
                    </span>
                    <span class="frm-chk">
                        <input type="radio" id="sex-male" name="frm-sex" value="2" v-model="user.gndrCode">
                        <label for="sex-male">남자</label>
                    </span>
                </span>
            </div>
            <div class="frm-box line-box center">
                <label for="birth">생년월일</label>
                <input type="text" id="user-birth" v-model="user.bithYmd" tite="생년월일" class="datepicker" maxlength="10" @keyup="this.goCheckBithYmd">
            </div>
            <button type="button" class="btn-full" @click="goModUser">수정</button>
            <div class="btn-group">
                <button type="button" class="btn-txt">회원탈퇴</button>
                <button type="button" class="btn-txt" @click="goLogout" >로그아웃</button>
            </div>
        </div>
    </div>
    <pwChg name="pwChg" ></pwChg>

</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

//components
import pwChg from "@/page/main/views/common/layer.vue";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosFile = proxy.$axiosFile;

const router = useRouter()

//vuex 설정
const store = useStore();
const layerLst = computed(() => store.state.layer.layerLst);

//레이어 처리
function clickOpenLayer(){
    const layer = {};
    layer.show = true;
    layer.name = 'pwChg';
    store.dispatch('layer/setLayerLst', layer)
}

//유저 정보 처리 시작
const user = ref({});
const pwChgShow = ref(true);


const nickNmChkObj = ref({
                        status: true,
                        message: '',
                        oldNickNm : '',
                        });


const nickNmChkMessage = {
    INIT : '닉네임 중복 확인이 필요합니다.',
    FAIL : '해당 닉네임을 사용하실 수 없습니다.',
    RESET : '닉네임 중복 확인을 다시 해주시기 바랍니다.',
}

async function goGetCurrentUser(){
    await $axios({
        method: 'POST',
        url: '/cust/getCurrent',
    }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null  ){
                //user.value = resultObj;
                user.value.custNum = resultObj.custNum;
                user.value.id = resultObj.id;
                user.value.nickNm = resultObj.nickNm;
                user.value.cpNum1 = resultObj.cpNum1;
                user.value.cpNum2 = resultObj.cpNum2;
                user.value.cpNum3 = resultObj.cpNum3;
                user.value.gndrCode = resultObj.gndrCode;
                user.value.bithYmd = resultObj.bithYmd;
                user.value.custFileModel = resultObj.custFileModel;
                nickNmChkObj.value.oldNickNm = resultObj.nickNm;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
}

//생년월일 정규식
function goCheckBithYmd() {
    var formatNum = user.value.bithYmd;

    // 공백제거
    formatNum = formatNum.replace(/\s/gi, "");
    try{
        if(formatNum.length == 8) {
            formatNum = formatNum.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }
    } catch(e) {
        formatNum = formatNum;
    }
    user.value.bithYmd = formatNum;
};

async function goModUser(){
    //필수값 체크
    if(!user.value.nickNm){
        alert('닉네임을 체크해주세요. ');
        return false;
    }

    if(!nickNmChkObj.value.status){
        alert('닉네임 중복 확인이 필요합니다. ');
        return false;
    }

    if(!user.value.cpNum2 || !user.value.cpNum1 || !user.value.cpNum3){
        alert('휴대폰 번호를 체크해주세요. ');
        return false;
    }

    user.value.cpNum = user.value.cpNum1 + '-' + user.value.cpNum2 + '-' + user.value.cpNum3;

    await $axios({
        method: 'POST',
        data: user.value,
        url: '/cust/mod',
        }).then((data) => {
            alert("회원정보 수정이 완료되었습니다. ");
            goGetCurrentUser();
        }).catch((error) => {
            alert("에러 발생");
        })

}


function resetNickNmChkObj(){
    if(nickNmChkObj.value.oldNickNm == user.value.nickNm){
        nickNmChkObj.value.status = true;
    }else{
        nickNmChkObj.value.status = false;
        nickNmChkObj.value.message = nickNmChkMessage.RESET;
    }
}


async function goCheckNickNmDup(){
    if(nickNmChkObj.value.oldNickNm == user.value.nickNm){
        return false;
    }

    let tmpUser = new Object();
    tmpUser.nickNm = user.value.nickNm;
    tmpUser = await goGetUser(tmpUser);
    tmpUser == null ? nickNmChkObj.value.status = true : nickNmChkObj.value.status = false;
    nickNmChkObj.value.message = nickNmChkMessage.FAIL;
}

async function goGetUser(user){
    let tmpUser = new Object();
    const test = await $axios({
        method: 'POST',
        data: user,
        url: '/cust/get',
        }).then((data) => {
            tmpUser =  data.resultObj;
        }).catch((error) => {
            alert("에러 발생");
        })

    return tmpUser;
}

async function goLogout(){
    localStorage.removeItem("authorization");

    router.push({
    name: 'main',
    }).then((() =>window.scrollTo(0,0) ));
}

onMounted( async () => {
    await goGetCurrentUser();
    await getProfileImg();
    //간편로그인의 경우 비밀번호 변경 미노출
    if(store.state.cust.cust.loginGbnCode != '6006') pwChgShow.value = false;
})
//유저 정보 처리 끝


//이미지 업로드

const custFile = ref(null);
const custImg = ref(null);

function clickCustFile(){
    custFile.value.click();
}


async function custFileChg(e) {

    let fileDOM = e.target;
    let newFile = fileDOM.cloneNode(true);
    //let imageSrc = URL.createObjectURL(newFile.files[0]);

    // 파일 업로드 
    let formData = new FormData();
    formData.append("file", newFile.files[0]);
    formData.append("num", user.value.custNum);

    await $axios({
        method: "POST",
        url: '/custFile/reg',
        data: formData
    }).then((res) => {
        if( res != null && res.resultObj != null ){
            user.value.custFileModel = res.resultObj[0];
            getProfileImg();
        }
    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });
}

async function getProfileImg(){
    await $axiosFile({
        method: 'GET',
        url: '/custFile/img/profile/100x100/' + user.value.custFileModel.custFileNum,
    }).then((response) => {
        let imgUrl = URL.createObjectURL(response.data)
                
        custImg.value.src = imgUrl
    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });
}

</script>