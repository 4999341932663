<template>
        <router-view></router-view>

        <cust-footer></cust-footer>
</template>

<script setup>
import { useStore } from "vuex";

import custFooter from "@/page/main/views/main/custFooter.vue";



//vuex 설정
const store = useStore();
//const layerLst = computed(() => store.state.location.layerLst);


navigator.geolocation.getCurrentPosition(
  function(position) {
  console.log("위도 : " + position.coords.latitude);
  console.log("경도 : " + position.coords.longitude);

  store.dispatch('location/setLocation', position.coords)
  }, 
);

</script>