import { createRouter, createWebHistory } from 'vue-router'

import baseView from '@/page/main/views/baseView.vue';
import main from '@/page/main/views/main/main.vue';
import alrm from '@/page/main/views/alrm/lst.vue';
import cntn from '@/page/main/views/cntn/cntn.vue';
import srch from '@/page/main/views/srch/srch.vue';
import cafe from '@/page/main/views/cafe/cafe.vue';
import cafeLst from '@/page/main/views/cafe/lst.vue';
import cafeDetail from '@/page/main/views/cafe/detail.vue';
import cafeInfo from '@/page/main/views/cafe/detail/info.vue';
import cafeMenu from '@/page/main/views/cafe/detail/menu.vue';
import cafeRvw from '@/page/main/views/cafe/detail/rvw.vue';
import cafeRvwWrite from '@/page/main/views/cafe/rvwWrite.vue';
import cafeNtc from '@/page/main/views/cafe/detail/ntc.vue';
import map from '@/page/main/views/map/map.vue';
import mypage from '@/page/main/views/mypage/mypage.vue';
import tncChk from '@/page/main/views/mypage/tncChk.vue';
import sgnUpInfo from '@/page/main/views/mypage/sgnUpInfo.vue';
import sgnUpComp from '@/page/main/views/mypage/sgnUpComp.vue';
import myinfo from '@/page/main/views/mypage/myinfo.vue';
import favCafe from '@/page/main/views/mypage/favCafe.vue';

import board from '@/page/main/views/board/board.vue';
import boardLst from '@/page/main/views/board/lst.vue';
import boardDetail from '@/page/main/views/board/get.vue';

import login from '@/page/main/views/mypage/login.vue';
import naverLoginCallback from '@/page/main/views/mypage/smpl/naverLoginCallback.vue';
import kakaoLoginCallback from '@/page/main/views/mypage/smpl/kakaoLoginCallback.vue';
import googleLoginCallback from '@/page/main/views/mypage/smpl/googleLoginCallback.vue';

import axiosHelper from "@/util/axiosHelper";
import store from "@/store/store.js";


const requireAuth = () => async (to, from, next) => {
  let result = false;
  const test = await axiosHelper({
    method: 'POST',
    url: '/auth/chkViewUrlAuth',
    data: { 'url': to.path },
  }).then((data) => {

    if (data.resultYn == 'Y') {
      next();
    } else {
      next('/login');
    }
  })
};

const routes = [
  {
    path: '/',
    name: 'baseView',
    component: baseView,
    children: [
      {
        path: '/',
        alias: ['/main', '/index', '/', ''],
        name: 'main',
        component: main,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: 'alrm',
        name: 'alrm',
        component: alrm,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: 'cntn',
        name: 'cntn',
        component: cntn,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/board',
        name: 'board',
        component: board,
        props: true,
        //beforeEnter: requireAuth() 
        children: [
          {
            path: 'lst/:boardNum',
            name: 'boardLst',
            component: boardLst,
            props: true,
            //beforeEnter: requireAuth() 
          },
          {
            path: '/board/get/:boardNum/:artiNum',
            name: 'boardDetail',
            component: boardDetail,
            props: true,
            //beforeEnter: requireAuth() 
          },
        ]
      },
      {
        path: '/srch',
        name: 'srch',
        component: srch,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/cafe',
        name: 'cafe',
        component: cafe,
        props: true,
        //beforeEnter: requireAuth() 
        children: [
          {
            path: 'lst',
            name: 'cafeLst',
            component: cafeLst,
            props: true,
            beforeEnter: requireAuth()
          },
          {
            path: 'detail/:cafeNum',
            name: 'cafeDetail',
            component: cafeDetail,
            props: true,
            beforeEnter: requireAuth(),
            children: [
              {
                path: 'info',
                name: 'cafeInfo',
                component: cafeInfo,
                props: true,
                //beforeEnter: requireAuth() 
              },
              {
                path: 'menu',
                name: 'cafeMenu',
                component: cafeMenu,
                props: true,
                //beforeEnter: requireAuth() 
              },
              {
                path: 'rvw',
                name: 'cafeRvw',
                component: cafeRvw,
                props: true,
                //beforeEnter: requireAuth() 
              },
              {
                path: 'ntc',
                name: 'cafeNtc',
                component: cafeNtc,
                props: true,
                //beforeEnter: requireAuth() 
              },
            ]
          },
        ]
      },
      {
        path: 'cafe/rvw/write',
        name: 'cafeRvwWrite',
        component: cafeRvwWrite,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/map',
        name: 'map',
        component: map,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/mypage',
        name: 'mypage',
        component: mypage,
        props: true,
        beforeEnter: requireAuth(),
        children: [
        ]
      },
      {
        path: '/myinfo',
        name: 'myinfo',
        component: myinfo,
        props: true,
        beforeEnter: requireAuth()
      },
      {
        path: '/tncChk',
        name: 'tncChk',
        component: tncChk,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/sgnUpInfo',
        name: 'sgnUpInfo',
        component: sgnUpInfo,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/sgnUpComp',
        name: 'sgnUpComp',
        component: sgnUpComp,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/favCafe',
        name: 'favCafe',
        component: favCafe,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/login',
        name: 'login',
        component: login,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/login/naver',
        name: 'loginNaver',
        component: naverLoginCallback,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/login/kakao',
        name: 'loginKakao',
        component: kakaoLoginCallback,
        props: true,
        //beforeEnter: requireAuth() 
      },
      {
        path: '/login/google',
        name: 'loginGoogle',
        component: googleLoginCallback,
        props: true,
        //beforeEnter: requireAuth() 
      },
    ]
    //beforeEnter: requireAuth() 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //base : process.env.BASE_URL,
  routes
})

export default router
