<template>
<!-- main-banner(Swiper) -->
<div class="swiper main-swiper">
    <swiper
        :slides-per-view="1"
        :space-between="0"
        :loop="true"
        :pagination="pagination"
        class="default-slider">
        <swiper-slide v-for="n in bnrLst" :key="n"> 
            <a href="#n" class="item">
                <img :src="`${baseUrl}/file/getImg/${n.imgFile.fileNum}`">
                <div class="text">
                    <strong class="tit">{{ n.bnrNm }}</strong>
                    <p class="desc">{{ n.cntn }}</p>
                </div>
            </a>    
        </swiper-slide>
    </swiper>
  </div>
  <!-- //main-banner -->
</template>

<script>

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import SwiperCore, { Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

import axiosUtil from '@/util/request';
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name : 'custBnr',
    components: {
        Swiper,
        SwiperSlide,
    },
    data : function() {
        return {
          bnrLst:[]
         ,bnr : {}
         ,baseUrl : process.env.VUE_APP_SERVER_URL
         ,pagination: {
                type: "fraction",
         }
        }
    },
    created() { 
        this.init();
    },
    methods :{
        init : async function(){
            this.bnr = new Object();
            this.bnr.bnrCode = '1010';
            this.bnrLst =  await this.goGetBnrList(this.bnr);
        },
        /***
         *게시판 리스트를 조회를 한다. 
         */
        goGetBnrList : async function(bnr){
            let tmpBnrList = null
            const test = await axiosUtil({
                method: 'POST',
                url: '/bnr/lst', 
                data : bnr
             }).then((data) => {
                var resultObj =  data.resultObj;
                if( resultObj != null && resultObj.length > 0 ){
                    tmpBnrList = data.resultObj;
                }
            })
            return tmpBnrList;
        }, 
  }
  
}
</script>