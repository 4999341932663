<template>
    <!-- Start Content-->
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0" style="display:none" >
                            <li class="breadcrumb-item"><a href="javascript: void(0);">Hyper</a></li>
                            <li class="breadcrumb-item"><a href="javascript: void(0);">eCommerce</a></li>
                            <li class="breadcrumb-item active">Checkout</li>
                        </ol>
                    </div>
                    <h4 class="page-title">사용자등록</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- Steps Information -->
                        <div class="tab-content">
                            <!-- Billing Content-->
                            <div class="tab-pane show active" id="billing-information">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <!--
                                            <div class="row">
                                                <div class="col align-self-auto"  >
                                                    <img src="https://post-phinf.pstatic.net/MjAxODAzMTVfMTg5/MDAxNTIxMTEwODcyNDAw.VydowXsV01QXlULeVB0kwkwyS30umKOBsDQH0wRIrq4g.twzyKxF6IfxXxxlzsDIZmUtI43RixX0ypWW1l_1Ru0Yg.JPEG/SmartSelectImage_2018-03-15-19-42-18.jpg?type=w1200" class="img-fluid" style="max-width: 280px;" alt="Product-img">
                                                </div>
                                            </div> 
                                            --> 
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label for="billing-first-name" class="form-label">아이디</label>
                                                        <input class="form-control" :value="id" @input="id=$event.target.value" type="text" placeholder="아이디를 등록하세요." required />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label for="billing-last-name" class="form-label">패스워드</label>
                                                        <input class="form-control"  :value="pw" @input="pw=$event.target.value"  type="password" placeholder="패스워드 등록" />
                                                    </div>
                                                </div>
                                            </div> <!-- end row -->
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label for="billing-email-address" class="form-label">이름</label>
                                                        <input class="form-control" :value="nm" @input="nm=$event.target.value"  type="text" placeholder="이름을 등록하세요."  />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label for="billing-phone" class="form-label">권한분류</label>
                                                        <select class="form-select" :value="admnGbnCd" @input="admnGbnCd=$event.target.value"  id="example-select">
                                                            <option value="0" >선택</option>
                                                            <option value="1" >관리자</option>
                                                            <option value="2" >일반</option>
                                                            <option value="3" >임시</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> <!-- end row -->
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">  
                                                        <label for="billing-phone" class="form-label">이메일</label>
                                                        <input class="form-control"  :value="email" @input="email=$event.target.value"  type="email" placeholder="ex) sample@maxmini.com" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-2">  
                                                        <label for="billing-phone" class="form-label">전화1</label>
                                                        <input class="form-control" :value="cpNum1" @input="cpNum1=$event.target.value"  type="email" placeholder="전화번호" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-2">  
                                                        <label for="billing-phone" class="form-label">전화2</label>
                                                        <input class="form-control" :value="cpNum2" @input="cpNum2=$event.target.value"  type="email" placeholder="전화번호" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-2">  
                                                        <label for="billing-phone" class="form-label">전화3</label>
                                                        <input class="form-control" :value="cpNum3" @input="cpNum3=$event.target.value"  type="email" placeholder="전화번호" />
                                                    </div>
                                                </div>
                                            </div> <!-- end row -->
                                            <div class="row mt-4">
                                               <div class="col-sm-6">
 
                                                </div> <!-- end col -->
                                                <div class="col-sm-6">
                                                    <div class="text-sm-end">
                                                        <a href="#none" @click="goAdmnReg" class="btn btn-danger">등록</a>
                                                    </div>
                                                </div> <!-- end col -->
                                            </div> <!-- end row -->
                                        </form>
                                    </div>         
                                </div> <!-- end row-->
                            </div>
                            <!-- End Billing Information Content-->
                        </div> <!-- end tab content-->
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>
        <!-- end row-->

    </div> <!-- container -->
</template>

<script>
import axiosUtil from '../../../util/axiosUtil';
import cnst from '../../../util/cnst';

export default {
    name: 'join',
    data : function() {
        return {
         id:""
        ,pw:"" 
        ,nm:""  
        ,admnGbnCd : ""  
        ,email : ""
        ,cp : ""
        ,cpNum1 : ""
        ,cpNum2 : ""
        ,cpNum3 : ""
        }
    },
    created() { 
        //init.go();
    },
    methods:{


        goInit : function() {
            const url = '/code/list';
            let data = {
                pCodeNum :15,
            };
            axiosUtil.go(url, data, this.initSuccHndl, this.initErroHndl) ;
            return false; 
        }, 
        initSuccHndl : function(data){
            console.log('suceess');
            console.log(data);
        }, 
        initErroHndl: function(data){
            console.log(data);
        }, 

        goAdmnReg : function (){

            console.log(this.id);
            console.log(this.pw);
            console.log(this.email);
            console.log(this.nm);
            console.log(this.admnGbnCd);
            console.log(this.cp);

            const url = '/admn/reg';
            let data = {
                 id : this.id
                ,pw : this.pw
                ,email : this.email
                ,nm : this.nm
                ,admnGbnCd : this.admnGbnCd
                ,cpNum1  : this.cpNum1
                ,cpNum2  : this.cpNum2
                ,cpNum3  : this.cpNum3
            };
            axiosUtil.go(url, data, this.admnRegSuccHndl, this.admnRegErroHndl) ;

            return false; 
        }, 
        admnRegSuccHndl : function (data){
            console.log('suceess');
            console.log(data);
        }, 
        admnRegErroHndl : function (data){
            console.log(data);
        }, 
    }
}


</script>
