import axios from "axios";
import qs from "qs";
import cnst from '@/util/cnst';

import errorHandler from '@/util/axiosErrorHandler';

const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    Accept: "*/*",
};

const axiosInst = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: headers,
    withCredentials: true,
    transformRequest: [
        (data, headers) => {
            if (!(data instanceof FormData)) {
                var queryString = qs.stringify(data, { arrayFormat: "brackets" });
                data = queryString;
            }
            return data;
        },
        ...axios.defaults.transformRequest,
    ],
});

// axiosWrpr interceptor 설정

axiosInst.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        console.error("interceptors axiosWrpr Failed:", error.config);
        // Do something with axiosWrpr error
        return Promise.reject(error);
    }
);

axiosInst.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// axiosWrpr Wrapper with default success/error actions
const axiosWrpr = function (options) {
    //SuccessHandler
    const onSuccess = function (response) {
        // 전체 모달창 닫기
        try {
            //hideAllModel();
        } catch (e) { }

        return response.data;
    };

    return axiosInst(options).then(onSuccess).catch(errorHandler);
};


export default axiosWrpr;
