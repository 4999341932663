<template>
  <div
    class="docs-wrapper d-flex flex-column flex-row-fluid"
    id="kt_docs_wrapper"
  >
    <!--begin::Header-->
    <div
      id="kt_docs_header"
      class="docs-header align-items-stretch mb-2 mb-lg-10"
    >
      <!--begin::Container-->
      <div class="container">Test</div>
      <!--end::Container-->
    </div>
    <!--end::Header-->
    <!--begin::Content-->
  </div>
</template>

<script>
export default {
  name: "error404",
};
</script>
