<template>
<body>
    <section class="view-wrap">
        <h2 class="blind">MyCafe View</h2>
        <!-- view-banner(Swiper) -->
        <div class="view-banner-wrap">
            <div class="swiper view-swiper">
                <div class="swiper-wrapper">

                    <swiper
                        :slides-per-view="1"
                        :space-between="0"
                        :pagination="pagination"
                        class="default-slider">
                        <swiper-slide v-for="n in imgLst" :key="n"> 
                            <figure class="item">
                                <img :src="`${baseUrl}/file/getImg/${n.fileNum}`">
                            </figure>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div class="view-banner-info">
                <strong class="tit">{{ cafe.cafeNm }}</strong>
                <p class="desc">{{ cafe.intr }} </p>
                <div class="btn-group">
                    <a href="#" role="button" class="btn-navi">위치보기</a>
                    <a :href="'tel:' + cafe.cpNum" role="button" class="btn-call">전화하기</a>
                </div>
            </div>
        </div>
        <!-- //view-banner -->
        <div class="view-info-wrap">
            <!-- Tab links -->
            <div class="tab type-04" id="viewTabs">
                <button class="tablinks active" @click="goTapMenu($event, 'info')">기본정보</button>
                <button class="tablinks" @click="goTapMenu($event, 'menu')">메뉴</button>
                <button class="tablinks" @click="goTapMenu($event, 'rvw')">후기</button>
                <button class="tablinks" @click="goTapMenu($event, 'ntc')">공지/이벤트</button>
            </div>
            <!-- Tab content -->
            <router-view></router-view>
        </div>
    </section>
</body>
</template>

<script setup>

import { ref, reactive, onMounted, onBeforeMount, watch, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import SwiperCore, { Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

// Import Swiper styles
import "swiper/swiper.min.css";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

const cafe = ref({});
const tabLst = ref({});
const imgLst = ref([]);
let pagination = {
    type : "fraction",
}

const baseUrl = process.env.VUE_APP_SERVER_URL;


async function goGetCafe(cafe){
            let tmpCafe = null
            await $axios({
                method: 'POST',
                url: '/cafe/get', 
                data : cafe
             }).then((data) => {
                tmpCafe = data.resultObj.cafe;
            })
            return tmpCafe;
}

function goTapMenu(e, tabname){        
    // tablinks에서 active를 제거한 후 클릭한 event.target으로 active 클래스를 넣어준다
    const tabObj = document.querySelectorAll('.tablinks')
    
    tabObj.forEach(tab => {
        tab.classList.remove('active');
    });

    e.target.classList.add('active');

    router.push({
        path: tabLst.value[tabname]
    });
}

onMounted( async () => {
    cafe.value.cafeNum = route.params.cafeNum
    cafe.value = await goGetCafe(cafe.value)
    tabLst.value = {
        info : '/cafe/detail/' + cafe.value.cafeNum + '/info'
        ,menu : '/cafe/detail/' + cafe.value.cafeNum + '/menu'
        ,rvw : '/cafe/detail/' + cafe.value.cafeNum + '/rvw'
        ,ntc : '/cafe/detail/' + cafe.value.cafeNum + '/ntc'
        }
    imgLst.value = cafe.value.imgFileLst;
});


</script>