export default {
    namespaced: true,
    state: {
        latlot :{
            lat : 0,
            lot : 0,
        },
    },
    getters: {},
    mutations: {
        setLocation(state, payload) {
            state.latlot.lat = payload.latitude;
            state.latlot.lot = payload.longitude;
        },
    },
    actions: {
        setLocation({ state, commit }, payload) {
            commit('setLocation', payload);
        },},
};
