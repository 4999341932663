import cnst from '@/util/cnst';

import mngrRouter from "@/page/mngr/router/mngrRouter.js";
import admnRouter from "@/page/admin/router/adminRouter.js";
import custRouter from "@/page/main/router/index.js";

const errorHandler = function (error) {

    if (error.response.status == 401) {
        var url = error.config.url;
        var userGgnCd = cnst.USER.GBN.GUEST;
        try {
            userGgnCd = error.response.data.resultObj;
        } catch (e) { }

        if (userGgnCd == '20' || url.includes('/mngr') || url.includes('/manager')) {
          // window.location.href = '/manager/login';
            mngrRouter.push('/manager/login');
        } else if (userGgnCd == '30' || url.includes('/admn') || url.includes('/admin')) {
            //window.location.href = '/admin/login'
            admnRouter.push('/admin/login');
        } else {
            //window.location.href = '/login';
            custRouter.push('/login');
        }
       // return false;
    } else if (error.response.status == 403) {
        alert('권한이 없습니다.');
        //return false;
    } else {
        return Promise.reject(error.response || error.message);
    }
};

export default errorHandler;
