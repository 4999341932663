<template>
    <section class="section-wrap tabsboard">
        <h2 class="blind">카페관리</h2>
        <div id="tabCont-02" class="tabcontent" style="display: block">
            <h3 class="blind">사장님정보</h3>
            <ul class="frm-info-items">
                <li class="column">
                    <span class="label">프로필 사진</span>
                    <div class="pic-area">
                        <span class="pic-box">
                            <img id="mngrFile" src="/mycafemngr/images/dummy/@my-pic.png" alt="">
                            <input type="file" id="mngrFile" class="blind" @change="mngrFileChg" accept="image/*">
                        </span>
                        <button type="button" @click="mngrImgClick" class="btn-blk mini">수정</button>
                    </div>

                    <img id="dogs" />
                    <img id="image" />
                    <img id="image2" />

                </li>
                <li>
                    <label class="label" for="frm-01">아이디</label>
                    <div class="label-grp">
                        <input type="text" id="frm-01" name="id" placeholder="아이디" v-model="mngr.id" >
                        <input type="hidden" v-model="mngr.num">
                        <input type="hidden" v-model="mngr.cafeNum">
                    </div>
                </li>
                <li>
                    <label class="label" for="frm-02">비밀번호</label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="password" name="pw" id="frm-02" placeholder="비밀번호" v-model="mngr.pw" >
                            <button type="button" class="btn-blk">변경</button>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label required" for="frm-03">닉네임<i class="blind">필수입력</i></label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" id="frm-03" name="nickNm" placeholder="닉네임" v-model="mngr.nickNm" required>
                            <button type="button" class="btn-blk">중복 확인</button>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label required" for="frm-04">휴대폰<i class="blind">필수입력</i></label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" id="frm-04" name="cpNum" placeholder="휴대폰 번호" v-model="mngr.cpNum"
                                @keyup="cpNumSplit" required>
                            <input type="hidden" name="cpNum1" id="cpNum1" v-model="cafe.cpNum1">
                            <input type="hidden" name="cpNum2" id="cpNum2" v-model="cafe.cpNum2">
                            <input type="hidden" name="cpNum3" d="cpNum3" v-model="cafe.cpNum3">
                            <button type="button" class="btn-blk">휴대폰 인증</button>
                        </div>
                    </div>
                </li>
                <li>
                    <span class="label">이메일</span>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" style="width:28.7%" placeholder="이메일 아이디" title="이메일 아이디"
                                v-model="mngr.email1"> @
                            <input type="text" style="width:28.7%" placeholder="이메일 계정" title="이메일 계정"
                                v-model="mngr.email2">
                            <input type="hidden" name="email" v-model="mngr.email">
                            <select title="이메일 계정 선택">
                                <option>직접입력</option>
                            </select>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label" for="frm-07">대표자명</label>
                    <div class="label-grp">
                        <input type="text" id="frm-07" name="mngrNm" placeholder="대표자명" v-model="mngr.mngrNm">
                    </div>
                </li>
                <!-- <li>
                    <label class="label" for="frm-08">사업자등록번호</label>
                    <div class="label-grp">
                        <input type="text" id="frm-08" placeholder="사업자등록번호" v-model="mngr.bizNm">
                    </div>
                </li> -->
                <li class="line-chk-grp">
                    <span class="label">이메일 수신 동의</span>
                    <div class="label-grp">
                        <span class="frm-box txt-box">
                            <input type="checkbox" id="frm-chk-01" true-value="Y" false-value="N"
                                v-model="mngr.emailAcptCnstYn"><label for="frm-chk-01">이메일 수신을 동의합니다.</label>
                        </span>
                    </div>
                </li>
                <li class="line-chk-grp">
                    <span class="label">SNS 수신 동의</span>
                    <div class="label-grp">
                        <span class="frm-box txt-box">
                            <input type="checkbox" id="frm-chk-02" true-value="Y" false-value="N"
                                v-model="mngr.pushAcptCnstYn"><label for="frm-chk-02">SNS 수신을 동의합니다.</label>
                        </span>
                    </div>
                </li>
            </ul>
            <div class="btn-area">
                <button type="button" id="regMngrBtn" class="btn-blk" @click="goRegMngr($event)">저장</button>
            </div>
        </div>
        {{ count }}
    </section>
</template>

<script setup>
import axiosUtil from '@/util/request';
import Vaildation from '@/util/Validation';
import swal from 'sweetalert2'

import { ref, getCurrentInstance, computed  } from 'vue'

const { proxy } = getCurrentInstance();
const count = ref(0);

let mngrTmp = new Object();
mngrTmp.id = 'frelife22';

const mngr = ref(mngrTmp);
const cafe = ref({});

const mngrImgClick = () => {
    console.log('11');
}

let goRegMngr = (event) => {

    var tmpMngr = mngr.value;
    tmpMngr.email = tmpMngr.email1 + '@' + tmpMngr.email2;
    
    proxy.$axios({
        method: "POST",
        url: "/mngr/reg",
        data : tmpMngr
    }).then((data) => {
        console.log(data);
        if( data.resultYn != 'Y' ){
            swal.fire({
                icon: 'error',
                title: data.resultMsg
            })
        }
    })

    // const targetId = event.currentTarget.id;
    // console.log(targetId); // returns 'foo'
    // console.log('goRegMngr');

}

let cpNumSplit = (event) => {

    var tmpMngr = mngr.value;

    let tmpCpNum = event.target.value
        .replace(/[^0-9]/, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        
    const cpNums = tmpCpNum.split("-");
    if (cpNums.length > 0) {
        tmpMngr.cpNum1 = cpNums[0];
        tmpMngr.cpNum2 = cpNums[1];
        tmpMngr.cpNum3 = cpNums[2];
    }

}

</script>
