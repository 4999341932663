<template>
        <!-- Tab links -->
        <div class="tab full-type">
            <button v-for="(item, index) in srchCafeLst" :key="index" class="tablinks" :class="{'active' : item.codeNum == selSrchCafe}" name="srchCafe" :data-value="item.codeNum" @click="clickAddActive($event, item.codeNum)" >{{ item.codeNm }}</button>
        </div>
        <!-- theme -->
        <template v-if="quickCafeLst != null && quickCafeLst.length > 0">
            <div class="theme-wrap">
                <div class="theme-box">
                    <span v-for="(item, index) in quickCafeLst" :key="index" class="theme" :class="{'active' : item.codeNum == selQuickCafe}" name="quickCafe" :data-value="item.codeNum" @click="clickAddActive($event, item.codeNum)" >{{ item.codeNm }}</span> <!-- 활성화(.active) -->
                </div>
            </div>
        </template>
        <!-- filter -->
        <div class="filter-wrap"> 
            <div class="filter-box">
                <button type="button" class="btn-filter" @click="clickOpenLayer" ><i class="blind">필터선택</i></button>
                <div class="tag-chk-box" ref="moodLstParent">
                    <template v-for="(item, index) in moodLst" :key="index">
                        <span class="tag" :class="{'active' : item.chkYn == 'Y'}" :data-value="item.codeNum" @click="clickMoodlst">#{{ item.codeNm }}</span>
                    </template>
                </div>
            </div>
        </div>
        <!-- //filter -->
        <!-- Tab content -->
        <cafe-one-line :cafeLst="cafeLst" :totalCnt="pageObj.totalCnt"/>
        <layer name="cafeFilter" ></layer>
</template>

<script setup>

import { ref, reactive, onMounted, onBeforeMount, watch, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//util
import cafeEnum from "@/util/cafe.js"
import { debounce, throttle } from 'lodash'
import CodeClass from "@/util/CodeClass.js";

//components
import cafeOneLine from '@/page/main/common/cafeOneLine.vue'
import layer from "@/page/main/views/common/layer.vue";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter();
const route = useRoute();

//vuex 설정
const store = useStore();

//변수 선언
const srchCafeLst = ref([]);
const quickCafeLst = ref([]);
const code = reactive({});
const cafe = reactive({});
const cafeLst = ref([]);
const selQuickCafe = ref(null);
const selSrchCafe = ref(null);
const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 1 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 10 //한 페이지 내에 보여줄 리스트 개수
                    })

const moodLst = ref([]);
const selMoodLst = ref([]);
const moodLstParent = ref(null);

//하단 스크롤 시 발생 이벤트 관련 변수 선언
const currentScroll = ref(0);
const waitingScroll = ref(false);

onMounted(async () => {

    selSrchCafe.value = route.query.paramSrchCafe;
    selQuickCafe.value = route.query.paramQuickCafe;
    cafe.srchText1 = route.query.paramSrchText1;

    code.upCodeNum = '2001';
    srchCafeLst.value = await goGetCodeLst(code);

    if(selSrchCafe.value == null){
        selSrchCafe.value = cafeEnum.SRCH.SRCH_CODE.ALL;
    }
    if(selSrchCafe.value == cafeEnum.SRCH.SRCH_CODE.THEME){
        code.upCodeNum = '1001';
        quickCafeLst.value = await goGetCodeLst(code);
    }

    if(selQuickCafe.value == null || selQuickCafe.value == ''){
        selQuickCafe.value = '1002';
    }

    cafe.pageNum = pageObj.pageNum; //현재 페이지 번호
    cafe.pageSize = pageObj.pageSize; //최대 페이지 개수
    cafe.pageBlockNum = pageObj.pageBlockNum; //페이지 내에 보여줄 리스트 개수
    cafe.cafeGbn = code.upCodeNum;
    cafeLst.value = await goGetCafeLst(cafe);

    //보여주는 필터 정보
    code.upCodeNum = '4001';
    moodLst.value = await goGetCodeLst(code);
});


const scrollHandler = throttle(scrollCheck, 500);

onMounted(() => {
    document.addEventListener('scroll',scrollHandler);
})

onBeforeMount(() => {
    document.removeEventListener('scroll', scrollHandler);
})


async function goGetCodeLst(code){
    let tmpCode = [];
    await $axios({
        method: 'POST',
        url: '/code/lst', 
        data : code
        }).then((data) => {
        var resultObj =  data.resultObj;
        if( resultObj != null){
            tmpCode = data.resultObj;
        }
    });
    return tmpCode;
}

async function goGetCafeLst(cafe){
    if(selSrchCafe.value == '2006'){
        //로그인 정보가 없을 경우, 해당 부분에서 분기 필요. 찜한 카페 / 추천 카페
        let token = localStorage.getItem("authorization");
        token != null ? cafe.cafeSrchCode = cafeEnum.SRCH.SRCH_CODE.FAV : cafe.cafeSrchCode = cafeEnum.SRCH.SRCH_CODE.RCMN
    }else{
    cafe.cafeSrchCode = selSrchCafe.value;
    }
    
    let tmpCafeLst = [];
    await $axios({
        method: 'POST',
        url: '/cafe/lst', 
        data : cafe
        }).then((data) => {
        var resultObj =  data.resultObj;
            if( resultObj != null && resultObj.length > 0 ){
                tmpCafeLst = resultObj;
                pageObj.totalCnt = data.totalCnt;
                pageObj.totalPage = Math.ceil(pageObj.totalCnt / pageObj.pageBlockNum);
                cafe.pageNum++;
            }
    })
    return tmpCafeLst;
}
async function clickAddActive(e, codeNum){
    let checkName = e.target.name;
    let selCafe = checkName == 'srchCafe' ? selSrchCafe.value : selQuickCafe .value

    if (selCafe == codeNum) return false;

    if(checkName == 'srchCafe'){
        selSrchCafe.value = codeNum;
    }else{
        selQuickCafe.value = codeNum;
    }
    cafe.pageNum = 0;
    
    if(selSrchCafe.value == cafeEnum.SRCH.SRCH_CODE.THEME){
        code.upCodeNum = '1001';
        quickCafeLst.value = await goGetCodeLst(code);
    }else{
        quickCafeLst.value = new Object();
    }

    cafeLst.value = await goGetCafeLst(cafe);
}

function scrollCheck(e){
    currentScroll.value = e.target.scrollingElement.scrollTop + e.target.scrollingElement.clientHeight;

    if(currentScroll.value > e.target.scrollingElement.scrollHeight- 200 && pageObj.totalPage > cafe.pageNum && !waitingScroll.value){
        nextGoGetCafeLst(e);
    }
}

async function nextGoGetCafeLst() {
    waitingScroll.value = true;
    const addCafeLst = await goGetCafeLst(cafe);
    let i;
    for(i = 0; i < addCafeLst.length; i++){
        cafeLst.value.push(addCafeLst[i]);
    }
    waitingScroll.value = false;
}

//레이어 처리
function clickOpenLayer(){
    const layer = {};
    layer.show = true;
    layer.name = 'cafeFilter';
    store.dispatch('layer/setLayerLst', layer)
}

function setFilterLst(){
    selMoodLst.value = store.state.layer.layerLst.cafeFilter.selMoodLst;
    moodLst.value = CodeClass.getCodeListByArray(moodLst.value, selMoodLst.value);
}

function clickMoodlst(e){
    //클릭한 클래스에 active 있는지 확인
    //active가 없으면 active 추가
    //있으면 active 제거

  e.target.classList.toggle("active");

  const activeTags = Array.from(moodLstParent.value.querySelectorAll('.tag.active'));
  const selMoodLstOnDepth1 = activeTags.map(item => item.dataset.value);

  store.dispatch('layer/setCafeFilterMoodLst', selMoodLstOnDepth1);
}

watch(store.state.layer.layerLst, (currentValue, oldValue) => {
        setFilterLst();
    }
    ,{ deep: true });
</script>