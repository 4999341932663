

export default {
    namespaced: true,
    state: {
        loginUser: {
            roles: null
        },
    },
    getters: {},
    mutations: {
        setLoginUser(state, payload) {
            state.loginUser.roles = payload;
        },
    },
    actions: {
        setCust({ state, commit }, payload) {
            commit('setLoginUser', payload);
        },
    },
};
