<template>
<div>
    <header>
        <button type="button" class="btn-prev" onclick="history.back()"><i class="blind">이전</i></button>
        <em class="txt-cafe">{{ board.boardNm }}</em>
    </header>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//components
const router = useRouter()
const route = useRoute()


//게시판 정보 처리 시작
const board = ref({});

async function goGet(board){
    let tmpBoard = new Object;
    await $axios({
        method: 'POST',
        url: '/board/get',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoard = resultObj;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoard;
}

onMounted( async () => {
    board.value.boardNum = route.params.boardNum;
    board.value = await goGet(board.value);
})
//유저 정보 처리 끝

</script>