<template>
      <a v-if="menu.leafYn === 'Y'" v-on:click="goMenu(menu.url)" class="side-nav-link" v-bind:href="'#'">
          <i v-bind:class="menu.icon"></i>
          <span> {{menu.menuNm}} </span>
      </a>
      <a data-bs-toggle="collapse" v-else-if="menu.leafYn === 'N'" v-bind:href="'#submenu' + menu.menuNum"  class="side-nav-link" v-bind:class="'menu_' + menu.menuNum">
          <i v-bind:class="menu.icon"></i>
          <span> {{menu.menuNm}} </span>
          <span class="menu-arrow"></span>
      </a>
      <div v-if="menu.subMenuLst != null" class="collapse" v-bind:id="'submenu' + menu.menuNum" style="">
        <ul class="side-nav-second-level">
            <li v-for="(menu, index) in menu.subMenuLst" :key="index">
                <a v-on:click="goMenu(menu.url)">{{menu.menuNm}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name : 'menuNav',
  props:['menu'],
  data(){
    return {
      totalPage : 0
    };
  },
  computed:{
  },
  methods: {
    goMenu : function(url){
        this.$router.push(url).catch(()=>{});
    }
  }
}
</script>