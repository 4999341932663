<template>
        <cust-header></cust-header>
        <main class="main">
            <h2 class="blind">MyCafe Main</h2>
            <cust-bnr></cust-bnr>
            <div class="section">
                <!-- search -->
                <div class="search-group">
                    <div class="search-location"><button type="button">서울 구로구 디지털로 292</button></div>
                    <button type="button" class="btn-search-box" @click="goSrch" ><span>지역, 카페이름으로 찾아보세요.</span></button>
                </div>
                <!-- //search -->
                <!-- quick-theme -->
                <ul class="theme-list">
                    <cust-quick-cafe-lst></cust-quick-cafe-lst>
                </ul>
                <!-- //quick -->
            </div>
            <!-- list -->
            <cust-near-cafe />
            <!-- //list -->
            <!-- list(swiper) -->
            <cust-fav-cafe />
            <!-- //list(Swiper) -->
            <!-- list -->
            <cust-new-cafe />
            <!-- //list -->
            <!-- list(swiper) -->
            <cust-keyword />
            <!-- //list(swiper) -->
            <!-- 광고 -->
            <cust-adv-bnr></cust-adv-bnr>
            <!-- //main-banner -->
            <!-- //광고 -->
        </main>
</template>

<script>
import custHeader from "./custHeader.vue";
import custNearCafe from "./custNearCafe.vue";
import custFavCafe from "./custFavCafe.vue";
import custNewCafe from "./custNewCafe.vue";
import custKeyword from "./custKeyword.vue";
import custQuickCafeLst from "./custQuickCafeLst.vue";
import custBnr from "./custBnr.vue";
import custAdvBnr from "./custAdvBnr.vue";


export default {
  name : 'cust-toolbar'
  ,components:{
    'cust-header' : custHeader,
    'cust-bnr' : custBnr,
    'cust-near-cafe' : custNearCafe,
    'cust-fav-cafe' : custFavCafe,
    'cust-new-cafe' : custNewCafe,
    'cust-quick-cafe-lst' : custQuickCafeLst,
    'cust-keyword' : custKeyword,
    'cust-adv-bnr' : custAdvBnr
  }
  ,created(){
    this.init();

  },methods:{
    init:async function(){

    },
    goSrch : function(){
        this.$router.push({
            name: 'srch',
            query : {}
        });
    },
    goCafeDetail(event, cafeNum) {
        this.$router.push({
            name: 'cafeDetail',
            query : { id:cafeNum}
        });
    },
  }
}

</script>