<template>
    <div class="list-wrap">
        <div v-for="(cafe, index) in this.cafeLst" :key="index" class="list-item">
            <a class="list-box" @click="this.goCafeDetail(event, cafe.cafeNum)">
                <div class="list-thumb"><img :src="cafe.cafeFileModelLst[0].src" alt=""></div>
                <div class="list-info">
                    <strong class="tit">{{cafe.cafeNm}}</strong>
                    <span class="desc">{{cafe.intr}}</span>
                    <div class="tag-box">
                        <span v-for="(item, index) in cafe.tag.split(',') " :key="index" class="tag">{{ item }}</span>
                    </div>
                </div>
            </a>
            <span class="like-box"><input type="checkbox" :id="'bkmr_' + cafeSrchCode + '_' + cafe.cafeNum" @click="bkmr.clickBkmr($event, cafe, 'CAFE')" :value="cafe.bkmrCafeNum" :checked="cafe.bkmrCafeNum != null"  ><label :for="'bkmr_' + cafeSrchCode + '_' + cafe.cafeNum"><i class="blind">좋아요</i></label></span>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//공통함수
import bkmr from "@/util/bkmr.js";


//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();

//부모 컴포넌트에서 받은 값
const props = defineProps({
  cafeLst: Array,
  cafeSrchCode : String,
})

const baseUrl = process.env.VUE_APP_SERVER_URL;


function goCafeDetail(event, cafeNum) {
    router.push({
        name: 'cafeInfo',
        params : { cafeNum:cafeNum}
    }).then((() =>window.scrollTo(0,0) ));
}

</script>