<template>
    <ul class="pic-list" ref="picLst" @drop.prevent="imgDrop($event, item)" @dragenter.prevent @dragover.prevent>
        <li>
            <a role="button" class="btn-pic-regist" @click="$refs.cafeImgFileRef.click"><span class="blind">사진 추가</span></a>
            <p class="count">6/10</p>
            <input type="file" id="cafeImgFile" class="blind cafeImgFile" accept="image/*" ref="cafeImgFileRef"
                @change="addImg" multiple />
        </li>

        <li v-for="(item, index) in allFileLst" :key="index" draggable="true" @dragstart="imgDragStart($event, item)"
            @drag="imgDrag($event, item)" @dragover="imgDragOver">
            <a role="button" class="list-thumb" :id="`imgFile_${item.cafeFileNum}`">
                <img :src="item.src" alt="" class="image-box" :data-value="item.sortNum" />
                <!-- <img :src="item.cafeFileNum ? `${baseUrl}/cafe/img/${item.cafeFileNum}` : item.src" alt="" class="image-box" /> -->
            </a>
            <button type="button" class="btn-del" @click="removeImg(item, index)">
                <i class="blind">사진 삭제</i>
            </button>
        </li>
    </ul>
</template>

<script setup>
import { ref, onMounted, defineProps, onBeforeMount, getCurrentInstance, computed, watchEffect } from 'vue'
import { useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosFile = proxy.$axiosFile;

//vuex 설정
const store = useStore();

const baseUrl = process.env.VUE_APP_SERVER_URL;

const imgFileLst = computed(() => store.state.cafe.myCafe.imgFileLst);
const cafeFileModelLst = computed(() => store.state.cafe.myCafe.cafeFileModelLst);

const picLst = ref(null);

// const allFileLst = ref([]);

onMounted(() => {
})

//드래그 관련 이벤트

let selectedItem = null;
let ondropItem = null;

let selectedItemSortNum = null;

function imgDragStart(e, item) {
    selectedItem = item;
}

function imgDrag(e, item) {
    //console.log("Drag")
    //console.log(e)
}

function imgDrop(e, item) {
    if (selectedItem !== ondropItem) {
        let tmpSortNum = selectedItem.sortNum;

        //드래그헀던 이미지가 파일번호가 있는 경우(기존 이미지의 경우)
        if (selectedItem.cafeFileNum) {
            cafeFileModelLst.value.forEach((item, index) => {
                if (item === selectedItem) {
                    item.sortNum = ondropItem.sortNum;
                }
            })
        } else {
            //imgFileLst에서 해당 이미지의 정보를 가진 값을 찾는다
            imgFileLst.value.forEach((item, index) => {
                if (item === selectedItem) {
                    item.sortNum = ondropItem.sortNum;
                }
            })
        }


        if (ondropItem.cafeFileNum) {
            cafeFileModelLst.value.forEach((item, index) => {
                if (item === ondropItem) {
                    item.sortNum = tmpSortNum;
                }
            })
        } else {
            //imgFileLst에서 해당 이미지의 정보를 가진 값을 찾는다
            imgFileLst.value.forEach((item, index) => {
                if (item === ondropItem) {
                    item.sortNum = tmpSortNum;
                }
            })
        }
    }
    let tmpLst = [];
    let tmpImgFileLst = imgFileLst.value;
    let tmpCafeFileModelLst = cafeFileModelLst.value;

    store.commit('cafe/setImgFileLst', tmpLst)
    store.commit('cafe/setImgFileLst', tmpImgFileLst)
    store.commit('cafe/setCafeFileModelLst', tmpLst)
    store.commit('cafe/setCafeFileModelLst', tmpCafeFileModelLst)
}

function imgDragOver(e) {

    e.preventDefault();

    if (selectedItem.sortNum != e.target.dataset.value) {
        allFileLst.value.forEach((item, index) => {
            if (item.sortNum == e.target.dataset.value) {
                ondropItem = item;
            }
        })
    }
}

function addImg(e) {
    let imgLst = [];

    Array.prototype.forEach.call(e.target.files, function (file) {
        file.src = URL.createObjectURL(file);
        //file.sortNum = imgFileLst.value == null ? 0 : imgFileLst.value.length + 1;

        store.commit('cafe/pushImgFileLst', file)
    });
    //store.commit('cafe/setImgFileLst', imgLst)
}

async function removeImg(imgFile, index) {

    if (imgFile.cafeFileNum != null) {
        let imgLst = cafeFileModelLst.value.filter(item => item != imgFile);
        store.commit('cafe/setCafeFileModelLst', imgLst)
        store.commit('cafe/pushDelCafeFileModelLst', imgFile)
    } else {
        let imgLst = imgFileLst.value.filter(item => item != imgFile);
        store.commit('cafe/setImgFileLst', imgLst)
    }
}

async function getCafefileImg(cafeFileNum) {
    let imgUrl = '';
    await $axiosFile({
        method: 'GET',
        url: '/cafeFile/img/profile/' + cafeFileNum,
    }).then((response) => {
        imgUrl = URL.createObjectURL(response.data)
    }).catch(error => {
        console.log(error);
        throw new Error(error);
    });
    return imgUrl;
}

function setSrc(arr) {

    console.log('---------------------')

    console.log(arr);

    if (arr != undefined) {
        arr.forEach(async element => {

            console.log(element)

            if (!element.src) {
                element.src = await getCafefileImg(element.cafeFileNum)
            }
        });
    }
}

const allFileLst = computed(() => {
    let fileLst = [];
    let lastSortNum = 0;

    setSrc(cafeFileModelLst.value);

    for (const item of cafeFileModelLst.value) {
        if (lastSortNum < item.sortNum) lastSortNum = item.sortNum
        fileLst.push(item)

    }

    for (const item of imgFileLst.value) {
        if (lastSortNum < item.sortNum) lastSortNum = item.sortNum
        if (!item.sortNum) {
            lastSortNum = lastSortNum + 1
            item.sortNum = lastSortNum;
        }

        fileLst.push(item)
    }

    //후에 인덱스로 정렬하는 부분 추가 필요
    let sortFileLst = lstSort(fileLst);

    return sortFileLst
});

function lstSort(lst) {
    let sortFileLst = lst.sort(function (a, b) {
        return a.sortNum < b.sortNum ? -1 : a.sortNum > b.sortNum ? 1 : 0;
    })
    return sortFileLst
}

</script>
