<template>
<nav>
    <ul class="pagination justify-content-center">
        <li class="page-item">
            <a class="page-link" @click="onPageChange(this.pageObj.pageNum - 1, this.pageObj)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <template v-for="(paging, index) in pages" :key="index"  :class="paging - 1 === pageNum ? 'pageNum' : ''">
          <li class="page-item" :class="paging -1 === this.pageObj.pageNum ? 'active' : ''"><a class="page-link" @click="onPageChange(paging - 1, this.pageObj)">{{ paging }}</a></li>
          </template>
        <li class="page-item">
            <a class="page-link" @click="onPageChange(this.pageObj.pageNum + 1, this.pageObj)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</nav>
</template>

<script>
export default {
  name : 'adminPagination',
  props:['pageChange', 'pageObj'],
  data(){
    return {
      totalPage : 0
    };
  },
  computed:{
      pages : function(){
        const list = [];
        for (let index = this.startPage; index <= this.endPage; index += 1) {list.push(index);}
        return list;
      },
      startPage(){
        return parseInt(this.pageObj.pageNum / this.pageObj.pageSize) * this.pageObj.pageSize + 1;
      },
      endPage(){
          console.log("totalCnt : " + this.pageObj.totalCnt + " pageBlockNum : " +  this.pageObj.pageBlockNum + " totalPage : " + this.totalPage);
          this.totalPage = Math.ceil(this.pageObj.totalCnt / this.pageObj.pageBlockNum);
          if(this.totalPage == 0) this.totalPage = 1;
          let lastPage = parseInt(this.pageObj.pageNum / this.pageObj.pageSize) * this.pageObj.pageSize + this.pageObj.pageSize;
          return lastPage <= this.totalPage ? lastPage : this.totalPage;
      }
  },
  methods: {
    onPageChange(val, obj){
      console.log("var : " + val);

      if (val < 0){
        alert('첫 페이지입니다. ');
        return;
      }
      if(val >= this.totalPage){
        alert('마지막 페이지입니다.');
        return;
      }
      const param = {
        requestPage: val,
      };
      this.pageChange(param, obj);
    }
  }
}
</script>