<template>
    <section class="section-wrap tabsboard">
        <h2 class="blind">카페관리</h2>
        <!-- Tab links -->
        <div class="tab">
            <a href="./cafeinfo" class="tablinks">카페정보</a>
            <a href="./mngrinfo" class="tablinks active">사장님정보</a>
            <a href="./cafervw" class="tablinks">후기</a>
        </div>
        <!-- Tab content -->
        <div id="tabCont-02" class="tabcontent" style="display: block">
            <h3 class="blind">사장님정보</h3>
            <ul class="frm-info-items">
                <li class="column">
                    <span class="label">프로필 사진</span>
                    <div class="pic-area">
                        <span class="pic-box">

                            <img id="mngrImg" src="/mycafemngr/images/dummy/@my-pic.png" alt="">
                            <!-- <img src="http://localhost:7003/mngrFile/img/profile/7" alt=""> -->
                            <!--
                            <img :src="item.cafeFileNum ? `${baseUrl}/cafe/img/${item.cafeFileNum}` : item.url" alt=""
                class="image-box" />-->
                            <input type="file" id="mngrFile" class="blind" @change="mngrFileChg" accept="image/*">
                        </span>
                        <button type="button" @click="mngrImgClick" class="btn-blk mini">수정1</button>
                    </div>

                    <img id="dogs" />
                    <img id="image" />
                    <img id="image2" />

                </li>
                <li>
                    <label class="label" for="frm-01">아이디</label>
                    <div class="label-grp">
                        <input type="text" id="frm-01" name="id" placeholder="아이디" v-model="mngr.id" disabled>
                        <input type="hidden" v-model="mngr.num">
                        <input type="hidden" v-model="mngr.cafeNum">
                    </div>
                </li>
                <li>
                    <label class="label" for="frm-02">비밀번호</label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="password" name="pw" id="frm-02" placeholder="비밀번호" value="">
                            <button type="button" class="btn-blk">변경</button>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label required" for="frm-03">닉네임<i class="blind">필수입력</i></label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" id="frm-03" name="nickNm" placeholder="닉네임" v-model="mngr.nickNm" required>
                            <button type="button" class="btn-blk">중복 확인</button>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label required" for="frm-04">휴대폰<i class="blind">필수입력</i></label>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" id="frm-04" name="cpNum" placeholder="휴대폰 번호" v-model="mngr.cpNum"
                                @keyup="this.cpNumSplit" required>
                            <input type="hidden" name="cpNum1" id="cpNum1" v-model="cafe.cpNum1">
                            <input type="hidden" name="cpNum2" id="cpNum2" v-model="cafe.cpNum2">
                            <input type="hidden" name="cpNum3" d="cpNum3" v-model="cafe.cpNum3">
                            <button type="button" class="btn-blk">휴대폰 인증</button>
                        </div>
                    </div>
                </li>
                <li>
                    <span class="label">이메일</span>
                    <div class="label-grp">
                        <div class="label-box">
                            <input type="text" style="width:28.7%" placeholder="이메일 아이디" title="이메일 아이디"
                                v-model="mngr.email1"> @
                            <input type="text" style="width:28.7%" placeholder="이메일 계정" title="이메일 계정"
                                v-model="mngr.email2">
                            <input type="hidden" name="email" v-model="mngr.email">
                            <select title="이메일 계정 선택">
                                <option>직접입력</option>
                            </select>
                        </div>
                    </div>
                </li>
                <li>
                    <label class="label" for="frm-07">대표자명</label>
                    <div class="label-grp">
                        <input type="text" id="frm-07" name="mngrNm" placeholder="대표자명" v-model="mngr.mngrNm">
                    </div>
                </li>
                <!-- <li>
                    <label class="label" for="frm-08">사업자등록번호</label>
                    <div class="label-grp">
                        <input type="text" id="frm-08" placeholder="사업자등록번호" v-model="mngr.bizNm">
                    </div>
                </li> -->
                <li class="line-chk-grp">
                    <span class="label">이메일 수신 동의</span>
                    <div class="label-grp">
                        <span class="frm-box txt-box">
                            <input type="checkbox" id="frm-chk-01" true-value="Y" false-value="N"
                                v-model="mngr.emailAcptCnstYn"><label for="frm-chk-01">이메일 수신을 동의합니다.</label>
                        </span>
                    </div>
                </li>
                <li class="line-chk-grp">
                    <span class="label">SNS 수신 동의</span>
                    <div class="label-grp">
                        <span class="frm-box txt-box">
                            <input type="checkbox" id="frm-chk-02" true-value="Y" false-value="N"
                                v-model="mngr.pushAcptCnstYn"><label for="frm-chk-02">SNS 수신을 동의합니다.</label>
                        </span>
                    </div>
                </li>
            </ul>
            <div class="btn-area">
                <button type="button" class="btn-blk" @click="goRegMngr()">저장</button>
                <button type="button" class="btn-blk" @click="goRegMngr1()">권한(x)</button>
                <button type="button" class="btn-blk" @click="goRegMngr2()">전체권한</button>
            </div>
        </div>
    </section>
</template>



<script>
import axiosUtil from '@/util/request';
import Vaildation from '@/util/Validation';
import cnst from '@/util/cnst';
import { errorHandler, successHandler } from '@/util/axiosHandler';

export default {
    name: 'mngrinfo',
    components: {
    },
    data: function () {
        return {
            mngr: {},
            cafe: {},
            mountedYn: 'N',
            getMngrYn: 'N',
            formChk: [
                {
                    name: 'id'
                    , require: true
                    , message: '아이디를 넣어주세요.'
                    , type: 'text'
                },
                {
                    name: 'pw'
                    , require: true
                    , message: '비밀번호를 넣어주세요.'
                    , type: 'text'
                },
                {
                    name: 'nickNm'
                    , require: true
                    , message: '닉네임을 넣어주세요.'
                    , type: 'text'
                },
                {
                    name: 'cpNum'
                    , require: true
                    , message: '핸드폰번호를 넣어주세요.'
                    , type: 'text'
                },
                {
                    name: 'email'
                    , require: true
                    , message: '이메일을 넣어주세요.'
                    , type: 'text'
                },
                {
                    name: 'mngrNm'
                    , require: true
                    , message: '대표자명을 넣어주세요.'
                    , type: 'text'
                },
            ],
        }
    },
    async created() {
        await this.init();
    },
    async mounted() {
        this.mountedYn = 'Y';
        await this.goProfile();

        // console.log('1 = ' + this.$store.state.loginUserMdl.loginUser.roles.length);

    },
    methods: {
        init: async function () {
            this.mngr = await this.goGetMngr();

            let emailSplit = this.mngr.email.split('@');
            if (emailSplit.length > 0) {
                this.mngr.email1 = emailSplit[0];
                this.mngr.email2 = emailSplit[1];
            }
            this.getMngrYn = 'Y';
            this.goProfile();

            this.$axiosJson({
                method: 'POST',
                url: '/mngr/get2',
                data: this.mngr,
            }).then((data) => {
                var resultObj = data.resultObj;
                if (resultObj != null && resultObj.length > 0) {
                    this.mngr = data.resultObj;
                }
            })

        },
        goProfile: async function () {
            console.log(this.getMngrYn + ', ' + this.mountedYn);
            if (this.getMngrYn == 'Y' && this.mountedYn == 'Y') {
                if (this.mngr.mngrFileModel != null && this.mngr.mngrFileModel.mngrFileNum != null) {
                    this.$axiosFile({
                        method: 'GET',
                        url: '/mngrFile/img/profile/100x100/' + this.mngr.mngrFileModel.mngrFileNum,
                    }).then((response) => {
                        console.log('4. mngrinfo.vue init')
                        let imageNode = document.getElementById('mngrImg');
                        let imgUrl = URL.createObjectURL(response.data)

                        console.log(imgUrl);

                        imageNode.src = imgUrl

                    }).catch(error => {
                        console.log(error);
                        throw new Error(error);
                    });
                }
            }
        },
        mngrImgClick: function () {
            console.log('mngrImgClick');

            document.getElementById('mngrFile').click();
        },
        mngrGet2: function (e) {
        },
        mngrFileChg: function (e) {

            let fileDOM = e.target;
            let newFile = fileDOM.cloneNode(true);
            let imageSrc = URL.createObjectURL(newFile.files[0]);

            // 파일 업로드 
            let formData = new FormData();
            formData.append("file", newFile.files[0]);
            formData.append("num", this.mngr.num);

            this.$axios({
                method: "POST",
                url: '/mngrFile/reg',
                data: formData
            }).then((res) => {
                if (res != null && res.resultObj != null) {
                    this.mngr.mngrFileModel = res.resultObj[0];
                    console.log(this.mngr.mngrFileModel);
                }
                this.goProfile();
            }).catch(error => {
                console.log(error);
                throw new Error(error);
            });

        },
        goGetMngr: async function () {
            let tmpMngr = {};
            this.mngr.email = this.mngr.email1 + '@' + this.mngr.email2;
            await this.$axios({
                method: 'POST',
                url: '/mngr/get',
                data: this.mngr
            }).then((data) => {
                console.log('2. goGetMngr then')
                var resultObj = data.resultObj;
                console.log(data.resultObj);
                if (resultObj != null) {
                    tmpMngr = data.resultObj;
                }
                return tmpMngr;
            }).catch(error => {
                // 기본 에러 처리 
                errorHandler(error, cnst.USER.GBN.MNGR);
            })
            return tmpMngr;
        },
        goRegMngr1: function () {
            this.$axiosJson({
                method: 'POST',
                url: '/mngr/get1',
                data: this.mngr,
            }).then((data) => {
                alert(data.resultObj.resultVal);
            })
        },
        goRegMngr2: function () {
            this.$axiosJson({
                method: 'POST',
                url: '/mngr/get2',
                data: this.mngr,
            }).then((data) => {

                alert(data.resultObj.resultVal);

            })

        },
        goRegMngr: function () {

            // form을 체크 한다. 
            if (!Vaildation.check(this.formChk)) {
                return false;
            }

            this.$axiosJson({
                method: 'POST',
                url: '/mngr/mod',
                data: this.mngr,
            }).then((data) => {
                var resultObj = data.resultObj;
                if (resultObj != null && resultObj.length > 0) {
                    this.mngr = data.resultObj;
                }
            })

        },
        cpNumSplit: function (e) {
            this.mngr.cpNum = e.target.value.replace(/[^0-9]/, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
            const cpNums = this.mngr.cpNum.split("-");
            if (cpNums.length > 0) {
                this.mngr.cpNum1 = cpNums[0];
                this.mngr.cpNum2 = cpNums[1];
                this.mngr.cpNum3 = cpNums[2];
            }
        }
    },
} 
</script>
