<template>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter,useRoute } from 'vue-router';
import { useStore } from "vuex";
import cnst from '@/util/cnst.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const route = useRoute()
const store = useStore();

function goRouterPush(data, type, query) {
    if(type == 'path'){
        router.push({
            path: data,
        }).then((() =>window.scrollTo(0,0) ));
    }else if(type == 'name'){
        router.push({
            name: data,
        }).then((() =>window.scrollTo(0,0) ));
    }
}

onMounted(async () => {
    let userInfo = new Object;
    userInfo.smplLoginCode = route.query.code,
    userInfo.custRegTypeCode = cnst.SMPL.GOOGLE.CUST_REG_TYPE_CODE;

    //해당 엑세스토큰으로 서버단에서 정보 요청을 하는 방식으로 진행할 예정.
    await $axios({
        method: 'POST',
        data: userInfo,
        url: '/cust/smplLogin',
        }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null){
                localStorage.setItem('authorization', resultObj.token);
                store.dispatch('cust/setCust', userInfo.custRegTypeCode);

                if(resultObj.nickNm == '' || resultObj.nickNm == null){
                    alert('기본 정보 입력이 필요합니다. ');
                    goRouterPush('myinfo', 'name', '');
                }else{
                    goRouterPush('main', 'name', '');
                }
            }
        }).catch(error => {
            alert("로그인에 실패하였습니다. ");
        });
})

</script>