import Swal from 'sweetalert2'

class Validation {
    static check(data) {
        if (data !== undefined && data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {

                let obj = document.getElementsByName(data[i].name);
                if (obj != null) {

                    // 오류 메세지 Element 삭제 - 이미 존재하는 메세지는 삭제 한다. 
                    var errorElement = document.getElementById(data[i].name + '_validation_check');
                    if (errorElement != null) {
                        errorElement.remove();
                    }

                    // 상위 노드를 조회한다. 
                    let parentNode = obj[0].parentNode;
                    // TEXT에 대한 처리를 한다. 
                    if (data[i].require == true) {
                        let val = obj[0].value;
                        if (data[i].type == 'text') {
                            val = val.replace(/\s/gi, ""); // 공백제거 
                            if (val == '') {
                                // 신규생성 
                                const newDiv = document.createElement('p');
                                newDiv.id = data[i].name + '_validation_check';
                                newDiv.className = 'required';
                                newDiv.innerText = data[i].message;
                                // 하위에 등록 
                                parentNode.append(newDiv);
                            }
                        }
                    }
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: '체크 할 대상이 없습니다.',
            })
        }
        return data;
    }
}
export default Validation;
