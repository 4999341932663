
import cnst from '@/util/cnst';

const myCafeLoginUser = {
    roles: null,
    getRole: function () {
        var name = cnst.COOKIE.USER_GBN_NM;
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? unescape(value[2]) : cnst.USER.GBN.GUEST;
    },
    isHasRole: function (roleNum) {
        let result = false;
        let tmpRoleNum = this.getRole();

        if (tmpRoleNum == roleNum)
            result = true;

        return result;
    }
}

export default myCafeLoginUser;

