<template>
    <div class="list-wrap evt-type">
        <div class="list-item" v-for="(tmpBoard, index) in artiLst" :key="index" >
            <a class="list-box" @click="goDetail(tmpBoard.artiNum)">
                <div class="list-info">
                    <strong class="tit">{{ tmpBoard.ttl }}</strong>
                    <span class="date">{{ StringUtil.timestampToDate(tmpBoard.regDt) }}</span>
                </div>
                <!-- <div class="list-thumb"><img src="" alt=""></div> -->
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, defineProps, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

//components
import StringUtil from '@/util/StringUtil.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const router = useRouter()
const route = useRoute()

//vuex 설정
const store = useStore();


//부모 컴포넌트에서 받은 값
const props = defineProps({
  boardNum: String
})

//게시판 정보 처리 시작
const board = ref({});
const artiLst = ref([]);

const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 1 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 10 //한 페이지 내에 보여줄 리스트 개수
                    })


function goDetail(artiNum){
    router.push({
        name: 'boardDetail',
        params : { boardNum:board.value.boardNum,
                    artiNum:artiNum}
    }).then((() =>window.scrollTo(0,0) ));;
}

async function goGetLst(board){
    let tmpBoardLst = [];
    await $axios({
        method: 'POST',
        url: '/arti/lst',
        data: board
    }).then((data) => {
        var resultObj = data.resultObj;
            if( resultObj != null  ){
                tmpBoardLst = resultObj;
                pageObj.totalCnt = data.totalCnt;
                pageObj.totalPage = Math.ceil(pageObj.totalCnt / pageObj.pageBlockNum);
                board.pageNum++;
            }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpBoardLst;
}


onMounted( async () => {
    board.value.boardNum = props.boardNum;


    board.value.pageNum = pageObj.pageNum; //현재 페이지 번호
    board.value.pageSize = pageObj.pageSize; //최대 페이지 개수
    board.value.pageBlockNum = pageObj.pageBlockNum; //페이지 내에 보여줄 리스트 개수

    artiLst.value = await goGetLst(board.value);
})
//유저 정보 처리 끝


import { debounce, throttle } from 'lodash'
//하단 스크롤 시 발생 이벤트 관련 변수 선언
const currentScroll = ref(0);
const waitingScroll = ref(false);


const scrollHandler = throttle(scrollCheck, 500);

onMounted(() => {
    document.addEventListener('scroll',scrollHandler);
})

onBeforeMount(() => {
    document.removeEventListener('scroll', scrollHandler);
})

function scrollCheck(e){
    currentScroll.value = e.target.scrollingElement.scrollTop + e.target.scrollingElement.clientHeight;

    if(currentScroll.value > e.target.scrollingElement.scrollHeight- 200 && pageObj.totalPage > board.value.pageNum && !waitingScroll.value){
        nextGoGetArtiLst(e);
    }
}

async function nextGoGetArtiLst() {
    waitingScroll.value = true;
    const addArtiLst = await goGetLst(board.value);
    let i;
    for(i = 0; i < addArtiLst.length; i++){
        artiLst.value.push(addArtiLst[i]);
    }
    waitingScroll.value = false;
}

</script>