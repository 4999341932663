import custRouter from "@/page/main/router/index.js";
import mngrRouter from "@/page/mngr/router/mngrRouter.js";
import cnst from '@/util/cnst';

// axiosWrpr Wrapper with default success/error actions
const errorHandler = function (error, userGbn) {

    if (error.response.status == 401) {

        if (userGbn == cnst.USER.GBN.CUST) {
            custRouter.push('login');
        } else if (userGbn == cnst.USER.GBN.MNGR) {
            mngrRouter.push('login');
        }
    } else if (error.response.status == 403) {
        alert('권한이 없습니다.');
    }

    return error
};

const successHandler = function (error) {

    console.log('successHandler');

    return error
};

export { errorHandler, successHandler };
